/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment, useContext, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks  from 'remark-breaks';
import axios from 'axios';
import {AuthContext, ViewContext, Hero, Card, Row, Link, useAPI, Switch, useNavigate, Button, Grid } from 'components/lib';
import { Navigate } from 'react-router-dom';
//import { Row, Button, Hero, Features, Testimonial } from 'components/lib';
//import ImgDashboard from './images/dashboard.png';
//import ImgKyleGawley from './images/kyle-gawley.jpg';



export function Home(props){
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();
  const markdown = useAPI('/api/markdown/home/main');
  
  const urlParams = new URLSearchParams(window.location.search);
  let referrer = urlParams.get('r');
  if(referrer){
    localStorage.setItem('referrer', referrer);
  }

  let signout = urlParams.get('clear');
  if(signout){
    console.log("Clear");
    localStorage.clear();
    axios({ method: 'delete', url: '/api/auth' });
    window.location = '/'
  }

  useEffect(() => {
    if(markdown.data){
      //console.log(markdown.data);
    }

  }, [markdown]);

 async function onWalletConnect(data){
  console.log("onWalletConnect");
  console.log(data);
  localStorage.setItem("walletLogin", true);

  try {
    const res = await axios.post('/api/auth', { 
      wallet_address: data
    });
  
    console.log(res.data);
    if(res.data.token){
      navigate(authContext.signin(res))
  
    }  
  } catch (error) {
    console.log(error);
    console.log("Failed to find account with this wallet");
    viewContext.notification.show("Failed to find account associated with this wallet address", 'error', true);
  }
  

  //Login with Wallet
  /*
  url={ useMagic ? '/api/auth/magic' : '/api/auth' }
  buttonText={ useMagic ? 'Send Magic Link' : 'Sign In' }
  callback={ res => {

    useMagic ? setMagicLinkSent(true) : 
    (res.data['2fa_required'] ? navigate(`/signin/otp?token=${res.data.token}`) : navigate(authContext.signin(res)));

  }}
  */
 }

  return(
    authContext.user?.token ?
      <Navigate to='/home'/>
      : 
      <Fragment>

      <Hero
        title='Level up your Collectibles!'
        tagline='Add value to your Collectibles! Earn XP and level them up with Scribe!'
        onWalletConnect={onWalletConnect}
        skipStorage={true}
        signin
      />
      

      
        {/*<Card dark center>
          {markdown.data && 
            <ReactMarkdown 
              children={markdown.data} 
              remarkPlugins={[remarkGfm,remarkBreaks]}
              linkTarget="_blank" 
            />
          }
        </Card>
        <Link 
          btn={true}
          small={false}
          url='/c' 
          text='View Communities' 
        />*/}

      <Hero
        title='Checking things out?'
        tagline='Here are a few things you can look at without logging in..'
      />
      <Card dark center restrictWidthWider>
        <Grid cols="3">
          <Button
            key='centerButton'
            color={ props.destructive ? 'red' : 'green' }
            text={ "Shop" }
            goto={'/shop'}
            //action={ () => navigate(-1) }
          />
          <Button
            key='centerButton2'
            color={ props.destructive ? 'red' : 'green' }
            text={ "Communities" }
            goto={'/c'}
            //action={ () => navigate(-1) }
          />
          <Button
            key='centerButton3'
            color={ props.destructive ? 'red' : 'green' }
            text={ "Spaces" }
            goto={'/spaces'}
            //action={ () => navigate(-1) }
          />
        </Grid>
      </Card>
        
        
      
      

      
    </Fragment>
  

    
    
  );
}
