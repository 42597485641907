/***
*
*   HERO
*   Hero section on landing page
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import React from 'react';
import { Animate, Content, Button, Image, ClassHelper, SocialSignin, Card, WalletConnect } from 'components/lib';
import {isMobile} from 'react-device-detect';
import Style from './hero.tailwind.js';

export function Hero(props){

  const heroStyle = ClassHelper(Style, {

    hero: true,
    className: props.className

  });

  return (
    <section className={ heroStyle }>
      <Content>

        <Animate type='pop'>
          <section className={ Style.blurb }>

            <h1 className={ Style.title }>{ props.title }</h1>
            <h2 className={ Style.tagline }>{ props.tagline }</h2>
            {/*<Button goto='/signin' text="Let's Go!" big className={ Style.button }/>*/}
            
            {props.signin && 
            <Card restrictWidth center className='bg-transparent'>
              <div key='ethconnect' className={'flodat-left'}>
                <br/>
              { isMobile &&  <strong className={'text-sm'}>Please use your Mobile Wallet's browser.</strong>}<br/>
              <p>Already have an account with a wallet connected?</p><br/>
              <div className={'text-center'}><WalletConnect button={true} center connectString={"Login with MetaMask"} skipStorage={props.skipStorage} onConnect={props.onWalletConnect} /></div>
              
            </div>

              <SocialSignin network={['discord']}  />
              <Card dark restrictWidth>
                <small>
                Note: There is an issue with login on some Android devices. We suggest using a desktop browser for initial Discord login, connect your wallet in the My Stuff screen.  
                Thereafter, you can connect via Login with MetaMask easily!
                </small>
              
              </Card>

              
            </Card>
            }
        
            
          </section>
        </Animate>

        <Animate>
          <Image className={ Style.image } source={ props.image } alt={ props.alt } />
        </Animate>

      </Content>
    </section>
  )
}