const Style = {
  nft: 'mx-auto text-center min-h-430 shadow-lg',  
  nftContainer: 'flex flex-col mx-auto text-center min-h-430 shadow-lg pt-6 !pb-0.5',
  nftContainerNormal: 'flex flex-col mx-auto text-center min-h-360 shadow-lg pt-6 !pb-0.5',
  floatLeftp1: 'float-left px-1',
  applicationDiv: 'mx-auto text-center',
  applicationImageDiv: 'mx-auto text-center float-left',
  greenText: 'text-green-500',
  tinyTextSpan: 'inline-block align-text-top -mt-3',
  tinyText: 'text-xxs leading-3',
  collection: 'p0 text-xs text-normal-tight',
  nftname: 'p0 text-xs font-semibold',
  nftButton: 'px-7 !py-1 mx-auto text-center mt-auto',
  nftButtonLeft: '!px-7 !py-1 mx-auto text-center mt-auto float-left',
  nftButtonRight: '!px-7 !py-1 mx-auto text-center mt-auto float-right',
  nftImage: 'max-h-300 sm:max-h-44 mx-auto',
  nftImageTiny: 'cursor-pointer max-h-45 sm:max-h-45 mx-auto p-0.5',
  fillRaw: 'h-full ease-in-out duration-1000 bg-orange-500!',
  socialIcon: 'absolute z-5 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
  buttonGrid: 'mt-1'
}
  
export default Style;