/***
*
*   ACCOUNT
*   Index page for account functions
*
**********/
import React, {useEffect, useState, useContext, useCallback} from 'react';
import { Animate, Grid, Card, Icon, Link, Row, Table } from 'components/lib';
import Axios from 'axios';
import Style from './leaderboard.tailwind.js';

export function LeaderboardHome(props){
  const [leaderboards, setLeaderboards] = useState(null);

  const iconSize = 20;

  const getLeaderBoards = useCallback(async (filter, minEventDate) => {
            
    //let minDate = "2022-06-05";
    //let nftFilter=[];
    let filterString = filter ? "?filter=" + filter : "?";
    let mindateString = minEventDate ? "&minDate=" + minEventDate : "";
    const res = await Axios.get('/api/reports/leaderboards');
    console.log(res.data);
    setLeaderboards(res.data);
    
    
  }, []);

  useEffect(() => {

    getLeaderBoards("level", "2022-04-01");

  }, [getLeaderBoards]);


  return (
    <Animate>
      
        <Row dark>
            <Link url='/leaderboard/nft' className={ Style.link }>
            NFT Leaderboard
            </Link><br/>
            <Link url='/leaderboard/metaverse' className={ Style.link }>
            Metaverse Leaderboard
            </Link>

          { leaderboards && leaderboards.data && leaderboards.data.length > 0 && 
          <>
          <Card name='leaders' center smallHeader >
            <Grid cols='2'>
              { leaderboards.data.map((leaderboard, index) => {
                
                return (
                  
                  <Card shadow rounded
                    blueHeader
                    key={ leaderboard.id }
                    className={ Style.leaderCardBlue }
                    title={leaderboard.lb_display_name}
                    center
                    >
                  <Table
                    data={ leaderboard.report_data }
                    loading={ leaderboard.loading }
                    >
                  </Table>
                  </Card>
                )
                })
              }
            </Grid>
          </Card>
          </>
        }
        </Row>
    </Animate>
  )
}

const Styles = {

  subtitle: 'font-bold mt-4',
  link: 'text-blue-500 font-semibold mt-3 inline-block'

}