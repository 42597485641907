const Style = {

  loading: 'opacity-50',
  button: 'w-[49%] inline-block mr-[2%] last:mr-0',
  //smallButton: 'w-[49%] inline-block mr-[2%] last:mr-0',
  restrictWidth: 'max-w-md',
  center: 'center',

}

export default Style;