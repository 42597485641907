/***
*
*   HERO
*   Hero section on landing page
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import React from 'react';
import { Animate, Content, Message, Button, Image, ClassHelper, SocialSignin, Card } from 'components/lib';
import Style from './signin.tailwind.js';

export function SignIn(props){

  const signinStyle = ClassHelper(Style, {

    signin: true,
    className: props.className

  });

  return (
    <section className={ signinStyle }>
      <Content>

        <Animate type='pop'>
          {/*<Header>sss</Header>*/}
    
          <Message
            messageID="Signin_Required"
            title={props.title ? props.title : ''}
            text={props.text ? props.text : ''}
            type="warning"
          />
          <Card dark700 restrictWidth center>
            <SocialSignin network={['discord']} localStorage={props.localStorage? props.localStorage : null} />
          </Card>
        </Animate>

       
      </Content>
    </section>
  )
}