/***
*
*   SIGN UP STEP 1
*   Signup form for account owners
*   Step 1: create account
*   Step 2: select plan and make payment
*
**********/

import React, { useContext } from 'react';
import { AuthContext, Animate, Row, Card, SocialSignin, Form, Link } from 'components/lib';

export function Signup(props){

  const context = useContext(AuthContext);
  
  return(
    <Animate type='pop'>
      <Row title='Create Your Account'>
        <Card loading={ false } restrictWidth center>

          <SocialSignin network={[ 'discord']} signup />

        </Card>
      </Row>
    </Animate>
  );
}
