/***
*
*   ROW
*   Renders a new landing page row
*
*   PROPS
*   align: left/right/center
*   color: dark/tint/brand
*
**********/

import React from 'react';
import { ClassHelper } from 'components/lib';
import { RowHeader } from './header.js';
import { Content } from './content.js'
import { ContentLeft } from './contentleft.js'
import Style from './row.tailwind.js';

export function Row(props){

  const css = ClassHelper(Style, {

    [props.color]: props.color, 
    className: props.className,
    center: props.align === 'center',
    left: props.align === 'left',
    small: props.small,
    smallDark: props.smallDark,
    dark: props.dark,
    darkNormal: props.darkNormal,
    tinyDark: props.tinyDark,
    tiny: props.tiny,
    interactive: props.interactive
  });

  if (props.header){
    return (
      <header className={ css }>
        <Content>

          { props.children }

        </Content>
      </header>
    );
  }

  return (
    <section className={ css }
      onClick={ e => {

        e.preventDefault();
        e.stopPropagation();

        props.action && props.action(props.params);
        props.goto && History.push(props.goto);
        if (props.url) window.location = props.url;

      }}
    
    >
      {props.align && props.align === 'left' && 
        <ContentLeft className='!ml-1'>

        <RowHeader
          title={ props.title }
          desc={ props.desc }
          color={ props.color }
          mainTitle={ props.mainTitle }
        />

        { props.children }

        </ContentLeft>
      }
      {!props.align&& 
        <Content >

        <RowHeader
          title={ props.title }
          desc={ props.desc }
          color={ props.color }
          mainTitle={ props.mainTitle }
        />

        { props.children }

        </Content>
      }
      
    </section>
  )
}