/***
*
*   CommunityBoost
*   Content for the Community Boost Modal
*
**********/

import React, {useContext, Fragment, useEffect, useState, useImperativeHandle, forwardRef, useRef} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { CommunityBoost } from 'components/boost/community_boost';
import { useAPI, AuthContext, ViewContext, Link, Button, Image, Grid, Card, ProgressBar} from 'components/lib';
import Axios from 'axios';

const CommunityLevel = forwardRef(function CommunityLevel(props, ref) {
//export function CommunityLevel forwardRef(props){
  //console.log(props);

  
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const [userNFTs, setUserNFTs] = useState(null);
  const [community, setCommunity] = useState(props.community);
  const [selectedMultipass, setSelectedMultipass] = useState(null);
  const [selectedBoostMultiPass, setSelectedBoostMultiPass] = useState(null);
  
  const userMultipasses = useAPI('/api/user/multipass', authContext, "POST");

  useEffect(() => {
    
    if(userMultipasses.data){
      //console.log(userMultipasses.data);
      //userMultipasses.data.nfts[0].boost = "Crypto Cave Club";
      setUserNFTs(userMultipasses.data.nfts);
      if(userMultipasses.data.nfts.length > 0){
        setChosenMultipass(0);
      }
      
      //setUserNFTs(userMultipasses.data.nfts);
    }
  }, [userMultipasses]);

  async function onChange(index, item) {
    //console.log("onChange");
    //console.log(index, item);
    setChosenMultipass(index);
    if(props.onChange){
      props.onChange(index, item);
    }
  }

  async function onClickItem(index, item) {
    //console.log("onClickItem");
    //console.log(index, item);
    setChosenMultipass(index);
    if(props.onClickItem){
      props.onClickItem(index, item);
    }
  }

  async function onClickThumb(index, item) {
    //console.log("onClickThumb");
    //console.log(index, item);
    setChosenMultipass(index);
    if(props.onClickThumb){
      props.onClickThumb(index, item);
    }
  }

  async function setChosenMultipass(index){
    //console.log("setChosenMultipass");
    //console.log("Selected Index: " + index);
    let chosenNFT = userMultipasses.data.nfts[index];
    setSelectedMultipass(chosenNFT);
    if(props.onSelected){
      props.onSelected(index, chosenNFT);
    }
    
    //console.log(chosenNFT);
  }

  async function cancelBoost(){
    console.log("cancelBoost");
    viewContext.modal.hide();
    
  }

  async function boostCommunity(){
    console.log("boostCommunity");
    //console.log(resData);
    //console.log(selectedMultipass);
    
    const res = await Axios.post('/api/community/boost', { 
      multipass_id: selectedMultipass.id,
      community_id: props.community.id
    });

    //console.log(res.data);
    if(res.data.error){
      viewContext.notification.show(res.data.message, 'error', true);
      
    } else {
      viewContext.notification.show(res.data.message, 'success', true);

      let tmpCommunity = res.data.data.community;
      if(props.onBoosted){
        props.onBoosted(tmpCommunity);
      }

      //const mpres = await Axios.post('/api/user/multipass');
      //console.log(mpres.data);
      
      /*setUserNFTs(mpres.data.data.nfts);
      if(mpres.data.data.nfts > 0){
        setChosenMultipass(0);
      }*/

      //setCommunity(tmpCommunity); ///api/community/' + community_slug
      viewContext.modal.hide();
    }
  }

  useImperativeHandle(ref, () => ({
    showBoosts() {
      showBoosts();
    },
  }));

  async function showBoosts() {
    //console.log("step");
      
    //console.log(step);
    //var win = window.open(anItem.link.url, '_blank');
    //viewContext.modal.html = <Row>Something</Row>;
    let modalDef = {
      dark: true,
      title: `Boost Community: ${community.community_name}`,
      /*form: {
        id: {
          type: 'hidden',
          value: "none"
        },
      },*/
      /*form: {
        nft_id: {
          type: "hidden",
          value: nft.id,
        },
        user_id: {
          type: "hidden",
          value: nft.user_id,
        },
        stat_id: {
          type: "hidden",
          value: stat === "all" ? "all" : stat.id,
        },
        event_id: {
          type: "hidden",
          value: reference_event_id,
        },
        credits: {
          type: "hidden",
          value: totalCost,
        },
      },*/
      htmlPre: <CommunityBoost 
        onSelected={ multipassBoostSelected }
        onBoosted={communityBoosted}
        community={community}
      />,
      buttonText: null,
      buttonCancelText: null,
      //url: "/api/nft/inscribe_collectible",
      //url: '/api/community/boostpre',
      //method: "POST",
      //text: userMultipasses?.data ? "Boost this Community?" : null,
      //text2: "Inscribing a Collectible makes your experience permanent. It becomes part of the Collectible.",
      //preText: "Note: This will inscribe up to level 10 for free. Any XP earned after reaching level 10 will cost credits to inscribe.",
    };
    viewContext.modal.show(
      modalDef,
      async (resData, theData) => {
        
        if(resData){
          console.log("Hit2");
        }

        if(theData){
          console.log("Hit3");
  
        }
  
      });
  };

  async function communityBoosted(communityData) {
    //console.log("multipassBoostSelected");
    //console.log(index, boostNFT);
    console.log(communityData);
    setCommunity(communityData);
    //const mpres = await Axios.post('/api/user/multipass');
      //console.log(mpres.data);
      
      /*setUserNFTs(mpres.data.data.nfts);
      if(mpres.data.data.nfts > 0){
        setChosenMultipass(0);
      }*/
  }

  async function multipassBoostSelected(index, boostNFT) {
    //console.log("multipassBoostSelected");
    //console.log(index, boostNFT);
    setSelectedBoostMultiPass(boostNFT);
  }

  

  return(
    community && 
      <Card dark>
        <Button
          tiny
          className='!absolute top-1 right-1'
          text={community.boosts.length + ' Boosts >'}
          //icon='x'
          color={ 'green' }
          action={ () => showBoosts() }
        />
          <div>
            <strong className='text-white center mb-2'>Community Level: {community.level.level}</strong>
            <small className='center mb-2'>Gain additional perks and features for the community with Boosts! 
            &nbsp;<Link url='/shop/multipass' target='_blank' text='Mint a MultiPass' color='cyan' className='center' /><br/>
            </small>
        
            
          </div>
        
        
        <ProgressBar 
          small
          meta
          labelCenter
          labelStrong
          label = {community.boosts.length + " Boosts / " + community.level.next + " Boosts"}
          progress={ community.level.progress ? community.level.progress * 100 + '%' : 0 + '%'} 
          //label={nft.unlocked === 1 ? `Level: ${nft.xpStats.level}  (${nft.xp.toLocaleString()} of ${nft.xpStats.nextLevelXP.toLocaleString()})` : 'Activate to Level Up'}
        />
        
        

        {/*
        <ProgressSteps
          items={[
            { name: 'Tier 1', completed: true, action: doStep } ,
            { name: 'Tier 2', completed: true, action: doStep },
            { name: 'Tier 3', completed: false, action: doStep },
            { name: 'Tier 4', completed: false, action: doStep },
            { name: 'Tier 5', completed: false, action: doStep },
          ]}
        />
        */}

      </Card>
    
  );
});

export default CommunityLevel;