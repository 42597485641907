/***
*
*   SPACES NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React from 'react';
import { SubNav, Card } from 'components/lib';



export function CommunityViewNav(props){
  console.log(props);
  if(props.loading){
    return(
      <SubNav dark center={props.center} items={[{ link: '/c/', label: '<'}]}/>
    );
  }

  if(!props.loading && props.loadFail){
    return(
      <>
        <SubNav dark center={props.center} items={[{ link: '/c/', label: '<'}]}/>
        <Card restrictWidth dark><div><strong>Community Not Found</strong></div></Card>
      </>
    );
  }
  let items = [
    { link: props.home ? props.home : '/c', label: '<'},
  ]

  if(!props.loading && !props.loadFail && props.community_info){
  
    items = [
    { link:  props.home ? props.home : '/c', label: '<'},
    { link: '/c/' + props.community_info.community.slug, label: 'Main'},

    { link: '/c/news/' + props.community_info.community.slug , label: 'News'},
    { link: '/c/metrics/' + props.community_info.community.slug , label: 'Metrics'},
    { link: '/c/members/' + props.community_info.community.slug , label: 'Members'},
    { link: '/c/collectibles/' + props.community_info.community.slug , label: 'Collectibles'},
    { link: '/c/games/' + props.community_info.community.slug , label: 'Games'},
    
  ]
    if(props.user && props.user.data){
      let userFlags = props.user.data.flags ? props.user.data.flags.split(",") : [];
      
      let adminUser = false;
      const userData = props.user.data;
      const communityInfo = props.community_info.community;
      if (userData.twitter_id === communityInfo.owner_twitter_id) {
        adminUser = true;
      } else if (userData.id === communityInfo.owner_id) {
        adminUser = true;
      } else if (userData.id === communityInfo.admin_user_id) {
        adminUser = true;
      } else if (userData.discord_id === communityInfo.admin_discord_id) {
        adminUser = true;
      }

      if(adminUser || userFlags.includes('17')){
        items.push({ link: '/c/admin/' + props.community_info.community.slug , label: 'Admin'});
      }
    }
  }
    
  

  return(
    <SubNav dark center={props.center} items={items}/>
  );
}