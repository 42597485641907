/***
*
*   IMAGE
*   Import the image before passing it to this component
*
*   PROPS
*   source: imported source
*   alt: alt description
*   title: description
*   className: inject a custom class object
*
**********/

import React from 'react';
import { ClassHelper } from 'components/lib';
import Style from './image.tailwind.js';

export function Image(props){
  //console.log(props);
  const imageStyle = ClassHelper(Style, props);

  return(
    <img
      src={ props.source }
      alt={ props.alt }
      title={ props.title }
      loading={ props.loading ? props.loading : 'eager' }
      className={ imageStyle }
      onClick={ e => {

        e.preventDefault();
        e.stopPropagation();
  
        props.action && props.action(props.params);
        props.goto && History.push(props.goto);
        if(props.target && props.url){
          window.open(props.url,props.target,"noopener noreferrer")
        } else if (props.url) {
          window.location = props.url;
        }
  
      }}
    />
  );
}