/***
*
*   AUTHENTICATION
*   Auth provider to manage auth functions throughout
*   the application. <PrivateRoute> component to
*   protect internal application routes from unauthenticated
*   access.
*
**********/

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

// auth context
export const AuthContext = React.createContext();

const useAPI = require('components/lib').useAPI;
const Event = require('components/lib').Event;
const permissions = require('./permissions');

export function AuthProvider(props){

  const cache = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(cache);
  const auth = useAPI(user ? '/api/auth' : null);

  useEffect(() => {

    // update the auth status
    if (!auth.loading && auth.data){

      auth.data.authenticated ? 
        update(auth.data) : signout();

    }
  }, [auth]);

  function signin(res){

    if (res.data){

      console.log("AuthContext SignIn");
      localStorage.setItem('user', JSON.stringify(res.data));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
      Event('signin');

      if (!res.data.plan)
        return window.location = '/signup/plan';
      
      let spaceID = localStorage.getItem('space_id');
      if(spaceID){
        localStorage.removeItem('space_id');
        //return window.location = '/space/' + spaceID;
      }

      let gameID = localStorage.getItem('game_id');
      if(gameID){
        localStorage.removeItem('game_id');
        return window.location = '/game/' + gameID;
      }

      let collectionID = localStorage.getItem('collection_id');
      if(collectionID){
        localStorage.removeItem('collection_id');
        return window.location = '/community/game/' + collectionID;
      }

      let socialPosts = localStorage.getItem('social_posts');
      if(socialPosts){
        localStorage.removeItem('social_posts');
        //return window.location = '/posts';
      }

      let userProfile = localStorage.getItem('account_link');
      if(userProfile){
        //localStorage.removeItem('account_link');
        return window.location = '/account/profile';
      }

      let spaces = localStorage.getItem('spaces');
      if(spaces){
        localStorage.removeItem('spaces');
        //return window.location = '/spaces';
      }

      let viewNftID = localStorage.getItem('view_nft_id');
      if(viewNftID){
        localStorage.removeItem('view_nft_id');
        //return window.location = '/nft/view/' + viewNftID;
      }

      let new_community = localStorage.getItem('new_community');
      if(new_community){
        localStorage.removeItem('new_community');
        return window.location = '/c/new';
      }


      

      return window.location = res.data.onboarded ? '/home' : '/home';

    }
  }

  async function signout(){

    axios({ method: 'delete', url: '/api/auth' });
    //localStorage.clear();
    localStorage.removeItem("user");
    return window.location = '/'

  }

  async function switchAccount(id){

    const res = await axios({ 
      
      method: 'post', 
      url: '/api/auth/switch',
      data: { account: id }
    
    });

    if (res.data)
      signin(res)

  }

  function update(data){

    if (localStorage.getItem('user')){

      let user = JSON.parse(localStorage.getItem('user'));
      for (let key in data){

        if (Array.isArray(data[key])){
       
          user[key] = data[key]

        }
        else if (typeof data[key] === 'object'){
          for (let innerKey in data[key]){

            user[key][innerKey] = data[key][innerKey]

          }
        }
        else {
          
          user[key] = data[key];

        }
      }

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);

    }
  }

  return (
    <AuthContext.Provider value={{

      user: user,
      signin: signin,
      signout: signout,
      update: update,
      switchAccount: switchAccount,
      permission: permissions[user?.permission]

    }}

    {...props} />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props){

  // check user exists
  const user = JSON.parse(localStorage.getItem('user'));
  //const path = window.location.pathname;

  if (user?.token){
    if (permissions[user.permission][props.permission]){

      // user has no plan
      /*
      if (!user.plan && path !== '/account/profile' && path !== '/signup/plan')
        return <Navigate to='/signup/plan'/>;

      // user has no subscription
      if ((user.subscription !== 'active' && user.subscription !== 'trialing') && user.permission !== 'master'){

        if (!path.includes('/account/billing') &&
            !path.includes('/signup/plan') &&
            !path.includes('/account/upgrade') && 
            !path.includes('/account') && 
            !path.includes('/account/profile')){

              return <Navigate to='/account/billing' />

        }
      }
      */

      // user is good
      return props.children;

    }
  }

  // user is not authenticated
  return <Navigate to='/signin' />;

}
