import { ProjectDashboard } from 'views/projects/project_dashboard';
import { ProjectView } from 'views/projects/project';


const Routes = [

  {
    path: '/projects',
    view: ProjectDashboard,
    layout: 'app',
    permission: 'user',
    title: 'Project Dashboard'
  },
  {
    path: '/project/:project_id',
    view: ProjectView,
    layout: 'app',
    //permission: 'user',
    title: 'Project View'
  }
]

export default Routes;
