const Style = {

  footer: 'border-t border-solid border-slate-400 flex justify-center',
  copyright: 'block opacity-70 text-left text-sm mb-3',
  address: 'block opacity-70 text-left text-sm not-italic',
  nav: 'mb-5',
  link: 'mr-4 text-sm no-underline hover:underline hover:text-blue-500',
  
}

export default Style;