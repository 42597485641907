const Style = {

  walletTop: 'absolute text-center top-1/2 -translate-y-1/2 right-3 z-10',
  walletButton: 'text-center',
  walletButtonContainer: 'text-center w-40',
  name: 'float-right opacity-50 text-xs font-normal',
  address: 'text-xs font-normal float-left'

} 

export default Style;