/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext} from 'react';

import { Card, ViewContext, Stat, ProgressBar, Chart,  Image, Button, Row, Link, SocialShare,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, SpacesNav } from 'components/lib';

import { useWeb3React } from "@web3-react/core";

import Axios from 'axios';
import Style from './spaces_dashboard.tailwind.js';

export function ScheduledSpaces(props){

  //const user = useAPI('/api/user');
  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();
  const [spacesList, setSpacesList] = useState(null);
  const [userFlags, setUserFlags] = useState([]);
  const [advanced, setAdvanced] = useState(false);

  function truncateString(theString, truncLength=50){
    let retString = theString;
    if(theString.length > truncLength){
      let strLen = theString.length;
      let string1 = theString.substring(0,truncLength);
      retString = string1 + "...";
    }
    return retString;
  }

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);
  
  useEffect(() => { 
    async function getSpaceList() {
      //if(user.data) {

        //let userflags =user.data.flags ? user.data.flags.split(",") : [];
        //console.log(userflags);
        //setUserFlags(userflags);

        //console.log(user.data);
        const res = await Axios.get('/api/spaces/scheduled');
        console.log(res.data);
        setSpacesList(res.data.data);
      //}
    }

    getSpaceList();

  },[]);


  async function editSpace(space) {
    //console.log(space);
    //let navPath = `/space/${space.space_id}`;
    //console.log(navPath);
    //navigate(navPath);
    viewContext.modal.show({

      title: 'Space Settings',
      form: {
        xp_modifier: {
          label: 'XP Bonus Multipler',
          type: 'number',
          min: 0,
          max: 4,
          value: space.xp_modifier,
          required: true,
        },
        space_id: {
          type: 'hidden',
          value: space.space_id
        },
      },
      buttonText: 'Update Space',
      url: '/api/spaces/scheduled',
      method: 'POST',
      text: 'Set the XP Modifier or other settings for the Space',
  
    }, (res, data) => {
      //console.log(res);
      //console.log(data);
      viewContext.notification.show(data.message, data.success ? 'success' : 'error', true);
      if(data.success){
        console.log("Success");
        setSpacesList(data.data);
      }
      
  
    });
   
  };


  return (
    <div className={Style.spaceMargin}>
        <SpacesNav/>
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      { spacesList && spacesList.length === 0 && 
      <Message
        text="Scribe is not currently tracking any Twitter spaces."
        type='info'
      />
      }

      { spacesList && spacesList.length > 0 && 

        <Grid cols='6'>

          
            { spacesList.map((space, index) => {
              
              return (
                space.title !== null &&
                  <Card shadow rounded
                    key={ space.space_id }
                    id={ space.space_id }
                    className={ Style.scheduledSpaceCardDark }
                    center
                    >
                    <div key='space_info' className={ Style.smallestWhite }>
                      {/*{new Date(space.scheduled_start).toString()}<br/>*/}
                      {new Date(space.scheduled_start).toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) }
                      &nbsp;{new Date(space.scheduled_start).toLocaleTimeString('en-us', { hour: "2-digit", minute: "2-digit" }) }
                    </div>
                    <div key='space_link' className={ Style.spaceTitle }>
                      <strong><Link url={`https://twitter.com/i/spaces/${space.space_id}/peek`} text={truncateString(space.title, 90)} target="_new"/></strong>
                    </div>
                    
                    <Card
                      key={`UserCard${index}`}
                    >
                      <div key='space_link2' className={ Style.userName }>
                          <strong><Link  url={`https://twitter.com/${space.creator_user_name}`} text={truncateString(space.creator_name, 42)} target="_new" color='dark'/></strong>
                      </div>
                      <div key='space_image'>
                          <Image
                          key= {`user_${space.creator_id}`}
                          className={ Style.nftImage }
                          nftImage = {true}
                          border = {true}
                          source={ space.creator_profile_url.replace("_normal","")}
                          interactive
                          />
                      </div>
                    </Card>
                    <div key='space_info2' className={ Style.smallerWhite }><strong>XP Modifier:</strong> {space.xp_modifier}</div>
                    <div key='space_button'>
                      <Button key="space_visit" className={Style.spaceButton} tiny color={"blue"} text='Set Reminder' url={`https://twitter.com/i/spaces/${space.space_id}/peek`} target='_new' />
                      
                      {/*user.data && userFlags.includes('2') && 
                        <Button key="space_edit" className={Style.spaceButton} tiny color={"green"} text='Edit' action={ e =>  {editSpace(space) }}/>
                    */}
                    </div>
                      
                  </Card>
              )
              })
            }
            
          
        
      </Grid>
      }

      
      <Feedback />
      </div>
    </div>
  );
}
