/***
*
*   HELP
*   Information for user to get help and support
*
**********/

import React, { Fragment, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks  from 'remark-breaks'
import { Card, Form, Message, Loader, useAPI, Grid, Image, Link } from 'components/lib';
import Style from './nft.tailwind.js';

export function ActivityList(props){

  //const user = useAPI('/api/user');
  const applications = useAPI('/api/applications');

  useEffect(() => {
    //console.log(applications);
    
  }, [applications])

  return (
    <Fragment>

      {/*<Message 
        title='Want more supported apps?'
        type='info'
        text="Contact us to recommend a favorite app or game you'd like to see integrated with Scribe!"
      />*/}

      { applications.loading ? 
        <Loader /> :
        <Fragment>
            {
              applications?.data?.map((application, index) => {
                //console.log(application);
                return (
                <Card
                  key={application.id}
                  id={application.id}
                >
                  <Grid cols='2'>
                  
                    <Card
                      key={application.id}
                      shadow rounded smallHeader
                      title={application.application_name}
                      restrictWidth
                      className={ Style.applicationCardCenter }
                    >
                      <div className={ Style.nftStatName }>
                      {application.application_type} - {application.status }
                      </div>
                      <Image
                        className={ Style.applicationImage }
                        nftImage = {true}
                        border = {true}
                        source={ application.logo_square_url}
                        alt={ application.application_name }
                      />
                      {application.status === "Live" && application.application_url != null && 
                        <Link url={application.application_url} text={"Visit App"} className={ Style.link } target="_new"/>
                      }
                    </Card>
                    <Card 
                      key={application.id + "_desc"}
                      shadow rounded smallHeader
                      title={"Description"}
                      className={ Style.applicationCardLeft }
                    >
                      <div className={ Style.applicationDescription }>
                        <ReactMarkdown 
                          children={application.description} 
                          remarkPlugins={[remarkGfm,remarkBreaks]}
                          linkTarget="_blank" 
                        />
                      </div>
                      <div className={ Style.nftStatName }>
                        <br/>Superpass Support<br/>
                        <div className={ Style.applicationSuperpass }>
                          {application.superpass}
                        </div>
                        
                      </div>
                    </Card>
                  </Grid>
                </Card>
                )
              })
            }
          </Fragment>
     }
    </Fragment>
  )
}