/***
*
*   APP NAV
*   Primary navigation used inside the main app component
*
*   PROPS
*   type: fixed or popup (style of nav on mobile)
*   items: array of objects containing label, link and icon (optional)
*
**********/
import Axios from 'axios';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useWeb3React } from "@web3-react/core";
import truncateEthAddress from 'truncate-eth-address'
import {  Button, Icon, Web3Inject, ViewContext } from 'components/lib';
import Style from './wallet.tailwind.js';


export function WalletConnect(props){

  //console.log(props);
  // state
  const context = useContext(ViewContext);
  const { active, account , chainId, activate, deactivate } = useWeb3React();
  const [existingAccount, setAccount] = useState(null);

  const displayAddress = () => (
    /*<CopyToClipboard
      options={{ debug: true, message: "Yeah" }}
      text={account}
      onCopy={() => {
        //console.log("Copied");
        //setCopied(true);
      }}
    >*/
      //<span>{truncateEthAddress(account)}&nbsp;<button ><Icon color="dark" image='copy' size={ 12 } /></button></span>
      <span>{truncateEthAddress(account)}</span>
    //</CopyToClipboard>
	);

  const connect = useCallback(async () => {
    //console.log("Connect1");
		try {
		  await activate(Web3Inject);
      //activate(CoinbaseWallet)
      //activate(WalletConnect)
		} catch (ex) {
		  console.log(ex)
		} finally {
      localStorage.removeItem("walletDisconnected");
      localStorage.setItem("hasConnectedWallet","true");
		}
	},[activate]);

  useEffect(() => {
    let hasConnectedWallet = localStorage.getItem("hasConnectedWallet");
    if(props.skipStorage){
      hasConnectedWallet = false;
    }
    
      if(!active){
        if(!localStorage.getItem("walletDisconnected")){
         
            //If they've connected their wallet before, reconnect now
            if(hasConnectedWallet){
              connect(account);
            }
         
        }
      } else {
        async function changeAccount(){
          //console.log(library);
          //console.log(connector);
          //console.log("ChainId1 :" + chainId);
          let theChain = 'eth';
          switch(chainId){
            case 1: theChain = 'eth'; break;
            case 3: theChain = 'eth'; break;
            case 4: theChain = 'eth'; break;
            case 5: theChain = 'eth'; break;
            case 42: theChain = 'eth'; break;
            case 56: theChain = 'bnb'; break;
            case 137: theChain = 'matic'; break;
            case 25: theChain = 'cro'; break;
            case 100: theChain = 'xdai'; break;
            case 43114: theChain = 'avax'; break;
            case 1666600000: theChain = 'harmony'; break; //added in components/wallet/connector.js
            
            default: theChain = 'eth';

          }
          
          try {
            const res = await Axios.post('/api/user/web3', { 
              wallet_address: account,
              chain: theChain
            });
            //console.log(res);
            if(res.data.error){
              context.notification.show(res.data.message, 'error', true);
              return;
            } else {
              setAccount(account);
            }  
          } catch (error) {
            console.log("Error adding wallet");
          }
          
          //console.log(res.data);
          if(props.onConnect) {
            //console.log("Sending account to parent: " + account);
            props.onConnect(account);
          }
        }
  
        if(existingAccount !== account){
          changeAccount();
        }
      }
    
    
  },[existingAccount, setAccount, account, active, connect]);

  async function disconnect() {
    try {
      localStorage.setItem("walletDisconnected","true");
      //console.log("Disconnecting");
      deactivate();
    } catch (ex) {
      console.log(ex)
    } finally {
      //console.log("Removing local storage");
      setAccount(null);
    }
  };

  return(
    <div className={ props.center? '' : Style.walletButtonContainer}>
      {active ?
        <div className={Style.walletButton}>
          
          <Button 
            small
            color="orange" 
            variant="contained"
            action={ e => disconnect() }
            text={ displayAddress() }
            className={props.center? 'm-auto' : ''}
          />
          {/*<div className={ Style.address }>{displayAddress()}</div>*/}
          
        </div>
        : 
        <div>
        <Button 
          color="orange" 
          variant="contained"
          action={ e => connect() }
          text={ props.connectString ? props.connectString : "Connect" }
          className={props.center? 'm-auto' : ''}
        />
        </div>
      }
    </div>
  );
}
