/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback, Fragment, useRef} from 'react';
import { useLocation } from 'react-router-dom';


import { useWeb3React } from "@web3-react/core";
//import truncateEthAddress from 'truncate-eth-address';


import CommunityLevel from 'components/boost/community_level';
import { Card, Stat, ProgressBar, Chart,  Button, Image, Row, Link, Form, Search, CommunityViewNav, Table, ProgressSteps, 
  Message, Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, AuthContext, WalletConnect } from 'components/lib';

import { ethers, utils, BigNumber } from 'ethers';

//https://eth-mainnet.g.alchemy.com/v2/RR7i6KryXLb4CRj-wkmv4Tyr_lr-9jj0
  
import Axios from 'axios';
import Style from './community.tailwind.js';

//let lastSearch = '';

export function CommunityView(props){

  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  //const [tokenPrice, setTokenPrice] = useState(null);
  //const [contractABI, setContractABI] = useState(null);
  //const [contractAddress, setContractAddress] = useState(null);
  
  //const [walletAddress, setWallet] = useState("");
  //const [status, setStatus] = useState("");
  //const [waitMint, setWaitMint] = useState(false);

  //const [name, setName] = useState("");
  //const [description, setDescription] = useState("");
  //const [url, setURL] = useState("");

  //const { active, account, chainId } = useWeb3React();
  const [chartData, setChartData] = useState(null);
  const [community, setCommunity] = useState(null);
  const [communityLevels, setCommunityLevels] = useState(null);
  const [communityPerks, setCommunityPerks] = useState(null);
  const [communityError, setCommunityError] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  
  const boostRef = useRef(null);
  
  
  let pathSplit = location.pathname.split("/");
  const community_slug = pathSplit[pathSplit.length-1];
  
  const communityInfo = useAPI('/api/community/' + community_slug);
  const communityPerkData = useAPI('/api/community/perks/' + community_slug);
  const user = useAPI('/api/user', authContext);
  //const userMultipasses = useAPI('/api/user/multipass', authContext, "POST");
  
  useEffect(() => {

    //console.log(communityInfo);
    if(communityInfo.loading){
      //console.log("LOADING NO DATA YET");
    } else {
      if(!communityInfo.success){
        //console.log("NOT LOADING, FAIL");
        setCommunityError(true);
      }
    }
    if(communityInfo && communityInfo.data) {
      console.log(communityInfo);
      viewContext.setTitle(communityInfo.data.community.community_name);
      //setChartData(communityInfo.data.community.chartData);

      //console.log(communityInfo.data.community);
      setCommunity(communityInfo.data.community);
    }

  }, [communityInfo]);

  useEffect(() => {

    //console.log(communityInfo);
    if(communityPerkData.loading){
      //console.log("LOADING NO DATA YET");
    }

    if(communityPerkData && communityPerkData.data) {
      //console.log(communityPerkData.data.perks);
      setCommunityPerks(communityPerkData.data.perks);
      //console.log(communityPerkData.data.levels);
      setCommunityLevels(communityPerkData.data.levels);
    }

  }, [communityPerkData]);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);


  async function viewItem(anItem) {
    console.log(anItem);
    var win = window.open(anItem.link.url, '_blank');
  };
  async function viewItemOpenSea(anItem) {
    //console.log(anItem);
    var win = window.open("https://opensea.io/collection/" + anItem.slug, '_blank');
  };

  async function showBoosts(){
    boostRef.current.showBoosts();
  }
  
  
  return (
    <Fragment>
      <CommunityViewNav dark home={props.home? props.home : null} user={user} community_info={communityInfo.data} center={!advanced} loading={communityInfo.loading} loadFail={communityInfo.loading ? false : communityError} />
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      {!communityInfo.loading && communityInfo.data && communityInfo.data.project &&
        <Card>
          <strong>{communityInfo.data.project.project_name}</strong><br/>
        </Card>
      }

      {community && 
        <CommunityLevel community={community} ref={boostRef}/>
      }

      {community &&
        <>
        <Card dark>
          {community.description}
        </Card>
        
        {communityPerks &&
        <>
          {
          
          [...Array(6)].map((e, i) =>
          <Card 
            dark 
            title={`Level ${i} Perks ${i > 0 ? '(' + communityLevels[i-1].next + '+ Boosts)': ''}` }
            key={"PerkCard_" + i}
            
          >
            {(community.level.level < i) && 
            <Button
              tiny
              className='!absolute top-1 right-1'
              text={'Boost >'}
              //icon='x'
              color={ 'green' }
              action={ () => showBoosts() }
            />}
            <Grid cols={advanced ? "6" : "4"}>
            {
              communityPerks.filter(x=>(x.perk_level === i && x.activation_type === 'AUTO')).map((perk, index) => {
                //console.log(perk);
                //application.application_url = application.application_url.replace("/PLAY/", communityInfo.slug);
                return (
                  <Card
                    key={"PerkCard_" + i + "_" + perk.id}
                    shadow rounded smallHeader
                    title={perk.perk_name}
                    restrictWidth
                    className={ community.level.level >= i ? Style.perkCardActive : Style.perkCardInactive }
                    interactive
                    badge={community.level.level >= i ? true : false}
                    badgeColor={"orange"}
                    badgeText={"Active"}
                    /*action={(e) => {
                      viewApp(application);
                    }}*/
                  >
                    {/*<Image
                      className={ Style.applicationImage }
                      nftImage = {true}
                      border = {true}
                      source={ application.logo_square_url}
                      alt={ application.application_name }
                      interactive 
                      action={(e) => {
                        viewApp(application);
                      }}
                    />*/}
                    
                    <p className='text-xs !leading-4'>
                      {perk.perk_description}
                    </p>
                    
                  </Card>
                )
              })
            }
            </Grid>
          </Card>
          )}
          </>
        }

        </>
      }

        
      </div>
      <Feedback />

    </Fragment>
  );
}
