import { SpacesDashboard } from 'views/spaces/spaces_dashboard';
import { SpaceDashboard } from 'views/spaces/space_dashboard';
import { ScheduledSpaces } from 'views/spaces/scheduled_spaces';

const Routes = [

  {
    path: '/spaces',
    view: SpacesDashboard,
    layout: 'appDark',
    //permission: 'user',
    title: 'Spaces Dashboard'
  },
  {
    path: '/spaces_scheduled',
    view: ScheduledSpaces,
    layout: 'appDark',
    //permission: 'user',
    title: 'Scheduled Spaces'
  },
  {
    path: '/space/:space_id',
    view: SpaceDashboard,
    layout: 'appDark',
    //permission: 'user',
    title: 'Space Dashboard'
  }
]

export default Routes;

//http://localhost:3000/space/1mrGmaaeQavGy