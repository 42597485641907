/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext} from 'react';
import { Card, Stat, Image, Search, Table, DashboardNav,
   Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, AuthContext, Row, Link, Button } from 'components/lib';

//import { useWeb3React } from "@web3-react/core";
import Axios from 'axios';
import Style from './community.tailwind.js';
//import { Link } from 'react-router-dom';
import { ethers, utils, BigNumber } from 'ethers';

let lastSearch = '';

export function CommunityList(props){

  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();
  const communityList = useAPI('/api/communities');
  //const progress = useAPI('/api/demo/progress');
  //const table = useAPI('/api/demo/users/list');
  //const userChart = useAPI('/api/demo/users/types');
  //const revenueChart = useAPI('/api/demo/revenue');
  
  const authContext = useContext(AuthContext);
  
  const user = useAPI('/api/user');
    
  const [nftBaseCollections, setNFTBaseCollections] = useState(null);
  const [nftCollections, setNFTCollections] = useState(null);
  const [nftCount, setNftCount] = useState(0);
  const [search, setSearch] = useState('');
  const [advanced, setAdvanced] = useState(false);
  const [communitiesData, setCommunitiesData] = useState(null);
  

  const {ethereum} = window;
  const provider = ethereum ? new ethers.providers.Web3Provider(ethereum) : null;


  useEffect(() => {
    if(nftBaseCollections){
      //console.log("Show Locked " + showLocked);
      if(search !== '' || (lastSearch.toLowerCase() !== search.toLowerCase())){
        //console.log(search);
        if(lastSearch.toLowerCase() !== search.toLowerCase()){
          let list = nftBaseCollections;
          //console.log(list);
          let thisSearch = search.toLowerCase();
          let shortList = list.filter(function (thing) { 
            //console.log(creator);
            return (
              (thing.name && thing.name.toLowerCase().indexOf(thisSearch) !== -1) ||
              (thing.description && thing.description.toLowerCase().indexOf(thisSearch) !== -1) ||
              (thing.slug && thing.slug.toLowerCase().indexOf(thisSearch) !== -1) ||
              (thing.owner_twitter && thing.owner_twitter.toLowerCase().indexOf(thisSearch) !== -1)
            )
          });
          
          lastSearch = thisSearch;
          setNFTCollections(shortList)
        }
        
      } 
    }
    
  }, [search, nftBaseCollections]);

  

  useEffect(() => {
    
    //getNFTCollections();
    if(communityList.data){
      console.log(communityList.data.communityList);
      setCommunitiesData(communityList.data.communityList);
    }
    
  
  
  },[communityList]);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);

  async function viewItem(community) {
    let navPath=`/c/${community.slug}`;
    //let navPath = `/c/${collection.slug}`;
    navigate(navPath);
  };

  return (
    <Animate type='pop'>
      {user && user.data && !props.hideNav && 
      <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>
      }
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
        <div className='float-right'>
            <Button
              tiny
              id='centerButton'
              key='centerButton'
              color={ props.destructive ? 'red' : 'green' }
              text={ "Add Community" }
              //goto={'/new_community'}
              action={ () => navigate('/c/new') }
            />
          </div><br/>
      {/*<Table
        search
        sort
        className='restrict-width'
        data={ communityList.data && communityList.data.communityList }
        loading={ communityList.loading }
        show={['community_name', 'community_type']}
        badge={{ col: 'community_type', color: 'blue', condition: [

          { value: 'NFT', color: 'green' },
          { value: 'invited', color: 'blue' }

        ]}}
        //actions={{ edit: editUser, delete: deleteUser, email: true }}
      />*/}
      {/*<Search throttle={ 500 } callback={ x => setSearch(x) }/><br/>*/}
      { communitiesData && communitiesData.length > 0 && 
        <>

        { communitiesData.filter(x=>
          (
            //x.community_type.includes('NFT') && (x.stats.member_count > 0 || x.stats.collectible_count > 0))|| (!x.community_type.includes('NFT'))
            //x.community_type.includes('NFT'))
            //!x.community_type.includes('NFT')
            x.community_name && (x.community_name !== undefined))
          )
          .map((community, index) => {
          return (
            <Row 
              tinyDark
              align={'left'}
              id={ `card_${community.community_id}` }
              key={ `card_${community.community_id}` }
              className={Style.communityRow}
              interactive
              action={(e) => {
                viewItem(community);
              }}
            >
            <div className={ Style.communityName }>
              <Image
                  className={ Style.nftCollectionImage }
                  nftImage = {true}
                  border = {true}
                  loading = "lazy"
                  interactive
                  source={ community.image_url}
                  alt={ community.name }
                  action={ e => {viewItem(community)}}
                />
              <Link target={'_same'} text={ community.community_name } url={`/c/${community.slug}`} /><br/>
              <small >{community.community_type}</small><br/>
            </div>
          <Grid cols='6' className={'!ml-1'} noBottomMargin={true}>

                

                

                
                <div className={ Style.communityStat }>
                  <Stat
                    key="stat1"
                    labelSmall
                    small
                    loading={false}
                    value={community.stats.member_count ? community.stats.member_count : 'n/a'}
                    label="Tracked Members"
                    //icon="users"
                    communityStat
                  />
                </div>
                <div className={ Style.communityStat }>
                  <Stat
                    key="stat1"
                    labelSmall
                    small
                    loading={false}
                    value={community.stats.active_member_count ? community.stats.active_member_count : 'n/a'}
                    label="Active Members"
                    //icon="users"
                    communityStat
                  />
                </div>
                {community.community_type.includes('NFT') &&
                  <div className={ Style.communityStat }>
                    <Stat
                      key="stat1"
                      small
                      labelSmall
                      loading={false}
                      value={community.stats.collectible_count ? community.stats.collectible_count : 'n/a'}
                      label="Activated Collectibles"
                      //icon="users"
                      communityStat
                    />
                  </div>
                }
                <div className={ Style.communityStat }>
                  <Stat
                    key="stat1"
                    small
                    labelSmall
                    loading={false}
                    value={community.stats.event_count_social ? community.stats.event_count_social : 'n/a'}
                    label="Social Activity"
                    //icon="users"
                    communityStat
                  />
                </div>
                <div className={ Style.communityStat }>
                  <Stat
                    key="stat2"
                    small
                    labelSmall
                    loading={false}
                    value={community.stats.event_count_gaming ? community.stats.event_count_gaming : 'n/a'}
                    label="Gaming Activity"
                    //icon="users"
                    communityStat
                  />
                </div>
                <div className={ Style.communityStat }>
                  <Stat
                    key="stat3"
                    small
                    labelSmall
                    loading={false}
                    value={community.stats.discord_event_count ? community.stats.discord_event_count : 'n/a'}
                    label="Discord Activity"
                    //icon="users"
                    communityStat
                  />
                </div>

                {/*<div className={ Style.communityStat }>
                  <Stat
                    key="stat1"
                    small
                    labelSmall
                    loading={false}
                    value={community.member_count ? community.member_count : 'n/a'}
                    label="Blockchain Activity"
                    //icon="users"
                    communityStat
                  />
                </div>*/}
                
                
                
              </Grid>
            
            </Row>
          )
        })};

        </>
      }
      
      </div>
      <Feedback />

    </Animate>
  );
}
