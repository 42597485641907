/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useState, useEffect, useCallback } from "react";
//import useWindowSize from 'react-use/lib/useWindowSize';

import { useLocation } from "react-router-dom";
import {
  Table,
  NFTHolderNav,
  useNavigate,
  Message,
  Feedback,
  useAPI,
} from "components/lib";

import Style from './shop.tailwind.js';
//import { useWeb3React } from "@web3-react/core";
import Axios from "axios";

//Solana stuff
//import { useConnection, useWallet } from "@solana/wallet-adapter-react";

export function PlayHistory(props) {
  const navigate = useNavigate();
  const location = useLocation();

  //const onlyWidth = useWindowWidth()
  //const onlyHeight = useWindowHeight()

  // fetch
  const tokenData = useAPI("/api/shop/history/token");
  //const [nftData, setNFTData] = useState(null);
  //const [unAssignedXP, setUnAssignedXP] = useState(0);
  //const { active, account, chainId } = useWeb3React();
  //const [existingAccount, setAccount] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [tokenTransactions, setTokenTransaction] = useState(null);

  // We get the public key of the connected Solana wallet, if there is one
  //const { connection } = useConnection();
  //console.log("Solana wallet: " + publicKey);
  useEffect(() => {
    if (tokenData?.loading) {
    } else {
      //console.log(creditData.data);
      if (tokenData.data) {
        setTokenTransaction(tokenData.data);
        /*
id: '168f4a1f-4039-45e1-8917-1dfdd3922d72',
[1]   token: 'PLAY',
[1]   rewards_type: 'Inscribed XP Rewards',
[1]   test: 1,
[1]   note: 'XP Rewards Week 12 Test',
[1]   batch_id: '394234be-2a86-49d8-b975-5452fea525b7',
[1]   total_rewards: '20000.0000',
[1]   num_wallets: 19,
[1]   transaction_count: 0,
[1]   time_started: 2022-11-06T00:26:11.937Z,
[1]   time_complete: null,
[1]   date_created: 2022-11-06T04:26:10.000Z
        */
      }

      console.log(tokenData.data);
    }
  }, [tokenData]);

  return (
    <Fragment>
      {!tokenData.loading &&
        tokenTransactions &&
        tokenTransactions.length === 0 && (
          <Message
            closable
            messageID="Message_CreditEvents"
            color="transparent"
            title="Credits"
            text={"Your Credit Transaction History is Shown Below"}
          />
        )}

      {!tokenData.loading &&
        tokenTransactions &&
        tokenTransactions.length > 0 && (
          <>
          <div className='pb-4 text-xl font-semibold text-cyan-400'>$PLAY Transactions</div>
          <Table
            dark
            data={tokenTransactions}
            show={[
              "token",
              "rewards_type",
              "note",
              "transaction_type",
              "transaction_hash",
              "amount",
              "date_created",
            ]}
            loading={false}
            badge={{ col: "plan", color: "blue" }}
          ></Table>
          </>
        )}

      <Feedback />
    </Fragment>
  );
}
