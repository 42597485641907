/***
TOKEN PAY
**********/

import React, { useEffect, useState, useContext, Fragment } from "react";

import {
  Card,
  ViewContext,
  AuthContext,
  Image,
  Button,
  Row,
  Link,
  Message,
  Grid,
  Animate,
  Feedback,
  useAPI,
  useNavigate,
  SignIn,
  DashboardNav
  
} from "components/lib";

import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as SolanaWeb3 from "@solana/web3.js";
/*import {clusterApiUrl,
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
  Keypair,
  LAMPORTS_PER_SOL} from "@solana/web3.js";*/

import {
  getOrCreateAssociatedTokenAccount,
  TOKEN_PROGRAM_ID,
  createTransferInstruction,
} from "@solana/spl-token";
import {
  findTransactionSignature,
  FindTransactionSignatureError,
  findReference,
  FindReferenceError,
} from "@solana/pay";

//import { useWeb3React } from "@web3-react/core";

import Axios from "axios";
import Style from "./shop.tailwind.js";

let transactionTimer;
let txDelay = 5;

export function TokenStore(props) {
  //const { connection } = useConnection();
  const connection = new SolanaWeb3.Connection(
    "https://divine-spring-night.solana-mainnet.quiknode.pro/e464d54ffc705a38089484587a4b93af887d46cd/"
  );
  const { publicKey, sendTransaction, signTransaction } = useWallet();
  const currentUserPlayInfo = useAPI("/api/play_balance");

  
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const user = useAPI("/api/user", authContext);
  const navigate = useNavigate();
  const [shopList, setShopList] = useState(null);
  const [payReference, setPayReference] = useState(null);
  const [currentUserCreditInfo, setCurrentUserCreditInfo] = useState(null);
  const [transactionMessage, setTransactionMessage] = useState(null);
  const [transactionLink, setTransactionLink] = useState(null);
  const [transactionCheckCount, setTransactionCheckCount] = useState(0);
  const [advanced, setAdvanced] = useState(false);
  
  const [recipientAddress, setRecipientAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [memo, setMemo] = useState('');

  const shopInfo = useAPI("/api/shop/getShopItems/f84cc2b4-3f9b-45f2-aaa9-25e10bebb6b3");

  //const solTokenAddress = "DPZbpDehrLi2qg7BpMxLVqAgQZQzFixNdwtYq5QjzZXh";
  const playTokenAddress = "DPZbpDehrLi2qg7BpMxLVqAgQZQzFixNdwtYq5QjzZXh";
  const scribeShopAddress = "13VyJHhpFVfFHyovnkTmXEG5oz2bvuHe4tX7NyfWFNrV";

  //const projectInfo = useAPI('/api/project/' + project_id);
  useEffect(() => {
    if (shopInfo && shopInfo.data) {
      console.log(shopInfo.data);
    } else {
      //console.log(shopInfo);
    }
  }, [shopInfo]);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);  

  useEffect(() => {
    async function getShopList() {
      if (user.data) {
        //console.log(user.data);
        //const res = await Axios.get('/api/projects/list');
        //console.log(res.data);
        //setShopList(res.data.data.projects);

        const resCredits = await Axios.get(`/api/shop/credits`);
        console.log(resCredits.data.data);
        setCurrentUserCreditInfo(resCredits.data.data);
      }
    }

    getShopList();
  }, [user]);

  //
  useEffect(() => {
    //console.log("publicKey");
    //console.log(publicKey);
  }, [publicKey]);

  // Check every 0.5s if the transaction is completed
  useEffect(() => {
    if (connection && payReference) {
      let signatureInfo;

      return new Promise((resolve, reject) => {
        /**
         * Retry until we find the transaction
         *
         * If a transaction with the given reference can't be found, the `findReference`
         * function will throw an error. There are a few reasons why this could be a false negative:
         *
         * - Transaction is not yet confirmed
         * - Customer is yet to approve/complete the transaction
         *
         * You can implement a polling strategy to query for the transaction periodically.
         */
        const interval = setInterval(async () => {
          console.log("Checking for transaction reference: " + payReference);
          try {
            signatureInfo = await findReference(connection, payReference, {
              finality: "confirmed",
            });
            console.log("\n 🖌  Signature found: ", signatureInfo.signature);
            clearInterval(interval);
            setPayReference(null);
            resolve(signatureInfo);
          } catch (error) {
            if (!(error instanceof FindReferenceError)) {
              console.error(error);
              clearInterval(interval);
              setPayReference(null);
              reject(error);
            }
          }
        }, 1000);
      });
    }
  }, [payReference]);

  async function viewShopItem(project) {
    console.log(project);
    let navPath = `/shop/${project.id}`;
    //console.log(navPath);
    navigate(navPath);
  }

  async function getShopItem(item) {
    console.log(item);

    let doTX = true;
    console.log(currentUserCreditInfo);

    /*if(currentUserCreditInfo.creditBalance < item.item_cost){
      doTX = false;
      setTransactionMessage("Insufficient $PLAY");
      viewContext.notification.show("Insufficient $PLAY", 'error', true);
      return;
    }*/

    switch (item.fulfill_item) {
      case "CREDIT":
        if (
          currentUserCreditInfo.availableCapacity <= item.fulfill_item_amount
        ) {
          doTX = false;
          setTransactionMessage(
            "Insufficient Credit Capacity. Obtain a Superpass or Scribe Pass to increase Credit Capacity."
          );
          viewContext.notification.show(
            "Insufficient Credit Capacity",
            "error",
            true
          );
        }
        break;
      default:
        if (
          currentUserCreditInfo.availableCapacity <= item.fulfill_item_amount
        ) {
          doTX = false;
          setTransactionMessage(
            "Insufficient Credit Capacity. Obtain a Superpass or Scribe Pass to increase Credit Capacity."
          );
          viewContext.notification.show(
            "Insufficient Credit Capacity",
            "error",
            true
          );
        }
        break;
    }

    if (doTX) {
      if(item.item_currency === 'PLAY'){
        let result = await buyWithSPLToken(item);
      } else {
        let result = await buyWithSOL(item);
      }
      

      
    }
  }

  /*async function sendSolanaTransaction(amount) {
    //13VyJHhpFVfFHyovnkTmXEG5oz2bvuHe4tX7NyfWFNrV
    const transaction = new solanaWeb3.Transaction().add(
      solanaWeb3.SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new solanaWeb3.PublicKey(toAddress),
          lamports: solanaWeb3.LAMPORTS_PER_SOL * amount,
      })
    );
    const signature = await sendTransaction(transaction, connection);
  };*/

  const getTransactions = async (address, numTx) => {
    const pubKey = new SolanaWeb3.PublicKey(address);
    let transactionList = await connection.getSignaturesForAddress(pubKey, {
      limit: numTx,
    });
    return transactionList;
  };

  function truncateString(theString, truncLength = 50) {
    let retString = theString;
    if (theString.length > truncLength) {
      let strLen = theString.length;
      let string1 = theString.substring(0, truncLength);
      retString = string1 + "...";
    }
    return retString;
  }

  async function buyWithSPLToken(item) {
    let amount = item.item_cost;
    setTransactionMessage("Attempting Transaction");
    setTransactionLink(null);

    const fromAddress = publicKey;
    //const toAddress = "TO_ADDRESS"

    const fromPublicKey = new SolanaWeb3.PublicKey(fromAddress);
    const toPublicKey = new SolanaWeb3.PublicKey(scribeShopAddress);

    const tokenPublicKey = new SolanaWeb3.PublicKey(playTokenAddress);

    //const privateKey = "THE_PRIVATE_KEY_AS_A_STRING"
    //const fromWallet = SolanaWeb3.Keypair.fromSecretKey(bs58.decode(privateKey));

    const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
      connection,
      fromPublicKey,
      tokenPublicKey,
      fromPublicKey,
      signTransaction
    );

    const toTokenAccount = await getOrCreateAssociatedTokenAccount(
      connection,
      fromPublicKey,
      tokenPublicKey,
      toPublicKey,
      signTransaction
    );

    const transaction = new SolanaWeb3.Transaction().add(
      createTransferInstruction(
        fromTokenAccount.address,
        toTokenAccount.address,
        fromPublicKey,
        parseInt(amount * Math.pow(10, 9)),
        [],
        TOKEN_PROGRAM_ID
      )
    );

    console.log("transaction");
    console.log(transaction);

    const txStart = {
      item: item,
      from_account_pubkey: fromPublicKey,
      from_token_account_pubkey: fromTokenAccount.address,
      to_account_pubkey: toPublicKey,
      to_token_account_pubkey: toTokenAccount.address,
    };

    const resBuyItem = await Axios.post("/api/shop/buyCreditItem", txStart);
    //let theUserLevels = resLevels.data.data;
    console.log("reference");
    console.log(resBuyItem.data);
    if(!resBuyItem.data.success){
      setTransactionMessage(resBuyItem.data.message);
      viewContext.notification.show(
        resBuyItem.data.message,
        "error",
        false
      );
      return;
    }
    
    console.log(resBuyItem.data.data);
    let reference = resBuyItem.data.data.reference;
    let tx_id = resBuyItem.data.data.id;

    transaction.add(
      new SolanaWeb3.TransactionInstruction({
        keys: [{ pubkey: fromPublicKey, isSigner: true, isWritable: true }],
        data: Buffer.from(reference, "utf-8"),
        programId: new SolanaWeb3.PublicKey(
          "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
        ),
      })
    );

    // set a recent block hash on the transaction to make it pass smoothly
    const latestBlockHash = await connection.getLatestBlockhash();
    transaction.recentBlockhash = latestBlockHash.blockhash;

    // set who is the fee payer for that transaction
    transaction.feePayer = fromPublicKey;

    // sign the transaction using the signTransaction method that we got from the useWallet hook above
    const signed = await signTransaction(transaction);
    console.log("Transaction signed");

    // send the signed transaction
    const signature = await connection.sendRawTransaction(signed.serialize());

    setTransactionMessage("Awaiting Transaction...");
    setTransactionLink(`https://solscan.io/tx/${signature}`);

    //setPayReference(signature);
    console.log("signature");
    console.log(signature);

    const txUpdate = {
      item: item,
      tx_id: tx_id,
      reference: reference,
      signature: signature,
    };

    const resUpdateTransaction = await Axios.post(
      "/api/shop/updateTransaction",
      txUpdate
    );
    //console.log(resUpdateTransaction);

    // wait for a confirmation to make sure it went to the blockchain (optional)
    let confirm = await connection.confirmTransaction({
      signature,
      lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
      blockhash: latestBlockHash.blockhash,
    });
    setTransactionMessage("Transaction Confirmed.");

    //console.log("confirm");
    //console.log(confirm);
    //let txList = await connection.getParsedTransactions([signature]);
    //console.log("txList");
    //console.log(txList);

    //Send it to server for waiting
    const txFinish = {
      item: item,
      reference: reference,
      signature: signature,
    };
    setTransactionCheckCount(0);
    transactionTimer = setTimeout(
      () => checkTransaction(txFinish),
      txDelay * 1000
    );

    setTransactionMessage(
      "Fulfilling Order, please wait. Should complete within 60 seconds."
    );
    setTransactionLink(`https://solscan.io/tx/${signature}`);

    //const resConfirmTransaction = await Axios.post('/api/shop/confirmTransaction', item);
    //console.log(resConfirmTransaction);
  }

  async function buyWithSOL(item) {
    let amount = item.item_cost;
    setTransactionMessage("Attempting Transaction");
    setTransactionLink(null);

    const fromAddress = publicKey;
    //const toAddress = "TO_ADDRESS"

    const fromPublicKey = new SolanaWeb3.PublicKey(fromAddress);
    const recipientPublicKey = new SolanaWeb3.PublicKey(scribeShopAddress);

    // Construct transaction
    const transaction = new SolanaWeb3.Transaction().add(
      SolanaWeb3.SystemProgram.transfer({
        fromPubkey: fromPublicKey,
        toPubkey: recipientPublicKey,
        lamports: amount * 1000000000, // Convert amount to lamports
      })
    );

    /*let memo = "This is a memo";
    if (memo) {
      transaction.add(SolanaWeb3.SystemProgram.transfer({
        fromPubkey: fromPublicKey,
        toPubkey: recipientPublicKey,
        lamports: 0,
        memo,
      }));
    }*/



    console.log("transaction");
    console.log(transaction);

    const txStart = {
      item: item,
      from_account_pubkey: fromPublicKey,
      from_token_account_pubkey: null,
      to_account_pubkey: recipientPublicKey,
      to_token_account_pubkey: null,
    };

    const resBuyItem = await Axios.post("/api/shop/buyCreditItem", txStart);
    //let theUserLevels = resLevels.data.data;
    console.log("reference");
    console.log(resBuyItem.data);
    if(!resBuyItem.data.success){
      setTransactionMessage(resBuyItem.data.message);
      viewContext.notification.show(
        resBuyItem.data.message,
        "error",
        false
      );
      return;
    }
    
    console.log(resBuyItem.data.data);
    let reference = resBuyItem.data.data.reference;
    let tx_id = resBuyItem.data.data.id;

   
    transaction.add(
      new SolanaWeb3.TransactionInstruction({
        keys: [{ pubkey: fromPublicKey, isSigner: true, isWritable: true }],
        data: Buffer.from(reference, "utf-8"),
        programId: new SolanaWeb3.PublicKey(
          "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
        ),
      })
    );

    // set a recent block hash on the transaction to make it pass smoothly
    const latestBlockHash = await connection.getLatestBlockhash();
    transaction.recentBlockhash = latestBlockHash.blockhash;

    // set who is the fee payer for that transaction
    transaction.feePayer = fromPublicKey;

    // sign the transaction using the signTransaction method that we got from the useWallet hook above
    const signed = await signTransaction(transaction);
    console.log("Transaction signed");

    // send the signed transaction
    const signature = await connection.sendRawTransaction(signed.serialize());

    setTransactionMessage("Awaiting Transaction...");
    setTransactionLink(`https://solscan.io/tx/${signature}`);

    //setPayReference(signature);
    console.log("signature");
    console.log(signature);

    const txUpdate = {
      item: item,
      tx_id: tx_id,
      reference: reference,
      signature: signature,
    };

    const resUpdateTransaction = await Axios.post(
      "/api/shop/updateTransaction",
      txUpdate
    );
    //console.log(resUpdateTransaction);

    // wait for a confirmation to make sure it went to the blockchain (optional)
    let confirm = await connection.confirmTransaction({
      signature,
      lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
      blockhash: latestBlockHash.blockhash,
    });
    setTransactionMessage("Transaction Confirmed.");

    //console.log("confirm");
    //console.log(confirm);
    //let txList = await connection.getParsedTransactions([signature]);
    //console.log("txList");
    //console.log(txList);

    //Send it to server for waiting
    const txFinish = {
      item: item,
      reference: reference,
      signature: signature,
    };
    setTransactionCheckCount(0);
    transactionTimer = setTimeout(
      () => checkTransaction(txFinish),
      txDelay * 1000
    );

    setTransactionMessage(
      "Fulfilling Order, please wait. Should complete within 60 seconds."
    );
    setTransactionLink(`https://solscan.io/tx/${signature}`);

    //const resConfirmTransaction = await Axios.post('/api/shop/confirmTransaction', item);
    //console.log(resConfirmTransaction);
  }

  async function checkTransaction(txInfo) {
    setTransactionMessage("Checking Order Status...");
    const resConfirmTransaction = await Axios.post(
      "/api/shop/confirmTransaction",
      txInfo
    );
    //console.log(resConfirmTransaction);
    let txData = resConfirmTransaction.data.data;
    //console.log(txData);
    if (txData.finalized === 1) {
      setTransactionMessage("Order Fulfilled.");
      viewContext.notification.show(
        resConfirmTransaction.data.message,
        "success",
        false
      );

      const resCredits = await Axios.get(`/api/shop/credits`);
      //console.log(resCredits.data.data);
      setCurrentUserCreditInfo(resCredits.data.data);
    } else {
      //viewContext.notification.show(resConfirmTransaction.data.message, 'success', true)
      if (transactionTimer) {
        clearTimeout(transactionTimer);
      }

      setTransactionMessage(
        "Fulfilling Order, please wait. Should complete within 60 seconds."
      );

      transactionTimer = setTimeout(
        () => checkTransaction(txInfo),
        txDelay * 1000
      );
    }
  }

  if(!authContext.user){
    return (
      <Card dark700 center restrictWidthWide>
        <SignIn title="Signin Required" text="You must signin to use this feature" />
      </Card>
    );
  }
  
  return (
    <Fragment>
      {user && user.data && 
      <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>
      }
    
    <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      <Animate type="pop">
        {shopList && shopList.length === 0 && (
          <Message
            text="Scribe is not currently tracking any Twitter spaces."
            type="info"
          />
        )}
        <Card>
          <strong>
            Use your $PLAY to buy some Credits you can use in Scribe
            and in games.
          </strong>
          <br />
          <span>
            Note: you must have a Credits Container (ie a Scribe Pass or
            Superpass) to store the credits.
          </span>
          <div className={"bg-slate-200 rounded ..."}>
            <Grid cols="5">
              <div>
                <Button
                  tiny
                  className={"float-left p-8 mx-2 ..."}
                  url="https://play.nftscribe.io"
                  text="Buy $PLAY"
                  target="_new"
                />
                &nbsp;
                <div className={"float-left p-0 ..."}>
                  <strong>$PLAY: </strong>
                  {currentUserPlayInfo.loading
                    ? "--"
                    : currentUserPlayInfo.data
                    ? currentUserPlayInfo.data.toLocaleString()
                    : "0"}{" "}
                </div>
              </div>
              <div className={"float-left p-0 ..."}>
                <strong>Metaverse Credits: </strong>{" "}
                {currentUserCreditInfo && currentUserCreditInfo.creditBalance
                  ? `${currentUserCreditInfo.creditBalance.toLocaleString()}`
                  : "--"}
              </div>
              <div className={"float-left p-0 ..."}>
                <strong>Credit Capacity: </strong>{" "}
                {currentUserCreditInfo && currentUserCreditInfo.maxCredits
                  ? `${currentUserCreditInfo.maxCredits.toLocaleString()}`
                  : "0"}
              </div>
              <div className={"float-left p-0 ..."}>
                <strong>Credit Containers: </strong>{" "}
                {currentUserCreditInfo && currentUserCreditInfo.creditContainers
                  ? `${currentUserCreditInfo.creditContainers.length}`
                  : "None"}
              </div>
              <div className={"float-left p-0 ..."}>
                <Button
                  tiny
                  className={"float-left p-8 mx-2 ..."}
                  url="/shop/history"
                  text="Transaction History"
                  target="_top"
                />
              </div>
            </Grid>
          </div>
        </Card>

        <div>
          <div className={"float-left p-0 ..."}>
            <WalletMultiButton className="!bg-gray-900 hover:scale-105" />
          </div>

          {transactionMessage && (
            <div className={"float-left p-0 mx-2 ..."}>
              {transactionMessage}
            </div>
          )}
          {transactionLink && (
            <div className={"float-left p-0 mx-2 ..."}>
              <Link
                url={transactionLink}
                text={"Transaction Details"}
                target={"_new"}
              />
            </div>
          )}
        </div>
        <div className={"clear-both"}></div>

        {shopInfo.data && shopInfo.data.shop_items.length > 0 && (
          <>
          <Grid cols="6">
            {shopInfo.data.shop_items.map((shopItem, index) => {
              //console.log(shopItem);
              return (
                shopItem.title !== null && (
                  <Card
                    shadow
                    rounded
                    key={shopItem.id}
                    id={shopItem.id}
                    className={Style.shopCardSmall}
                    center
                  >
                    <Image
                      className={Style.nftImage}
                      nftImage={true}
                      border={true}
                      source={shopItem.item_image}
                      interactive
                      action={(e) => {
                        getShopItem(shopItem);
                      }}
                    />
                    <>
                      <div key="item_info">
                        <strong>{shopItem.friendly_name}</strong>
                        <br />
                        <span>
                          <strong>Price: </strong>
                          {shopItem.item_cost === "0"
                            ? "FREE"
                            : shopItem.item_cost.toLocaleString() +
                              " $" +
                              shopItem.item_currency}
                        </span>
                        <br />
                        <small>{shopItem.description}</small>
                        <br />
                        <br />
                      </div>

                      <div key="space_button">
                        {publicKey && (
                          <Button
                            key="space"
                            className={Style.spaceButton}
                            color={"green"}
                            text="Purchase"
                            action={(e) => {
                              getShopItem(shopItem);
                            }}
                          />
                        )}
                        {!publicKey && (
                          <WalletMultiButton className="!bg-gray-900 mx-auto scale-90 hover:scale-100 leading-4" />
                        )}
                      </div>
                    </>
                  </Card>
                )
              );
            })}


           


          </Grid>
          </>
        )}

        <Feedback />
      </Animate>
    </div>
    </Fragment>
  );
}
