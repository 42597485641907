/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, { useContext, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks  from 'remark-breaks'
//import { Animate, AuthContext, ViewContext, Button, Form, Card, Link, Row, Message, SocialSignin, useLocation, useNavigate } from 'components/lib';
import { Animate, ViewContext, Button, Card, Row, Message, SocialSignin, useLocation, useAPI } from 'components/lib';



export function Signin(props){

  const markdown = useAPI('/api/markdown/login/main');

  useEffect(() => {
    if(markdown.data){
      //console.log(markdown.data);
    }

  }, [markdown]);

  // context
  //const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  //const navigate = useNavigate();
  
  // state
  const [useMagic, setUseMagic] = useState(false);
  //const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [form, setForm] = useState({

    email: {
      label: 'Email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: 'Forgot your password?'
    }
  });

  useEffect(() => {

    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes('error')){
  
      const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=')+7));
      viewContext.notification.show(msg, 'error');
  
    }
  }, [location.search, viewContext.notification]);

  function toggleMagicLink(useMagic){

    const f = {...form };
    f.password.type = useMagic ? 'hidden' : 'password';
    f.password.required = useMagic ? false : true;
    f.forgotpassword.type = useMagic ? 'hidden' : 'link';

    setForm(f);
    setUseMagic(useMagic);

  }

  return(
    <Animate type='pop'>
      
      <Row darkNormal >
      <Message 
          closable
          messageID="Message_SignIn1"
          type='info'
          title='Signing In'
          text="We use Twitter & Discord for Scribe accounts, which lets us look up user profile info and reward your Twitter usage. It doesn't read DMs or publish Tweets on your behalf."
          text2 = {markdown.data && markdown.data}
        />
        <Card dark700 restrictWidth center>
          <SocialSignin network={['discord']} useMagic={ useMagic } />
        </Card>
      </Row>
    </Animate>
  );
}
