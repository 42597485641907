import { InjectedConnector } from '@web3-react/injected-connector';
//import { WalletLinkConnector } from "@web3-react/walletlink-connector";
//import { WalletConnectConnector } from "@web3-react/walletlink-connector";

//https://chainlist.org/
export const Web3Inject = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 137, 25, 100, 43114, 1666600000 ],
});

/*
const CoinbaseWallet = new WalletLinkConnector({

  url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
 
  appName: "Web3-react Demo",
 
  supportedChainIds: [1, 3, 4, 5, 42],
 
 });

 const WalletConnect = new WalletConnectConnector({

  rpcUrl: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
 
  bridge: "https://bridge.walletconnect.org",
 
  qrcode: true,
 
 });
*/