/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback} from 'react';
import { useLocation } from 'react-router-dom'
import {ConsoleView, isMobile} from 'react-device-detect';
import { Card, ViewContext, Stat, ProgressBar, Chart,  Image, Button, Row, Link, SocialShare,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, SpacesNav } from 'components/lib';

import fruits from './fruits.json';
import nouns from './nouns.json';
import adjectives from './adjectives.json';
import { useWeb3React } from "@web3-react/core";
import { Unity, useUnityContext } from "react-unity-webgl";
import Axios from 'axios';
import Style from './spaces_dashboard.tailwind.js';

import io from 'socket.io-client';
import Messages from './messages';
import MessageInput from './messageInput';
import './chat.css';


const delay = 30; //seconds
const doChat = true;
let spaceTimer;


export function SpaceDashboard(props){

  const location = useLocation();
  let pathSplit = location.pathname.split("/");
  const space_id = pathSplit[pathSplit.length-1];
  
  localStorage.setItem('space_id', space_id);

  const user = useAPI('/api/user');
  const showTestButton = useAPI('/api/var/show_game_test');
  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();
  const [spaceInfo, setSpaceInfo] = useState(null);
  const [userSocialLevelInfo, setUserSocialLevelInfo] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [socket, setSocket] = useState(null);
  const [doGame, setDoGame] = useState(null);
  const [showNav, setShowNav] = useState(true);
  const [showSpace, setShowSpace] = useState(true);
  const [userFlags, setUserFlags] = useState([]);
  const [numTeams, setNumTeams] = useState(0);
  const [numTeamsChanged, setNumTeamsChanged] = useState(false);
  const [teams, setTeams] = useState([]);
  const [advanced, setAdvanced] = useState(false);
    
  const { unityProvider, sendMessage, isLoaded, loadingProgression, addEventListener, removeEventListener, requestFullscreen  } = useUnityContext(
    isMobile ? {
      loaderUrl: "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.loader.js",
      dataUrl: "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.data",
      frameworkUrl: "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.framework.js",
      codeUrl: "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.wasm",
    }: {
      loaderUrl: "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.loader.js",
      dataUrl: "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.data",
      frameworkUrl: "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.framework.js",
      codeUrl: "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.wasm",
      }
  );

  const loadingPercentage = Math.round(loadingProgression * 100);
  
  function truncateString(theString, truncLength=50){
    let retString = theString;
    if(theString.length > truncLength){
      let strLen = theString.length;
      let string1 = theString.substring(0,truncLength);
      retString = string1 + "...";
    }
    return retString;
  }

  async function getSpaceList() {
    if(user.data) {
      
      //console.log(user.data);
      //console.log("Getting Space List");    
      //console.log(user.data);
      let userflags =user.data.flags ? user.data.flags.split(",") : [];
      //console.log(userflags);
      setUserFlags(userflags);

      const res = await Axios.get('/api/space/' + space_id);
      console.log(res.data);
      let theSpaceInfo = res.data.data.space;
      if(theSpaceInfo){

        //console.log(theSpaceInfo);
        let hostArray = theSpaceInfo.host_ids.split(",");
        let speakerArray = theSpaceInfo.speaker_ids.split(",");

        await Promise.all(theSpaceInfo.allSpaceUsers.map(async (spaceUser) => {
          //console.log(spaceUser);
          //spaceUser.display_name = spaceUser.
          spaceUser.role = "Audience";
          if(speakerArray.includes(spaceUser.twitter_id)){
            spaceUser.role = "Speaker";
            //console.log("I'm a speaker");
          }
          if(hostArray.includes(spaceUser.twitter_id)){
            spaceUser.role = "Co-Host"
            //console.log("I'm a co-host");
          }
          if(theSpaceInfo.creator_id === spaceUser.twitter_id){
            spaceUser.role = "Host"
            //console.log("I'm a host");
          }


        }));

        
        //console.log(theSpaceInfo);
        setSpaceInfo(theSpaceInfo);

        //console.log(user.data);
        let myTwitterID = user.data.twitter_id;
        //Run through speakers and hosts and see if we're on the list
        
        let role = null;

        let listenerExists = theSpaceInfo.allSpaceUsers.filter(x => x.twitter_id === myTwitterID);
        if(listenerExists.length > 0){
          role = "Listener";
        }

        if(speakerArray.includes(myTwitterID)){
          role = "Speaker";
          //console.log("I'm a speaker");
        }
        if(hostArray.includes(myTwitterID)){
          role = "Co-Host"
          //console.log("I'm a co-host");
        }
        if(theSpaceInfo.creator_id === myTwitterID){
          role = "Host"
          //console.log("I'm a host");
        }

        let oldRole = userRole;
        if(oldRole !== role){
          setUserRole(role);
        }

        /*const resLevels = await Axios.post('/api/user/events', { 
          user_id: user.data.id,
            truncate_events: true,
          }
        );
        let theUserLevels = resLevels.data.data;
        //console.log(theUserLevels);

        let socialLevel = theUserLevels.applicationLevels.filter(x=>x.application_name === 'Twitter Spaces');
        if(socialLevel.length > 0){
          socialLevel = socialLevel[0].level
        }
        //console.log(socialLevel);
        setUserSocialLevelInfo(socialLevel);
        */
          
        
        if(spaceTimer){
          clearTimeout(spaceTimer);
        }
        spaceTimer = setTimeout(() => getSpaceList(), delay * 1000);
      } else {
        console.log("No space found, setting to listener");
        setUserRole("Listener");
      }
    } else {
      let theSpaceInfo = {
        elapsedHours : 1,
        participant_count: 1,
        join_code: "Nothing",
        join_code_base: "Nothing",
        id: '123',
        space_link: "https://app.nftscribe.io/spaces",
        space_id: space_id,
        title: "Game Test",
        allSpaceUsers: []
      }
      setSpaceInfo(theSpaceInfo);
    }
  }

  useEffect(() => { 
    //console.log("showTestButton: " + showTestButton);
    //console.log(showTestButton);
    getSpaceList();
  },[user]);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);

  useEffect(() => { 
    
    if(user.data) {
      Axios.get('/api/site/spaces_dashboard/');
    }

  },[user]);

  useEffect(() => {
    if(user.data && (userRole && userRole !== "None") && doChat && !socket) {
      
      let domainName = window.location.hostname === 'localhost' ? `http://${window.location.hostname}:8080` : `https://${window.location.hostname}`;
      const newSocket = io(`${domainName}`, {
        transports: ['websocket'],
        secure: true,
      });
      console.log("Connecting to server...");
      //console.log(newSocket);
      setSocket(newSocket);
      return () => newSocket.close();
    }
  }, [user.data, setSocket, userRole]);

  const handleUnityAppReady = useCallback((game_id) => {
    console.log("handleUnityAppReady " + game_id);

    let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url; 
    if(serverURL.includes("localhost")){
      serverURL = "http://" + serverURL + ":8080";
    } else {
      //serverURL = "https://" + serverURL;
      serverURL = "https://game.nftscribe.io";
    }

    let startString = user.data.id + "|" + space_id + "|" + serverURL;
    sendMessage("GameController", "StartApp", startString);
  }, [sendMessage, user, space_id]);

  const handleUnityAppReady_Confirm = useCallback((local_user_id) => {
    console.log("handleUnityAppReady_Confirm " + local_user_id);

    let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url; 
    if(serverURL.includes("localhost")){
      serverURL = "http://" + serverURL + ":8080";
    } else {
      //serverURL = "https://" + serverURL;
      serverURL = "https://game.nftscribe.io";
    }
    
    let userCredits = 1000000;
    let startString = user.data.id + "|" + space_id + "|" + serverURL + "|" + userCredits;
    sendMessage("GameController", "StartAppFinal", startString);
  }, [sendMessage, user, space_id]);

  useEffect(() => {
    addEventListener("OnAppReady", handleUnityAppReady);
    return () => {
      removeEventListener("OnAppReady", handleUnityAppReady);
    };
  }, [addEventListener, removeEventListener, handleUnityAppReady]);

  useEffect(() => {
    addEventListener("OnAppReady_Confirm", handleUnityAppReady_Confirm);
    return () => {
      removeEventListener("OnAppReady_Confirm", handleUnityAppReady_Confirm);
    };
  }, [addEventListener, removeEventListener, handleUnityAppReady_Confirm]);


  useEffect(
    () => {
      //let timer1 = setInterval(() => getSpaceList(), delay * 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        //clearTimeout(timer1);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  useEffect(
    () => {
      //console.log("Changing num teams");
      if(numTeamsChanged){
        sendGameState("startGame", true);
      }
      
    },
    [numTeams]
  );

  async function viewSpace(space) {
    console.log(space);
    //let navPath = `/space/${space.space_id}`;
    //console.log(navPath);
    //navigate(navPath);
  };

  async function launchGame(space){
    //console.log(space);
    setDoGame(true);
  }

  async function showHideSpaceNav(){
    //console.log(showNav)
    let _showNav = !showNav;
    setShowNav(_showNav);
  }

  async function showHideSpace(){
    //console.log(showSpace)
    let _showSpace = !showSpace;
    setShowSpace(_showSpace);
  }

  
  const beTheChange = async(e, stateName, showModal) => {
    //console.log("beTheChange");
    //console.log(e);
    if(e.input === 'numTeams'){
      //console.log("Setting num teams");
      let theNumTeams = parseInt(e.value);
      //console.log(theNumTeams);
      
      setNumTeamsChanged(true);
      setNumTeams(theNumTeams);
      
      let existingTeams = teams;
      //console.log(existingTeams);
      let newTeams = [];
      for(let x=0; x<theNumTeams; x++){
        newTeams.push({
          id: x+1,
          name: existingTeams[x] !== undefined ? existingTeams[x].name : 'Team ' + (x+1),
          group: existingTeams[x] !== undefined ? existingTeams[x].group : 'Any',
        })
      };

      //console.log(newTeams);
      setTeams(newTeams);

      
    }
    
    
    if(e.input.includes('team_name_')){
      let team_id = parseInt(e.input.replace('team_name_',''));
      //console.log("Team ID:" + team_id);
      let existingTeams = teams;
      //console.log(existingTeams);
      let theTeam = existingTeams.filter(x=>x.id === team_id);
      theTeam[0].name = e.value
      setNumTeamsChanged(true);
      setNumTeams(existingTeams.length);
      setTeams(existingTeams);
      
      //console.log(existingTeams);
      
      //console.log("Setting num teams");
      //let theNumTeams = parseInt(e.value);
      //console.log(theNumTeams);
      //setNumTeamsChanged(true);
      //setNumTeams(theNumTeams);
    }

    if(e.input.includes('team_group_')){
      let team_id = parseInt(e.input.replace('team_group_',''));
      //console.log("Team ID:" + team_id);
      let existingTeams = teams;
      console.log(existingTeams);
      let theTeam = existingTeams.filter(x=>x.id === team_id);
      theTeam[0].group = e.value
      setNumTeamsChanged(true);
      setNumTeams(existingTeams.length);
      setTeams(existingTeams);
      
      console.log(existingTeams);
      
      //console.log("Setting num teams");
      //let theNumTeams = parseInt(e.value);
      //console.log(theNumTeams);
      //setNumTeamsChanged(true);
      //setNumTeams(theNumTeams);
    }
  }

  async function sendGameState(stateName, showModal){
    //console.log(stateName);
    //console.log(numTeams);

    let gameServer = window.location.hostname === 'localhost' ? '' : 'https://game.nftscribe.io';

    if(stateName === "startGame"){
      let collectionGroups = ['Any', 'AI Easy', 'AI Normal', 'AI Hard'];
      let teamOptions = collectionGroups.map(x => { return {
        value: capitalizeFirstLetter(x), label: capitalizeFirstLetter(x)
      }});

      let startGameForm = {
        space_id: {
          type: 'hidden',
          value: space_id
        },
        stateName: {
          type: 'hidden',
          value: stateName
        },
        /*bonusPost: {
          label: `Bonus Tweet (Interacters get starting bonus)`,
          type: 'text',
        },*/
        numTeams: {
          label: `Number Teams (0 teams = Everyone vs Everyone)`,
          type: 'number',
          value: isNaN(numTeams) ? '0' : numTeams + '',
          min: 0,
          max: 8,
          required: true,
          errorMessage: `Please enter a number between 0 and 8`
        },
      };

      for(var x=0; x < numTeams; x++){
        let teamCounter = x + 1;
        let teamName = teams[x].name;
        let teamGroup = teams[x].group;
        //console.log(teamName, teamGroup);
        //console.log(teamOptions);
        /*
        team1: {
          label: 'Team 1 Name',
          type: 'text'
        },
        team1_type: {
          label: 'Team 1 Members',
          type: 'select',
          options: teamOptions
        },
        */
        startGameForm[`team_name_${teamCounter}`] = {
          label : `Team ${teamCounter} Name`,
          type: 'text',
          value: teamName
        };
        startGameForm[`team_group_${teamCounter}`] = {
          label : `Team ${teamCounter} Player Type`,
          type: 'select',
          value: teamGroup,
          default: teamGroup,
          options: teamOptions
        }

      }


      viewContext.modal.show({
        scroll: true,
        title: 'Start Game Session',
        text: "Set your team names and game options here",
        form: startGameForm,
        updateOnChange: true,
        onChange: function(e){
          beTheChange(e, stateName, showModal);
        },
        buttonText: 'Go',
        //text: 'Choose the join code for the space.',
        url: gameServer + '/api/game/create',
        method: 'POST'
  
      }, (res, theData) => {
  
        if(res.data){
  
        }
  
      });





      /*const launchGameRes = Axios.post(gameServer + '/api/game/create', { 
        space_id : space_id
      }).then((res) => {
        //console.log(res);
        if(res.data){
  
        }
      });
      */
      return;
    }

    if(showModal){
      viewContext.modal.show({
        title: 'Game Commands',
        text: "Add the param you need to send!",
        form: {
          space_id: {
            type: 'hidden',
            value: space_id
          },
          stateName: {
            type: 'hidden',
            value: stateName
          },
          param: {
            label: 'Param',
            type: 'text',
          },
        },
        buttonText: 'Go',
        //text: 'Choose the join code for the space.',
        url: '/api/game/state/',
        method: 'POST'
  
      }, (res, theData) => {
  
        //viewContext.notification.show(data.name + ' was deleted', 'success', true);
        //callback();
        //getSpaceList();
  
      });
    } else {
      const sendGameStateRes = Axios.post(gameServer + '/api/game/state/', { 
        space_id : space_id,
        stateName: stateName
      }).then((res) => {
        //console.log(res);
        if(res.data){
  
        }
      });
    }

    
    

  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async function spaceCodeJoin(space){
    viewContext.modal.show({
      title: 'How to use Join Codes',
      text: "To show you're in the Space listening, get the Join Code from the Speakers.",
      form: {
        id: {
          type: 'hidden',
          value: space.space_id
        },
        code_1: {
          label: 'Join Code (1st Word)',
          type: 'select',
          options: adjectives.map(x => { return {
    
            value: capitalizeFirstLetter(x), label: capitalizeFirstLetter(x)
    
          }})
        },
        code_2: {
          label: 'Join Code (2nd Word)',
          type: 'select',
          options: nouns.map(x => { return {
    
            value: capitalizeFirstLetter(x), label: capitalizeFirstLetter(x)
    
          }})
        }
      },
      buttonText: 'Go',
      //text: 'Choose the join code for the space.',
      url: '/api/space/join',
      method: 'POST'

    }, () => {

      //viewContext.notification.show(data.name + ' was deleted', 'success', true);
      //callback();
      getSpaceList();

    });

    
  }

  function handleClick() {
    console.log("Full Screen");
    requestFullscreen(true);
  }

  function handleClickEnterFullscreen() {
    requestFullscreen(true);
  }

  async function spaceCodeRefresh(space){
    viewContext.modal.show({
      title: 'How to use Refresh Codes',
      text: "Use Refresh Codes every once in a while to show you're still in the space.",
      form: {
        id: {
          type: 'hidden',
          value: space.space_id
        },
        code_3: {
          label: 'Refresh Code',
          type: 'select',
          options: fruits.map(x => { return {
    
            value: capitalizeFirstLetter(x), label: capitalizeFirstLetter(x)
    
          }})
        }
      },
      buttonText: 'Go',
      //text: 'Choose the join code for the space.',
      url: '/api/space/join',
      method: 'POST'

    }, () => {

      //viewContext.notification.show(data.name + ' was deleted', 'success', true);
      //callback();
      getSpaceList();

    });

    
  }




  return (
      <>
      <SpacesNav/>
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      { !spaceInfo && 
        <Message
          text="Gathering Space Info..."
          type='info'
        />
      }

      { spaceInfo && 

     

          <>
          { (!userRole || userRole === "None") && 
            <Message
              text="To register as a listener, get and enter the Join Code from the Speaker or Cohost. Then you will see and be able to add a refresh code as often as every five minutes. The longer you listen, the more XP you earn."
              type='info'
              closable
            />
            }
          
          
          {showNav && <Button key="showHideNav" quicklinksmall color={"blue"} text='Hide Space Nav' action={ e =>  {showHideSpaceNav() }}/>}
          {!showNav && <Button key="showHideNav" quicklinksmall color={"blue"} text='Show Space Nav' action={ e =>  {showHideSpaceNav() }}/>}
          
          {showNav && 
            <Grid cols='3'>

              
                <Grid cols='3'>
                  <Stat
                    loading={ false }
                    label={ 'Role' }
                    value={ userRole? userRole : "None"}
                    icon=''
                  />
                  <Stat
                    loading={ false }
                    label={ 'Live (Hours)' }
                    value={ spaceInfo.elapsedHours}
                    icon=''
                  />
                  <Stat
                    loading={ false }
                    label={ 'Participants' }
                    value={ spaceInfo.participant_count}          
                    icon=''
                  />
                </Grid>
              


              <Card
                key='Options'
              >

                <Grid cols='4'>
                  {(!userRole || userRole === "None") && <Button key="space1" tiny color={"blue"} text='Enter Code' action={ e =>  {spaceCodeJoin(spaceInfo) }}/>}
                  {(userRole === "Listener") && <Button key="space1" tiny color={"blue"} text='Enter Code' action={ e =>  {spaceCodeRefresh(spaceInfo) }}/>}
                  {(userRole === "Host" || userRole === "Co-Host") && <Button key="space2" tiny color={"blue"} text='Picker' action={ e =>  {viewSpace(spaceInfo) }}/>}
                  {(userRole === "Host" || userRole === "Co-Host" || userRole === "Speaker") && <Button key="space3" tiny color={"blue"} text='Games' action={ e =>  {viewSpace(spaceInfo) }}/>}
                  {(userRole === "Host" || userRole === "Co-Host") && <Button key="space4" tiny color={"blue"} text='Admin' action={ e =>  {viewSpace(spaceInfo) }}/>}
                  {/*{(user.data && (user.data.twitter_id === '22164071' || user.data.twitter_id === '32460385')) && <Button key="spacegame1" tiny color={"blue"} text='Test Game' action={ e =>  {launchGame(spaceInfo) }}/>}*/}
                  {(userRole === "Host" || userRole === "Co-Host" || userRole === "Speaker" || userRole === "Listener") && (showTestButton.success && showTestButton.data === 1) && 
                    <Button key="spacegame1" tiny color={"blue"} text='Play Conquest' action={ e =>  {launchGame(spaceInfo) }}/>}
                </Grid>

                {/*(!userRole || userRole === "None" || userRole === "Listener" || userRole === "Host" || userRole === "Co-Host" || userRole === "Speaker" ) && userSocialLevelInfo && userSocialLevelInfo.level >= 10 && */}
                  <div className={ Style.small }>
                    <strong>Join Code:</strong> {spaceInfo.join_code_base}<br/>
                  </div>
                {/*}*/}
                              
                {(userRole === "Listener") &&
                  <div className={ Style.small }>
                    <strong>Refresh Code:</strong> {spaceInfo.join_code} (Updates every 5 minutes)
                  </div>
                }

              </Card>
              <Card key="share">
                <SocialShare 
                  url={spaceInfo.space_link}
                  description={'Come join me in the sidebar chat for this Space!'}
                />
              </Card>
              
            </Grid>
          }

          {showSpace && <Button key="showHideSpace1" quicklinksmall color={"blue"} text='Hide Space' action={ e =>  {showHideSpace() }}/>}
          {!showSpace && <Button key="showHideSpace2" quicklinksmall color={"blue"} text='Show Space' action={ e =>  {showHideSpace() }}/>}
          
          <Grid cols='3' class='-mx-6' mobileNoMargin noBottomMargin>
          {showSpace &&
          <Card darker shadow rounded restrictWidthMore 
              key={ spaceInfo.id }
              className={ Style.spaceCardTallDarkThin }
              //title = {space.title}
              >
              <div>
                <strong><Link url={`https://twitter.com/i/spaces/${spaceInfo.space_id}/peek`} text={spaceInfo.title} target="_new"/></strong>
              </div>

              <Grid cols='4'>
                { spaceInfo.allSpaceUsers.map((spaceUser, index) => {
                  //console.log(spaceUser);
                  return (
                    spaceUser.profile_image_url && 
                    <Card
                      key={spaceUser.twitter_id}
                      className={ Style.userCardSmall }
                    >
                      <div className={ Style.userName }>
                        <strong><Link  url={`https://twitter.com/${spaceUser.twitter_user_name ? spaceUser.twitter_user_name : spaceUser.username }`} text={truncateString(spaceUser.username,15)} target="_new" color='dark'/></strong><br/>
                        &nbsp;&nbsp;[{spaceUser.role}]
                      </div>
                      <div>
                        <Image
                          key= {`user_${spaceUser.twitter_id}`}
                          className={ Style.nftImage }
                          nftImage = {true}
                          border = {true}
                          source={ spaceUser.profile_image_url.replace("_normal","")}
                          interactive
                          action={ e => {viewSpace(spaceUser)}}
                        />
                      </div>
                  </Card>
                  )
                })}
                </Grid>
            </Card>
          }

          { user.data && (userRole && userRole !== "None") && doGame &&
            <Card shadow
              key={ "spaceContext" }
              className={ isMobile ? Style.gameCardTallMobile : Style.gameCardTall }
              //title = {space.title}
              center
              >

                <>
                {isLoaded === false && (
                  // We'll conditionally render the loading overlay if the Unity
                  // Application is not loaded.
                  <div className="loading-overlay">
                    <p>Loading... ({loadingPercentage}%)</p>
                  </div>
                )}
                  {isMobile ? 
                    <><Unity 
                    style={{ width: '100%', height: '100%' }}
                    unityProvider={unityProvider} 
                    tabIndex={1}
                    /></>
                  :
                    <Unity 
                    style={{ width: '100%', maxWidth: '1066px', height: 600 }}
                    unityProvider={unityProvider} 
                    tabIndex={1}
                    />
                  }
                  
                  <Button key="full_screen_10" tiny className={Style.devButton} color={"red"} text='Enter Fullscreen' action={ e =>  {handleClick() }}/>
                
                </>
                
              </Card>
            }
            { user.data && (userRole && userRole !== "None") && doChat && !doGame && 
            <Card shadow rounded
              key={ "spaceContext" }
              className={ Style.spaceCardTall }
              //title = {space.title}
              center
              >
                
                  <div className="chat-app">
                    <header className="app-header">
                      Space Chat
                    </header>
                    { socket ? (
                      <div className="chat-container">
                        <Messages socket={socket} userInfo={user.data} roomName={space_id} />
                        <MessageInput socket={socket} />
                      </div>
                    ) : (
                      <div>Not Connected</div>
                    )}
                  </div>
                
              </Card>
            }

            {(user.data && doGame && (user.data.superpass_xp > 0 || user.data.partner===1 || userFlags.includes('17') ) ) && 
              <><Card className={Style.devButtons}>
                <Button key="spacegametest_0" tiny className={Style.devButton} color={"orange"} text='Start Game Session' action={ e =>  {sendGameState("startGame", true) }}/>
                {/*<Button key="spacegametest_1" tiny className={Style.devButton} color={"blue"} text='waitForJoin' action={ e =>  {sendGameState("waitForJoin") }}/>*/}
                {/*<Button key="spacegametest_2" tiny className={Style.devButton} color={"blue"} text='sessionCreated' action={ e =>  {sendGameState("sessionCreated") }}/>*/}
                {/*<Button key="spacegametest_3" tiny className={Style.devButton} color={"blue"} text='allowJoin' action={ e =>  {sendGameState("allowJoin") }}/>*/}
                {/*<Button key="spacegametest_4" tiny className={Style.devButton} color={"blue"} text='joined' action={ e =>  {sendGameState("joined") }}/>*/}
                <Button key="spacegametest_5" tiny className={Style.devButton} color={"blue"} text='Begin Match' action={ e =>  {sendGameState("matchBeginCountdown") }}/>
                {/*<Button key="spacegametest_6" tiny className={Style.devButton} color={"blue"} text='matchRunning' action={ e =>  {sendGameState("matchRunning") }}/>*/}
                <Button key="spacegametest_8" tiny className={Style.devButton} color={"blue"} text='End Match' action={ e =>  {sendGameState("matchEndCountdown") }}/>
                {/*<Button key="spacegametest_9" tiny className={Style.devButton} color={"blue"} text='matchEnd' action={ e =>  {sendGameState("matchEnd") }}/>*/}
                {/*<Button key="spacegametest_10" tiny className={Style.devButton} color={"blue"} text='sessionEnd' action={ e =>  {sendGameState("sessionEnd") }}/>*/}
              </Card>
              <Card className={Style.devButtons}>
                <Button key="spacegametest_20" tiny className={Style.devButton} color={"red"} text='Spawn Enemy' action={ e =>  {sendGameState("spawnMob") }}/>
                <Button key="spacegametest_30" tiny className={Style.devButton} color={"red"} text='Spawn 10 Enemies' action={ e =>  {sendGameState("spawnMob10") }}/>
                {/*<Button key="spacegametest_20input" tiny className={Style.devButton} color={"red"} text='spawn mob p' action={ e =>  {sendGameState("spawnMob", true) }}/>*/}
                <Button key="spacegametest_21" tiny className={Style.devButton} color={"red"} text='Spawn Boss' action={ e =>  {sendGameState("spawnBoss") }}/>
                {/*<Button key="spacegametest_21input" tiny className={Style.devButton} color={"red"} text='spawn boss p' action={ e =>  {sendGameState("spawnBoss", true) }}/>*/}
                {/*<Button key="spacegametest_22" tiny className={Style.devButton} color={"red"} text='healz' action={ e =>  {sendGameState("healAll") }}/>*/}
                {/*<Button key="spacegametest_23" tiny className={Style.devButton} color={"red"} text='hurtz' action={ e =>  {sendGameState("hurtAll") }}/>*/}
                {/*<Button key="spacegametest_13" tiny className={Style.devButton} color={"red"} text='allowJoin' action={ e =>  {sendGameState("allowJoin") }}/>
                <Button key="spacegametest_14" tiny className={Style.devButton} color={"red"} text='joined' action={ e =>  {sendGameState("joined") }}/>
                <Button key="spacegametest_15" tiny className={Style.devButton} color={"red"} text='matchBeginCountdown' action={ e =>  {sendGameState("matchBeginCountdown") }}/>
                <Button key="spacegametest_16" tiny className={Style.devButton} color={"red"} text='matchRunning' action={ e =>  {sendGameState("matchRunning") }}/>
                <Button key="spacegametest_17" tiny className={Style.devButton} color={"red"} text='matchEndCountdown' action={ e =>  {sendGameState("matchEndCountdown") }}/>
                <Button key="spacegametest_18" tiny className={Style.devButton} color={"red"} text='matchEnd' action={ e =>  {sendGameState("matchEnd") }}/>
              */}
            </Card>
            </>
            }
            

            
            

          

          </Grid>
          
            
          

      </>
      }
      

      
      <Feedback />
      </div>
    </>
  );
}
