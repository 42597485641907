const Style = {
  communityName: 'p0 text-sky-300 text-normal font-semibold',  
  small: 'p0 text-sm text-normal-tight',
  smaller: 'p0 text-xs font-semibold',
  mintCardSmall: 'text-left min-h-310 shadow-lg',
  mintCard: 'text-center min-h-540 shadow-lg',
  mintCardTall: 'text-center min-h-565 shadow-lg',
  user: 'p0 mx-auto text-center shadow-lg',
  userCard: 'relative p-1 x-1 mb-6 bg-white rounded last-of-type:mb-0',
  userName: 'p0 text-xxs font-semibold',
  nftButton: 'px-7 !py-1 mx-auto text-center mt-1',
  spaceButton: 'px-7 !py-1 mx-auto text-center mt-1',
  nftImage: 'max-h-32 sm:max-h-38 mx-auto aspect-square',
  spaceUserImage: 'max-h-8 sm:max-h-8 max-w-8 sm:max-w-8',
  fillRaw: 'h-full ease-in-out duration-1000 bg-orange-500!',
  socialIcon: 'absolute z-5 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
  communityRow: 'rounded p-1 !ml-1 !pb-0 border-slate-700 border !bg-slate-800 text-cyan-400 mt-1',
  communityStat: '!max-w-xs w-36 mb-0 !p-0',
  badgeUL: 'absolute left-0 top-1 text-xxs bg-slate-500 text-cyan-400',
  greenFadeText: 'transition-property: color;  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);  transition-duration: 150ms;',
  smallMessage: '!p-2 md:p-4',

  applicationCardCenter: 'text-center shadow-lg min-h-310',
  applicationCardLeft: 'text-left shadow-lg min-h-275',
  applicationDescription: 'text-left text-normal',
  applicationSuperpass: 'text-left text-normal text-cyan-500',

  perkCardCenter: 'text-center shadow-lg min-h-310',
  perkCardActive: 'text-center shadow-lg text-slate-800 border-solid bg-emerald-100 border-emerald-500 min-h-100 border-4',
  perkCardInactive: 'text-center shadow-lg text-slate-800 border-solid bg-rose-100 border-rose-500 min-h-100 border-4',

  }
  
  export default Style;