/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext} from 'react';

import { Card, ViewContext, Stat, ProgressBar, Chart,  Image, Button, Row, Link, SocialShare,
  Message, Grid, Animate, Feedback, useAPI, useNavigate } from 'components/lib';

import { useWeb3React } from "@web3-react/core";

import Axios from 'axios';
import Style from './project.tailwind.js';

export function ProjectDashboard(props){

  const user = useAPI('/api/user');
  const context = useContext(ViewContext);
  const navigate = useNavigate();
  const [projectList, setProjectsList] = useState(null);
  
  useEffect(() => { 
    async function getProjectList() {
      if(user.data) {
        //console.log(user.data);
        const res = await Axios.get('/api/projects/list');
        //console.log(res.data);
        setProjectsList(res.data.data.projects);
      }
    }

    getProjectList();

  },[user]);


  async function viewProject(project) {
    console.log(project);
    let navPath = `/project/${project.id}`;
    //console.log(navPath);
    navigate(navPath);
   
  };


  return (
    <Animate type='pop'>

      {/*<Grid cols='4'>
      <Stat
          labelSmall        
          //loading={ stats?.loading }
          //value={ recentEvent ? recentEvent.event_description: '--'}
          //label={ 'Last Event: ' + (recentEvent ? new Date(recentEvent.date_created).toUTCString() : '--')}
          icon=''
        />
        <Stat
          labelSmall
          loading={ false }
          label={ 'Global Spaces XP Events (Last Hour)' }
          //value={ lastHourInfo? lastHourInfo.event_count.toLocaleString()  : '--'}
          icon=''
        />
        <Stat
          labelSmall
          loading={ false }
          label={ 'Global Spaces XP (Last Hour)' }
          //value={ lastHourInfo? isNaN(parseInt(lastHourInfo.event_xp)) ? 0 : parseInt(lastHourInfo.event_xp).toLocaleString()  : '--'}
          icon=''
        />
        <Stat
          labelSmall
          loading={ false }
          label={ 'Users Online (Last Hour)' }
         // value={ lastHourInfo? lastHourInfo.users_online.toLocaleString()  : '--'}          
          icon=''
        />
      </Grid>
  */}



      { projectList && projectList.length === 0 && 
      <Message
        text="Scribe is not currently tracking any Twitter spaces."
        type='info'
      />
      }
      
      <Card>
        <strong>Choose the Scribe Partner project you wish to Mint.</strong>
      </Card>
      

      { projectList && projectList.length > 0 && 

        <Grid cols='2'>

          
            { projectList.map((project, index) => {
              
              return (
                project.title !== null &&
                  <Card shadow rounded
                    key={ project.id }
                    id={ project.id }
                    className={ Style.mintCardSmall }
                    center
                    >
                    <div key='project_info'>
                      <strong>{project.project_name}</strong><br/>
                      <span><strong>Mint price: </strong>{project.mint_cost}ETH</span><br/>
                      <span>{project.token_description}</span>
                    </div>
                    
                    <div key='space_button'>
                    <Button key="space" className={Style.spaceButton} tiny color={"green"} text='View' action={ e =>  {viewProject(project) }}/>
                    </div>
                      
                  </Card>
              )
              })
            }
            
          
        
      </Grid>
      }

      
      <Feedback />

    </Animate>
  );
}
