const Style = {

  base: 'inline-block mx-1 py-0.25 px-1 text-white uppercase text-xs rounded',
  red: 'bg-red-500',
  blue: 'bg-blue-500',
  yellow: 'bg-yellow-300',
  violet: 'bg-violet-400',
  green: 'bg-emerald-500',
  orange: 'bg-orange-500',
  white: 'bg-white',
  whiteborder: 'bg-white border-solid border-slate-50 text-slate-200',

}

export default Style;