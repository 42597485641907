/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback, Fragment} from 'react';
import { useLocation } from 'react-router-dom';
//import truncateEthAddress from 'truncate-eth-address';
import { UserActivity } from "views/dashboard/activity.js";
import { ActivityList } from "views/dashboard/activity_list";
import { CommunityList } from 'views/communities/community_list';
import { Card, Stat, ProgressBar, Chart,  Button, Image, Row, Link, useNavigate, Form, Search, CommunityViewNav, TabView, Table, Icon,
  Message, Grid, Animate, Feedback, useAPI, ViewContext, AuthContext, WalletConnect, DashboardNav } from 'components/lib';



import {CopyToClipboard} from 'react-copy-to-clipboard';


//https://eth-mainnet.g.alchemy.com/v2/RR7i6KryXLb4CRj-wkmv4Tyr_lr-9jj0
  
//import Axios from 'axios';
import Style from './dashboard.tailwind.js';

//let lastSearch = '';

export function UserActivities(props){

  
  const viewContext = useContext(ViewContext);
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAPI('/api/user');
  
  const [advanced, setAdvanced] = useState(false);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);  


  async function viewItem(item) {
    
    console.log(item);
    let itemAction = item.url;
    

      var win = window.open(itemAction, '_blank');
      
    
  };
  
  return (
    <Fragment>
      {/*communityInfo.data && <CommunityViewNav dark user={user} community_info={communityInfo.data.community} /> */}
      {user && user.data && 
      <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>
      }
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      
          
          <TabView dark center className='center' name='ActivityOptions' initialView={0} labels={ 
            ['Activities','History']
            }>
            <Card dark smallBorder><ActivityList hideNav /></Card>
            {/*<Card dark smallBorder><CommunityList hideNav /></Card>*/}
            <Card dark smallBorder><UserActivity hideNav /></Card>
            
            
            
          </TabView>
          
      </div>
      <Feedback />

    </Fragment>
  );
}
