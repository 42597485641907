/***
*
*   MODAL
*   Display an overlay modal anywhere in your application by calling
*   context.modal.show() with an object containing the following props
*
*   PROPS
*   title: (optional)
*   text: message to the user (optional)
*   form: a form object (optional: see form docs for more information)
*   url: destination to send the form
*   method: HTTP post type
*   buttonText – text for the confirm button
*
**********/

import React, { Fragment, useContext, useRef } from 'react';
import SignaturePad from 'react-signature-canvas'
import { ViewContext, Card, Form, Button, Link, Image } from 'components/lib';
import { CSSTransition } from 'react-transition-group';
import './modal.scss';
import styles from './signature.module.css';
import Style from './modal.tailwind.js';

export function Modal(props){
  //console.log(props);

  const sigPad = useRef();

  const context = useContext(ViewContext);

  const clear = () => {
    //console.log(sigPad.current.canvasProps);
    //console.log(sigPad.current.props);
    sigPad.current.clear()
  }
  const save = () => {
    
    //console.log(sigPad.current.props);
    let dataURL = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
    //console.log(dataURL);
    
    sigPad.current.props.signComplete(dataURL, sigPad.current.props.signer);
    context.modal.hide(true)
    
  }

  const trim = () => {
    this.setState({trimmedDataURL: this.sigPad.current.getTrimmedCanvas()
      .toDataURL('image/png')})
  }

  return (
   <CSSTransition in appear timeout={ 0 } classNames='modal'>
      <div className='modal'
        onClick={(e) => e.target === e.currentTarget && context.modal.hide(true)}>

        <div className={`modal-content ${props.scroll ? 'scroll' : ''}`}>

          <Card center title={ props.title } dark={props.dark ? props.dark : false}>

            { props.htmlPre &&
              {...props.htmlPre }
            }

            { props.preText && !props.text && 
              <>{ props.preText }<br/><br/></> }

            { props.image && 
              <>
                <Image
                  className={ Style.nftImage }
                  nftImage = {true}
                  border = {true}
                  source={props.image}
                />
            <br/><br/></> }

            { props.text && props.preText &&
              <><p className = 'text-center'><strong>{props.preText}</strong></p><p>{ props.text }<br/></p></> }

            { props.text && !props.preText &&
              <p className = 'text-center'><strong><span dangerouslySetInnerHTML={{ __html: props.text }}></span></strong><br/></p> }

            { props.text2 &&
              <p><i>{ props.text2 }</i><br/></p> }

            { props.form &&
              <Form
                className = 'text-center'
                method={ props.method }
                url={ props.url }
                data={ props.form }
                destructive={ props.destructive }
                buttonText={ props.buttonText }
                updateOnChange = {props?.updateOnChange}
                onChange = {e => { 
                  if(props.updateOnChange){
                    //console.log("Running onChange");
                    props.onChange(e);
                  }
                }}
                cancel={ e => context.modal.hide(true) }
              />
            }
            
            { !props.form && props.link && props.linkText &&
              <strong>
                <Link 
                  url={props.link} 
                  text={props.linkText ? props.linkText : props.link}
                  target={props.linkTarget ? props.linkTarget : "_new"} 
                />
                <br/><br/>
              </strong>
            }

            {props.sigPad && 
              <Fragment>
              <div className={styles.container}>
                <div className={styles.sigContainer}>
                <SignaturePad signComplete={props.sigPadComplete } signer={props.signer} canvasProps={{className: styles.sigPad}}
                  ref={(ref) => { sigPad.current = ref }} />
                </div>
                <Button
                  id='centerButton'
                  key='centerButton'
                  color={ props.destructive ? 'red' : 'green' }
                  text={ "Clear" }
                  action={ (e) => clear() }
                />


              </div>
              
              </Fragment>
            }
            
            { sigPad && sigPad.current && 
              <>
              <Button
                id='centerButton2'
                key='centerButton2'
                color={'red'}
                text={ "Cancel" }
                action={ (e) => context.modal.hide(true) }

              />
              <Button
                id='centerButton'
                key='centerButton'
                color={ props.destructive ? 'red' : 'green' }
                text={ "Inscribe" }
                action={ (e) => save() }
              />
              </>
            }

            { !props.form && props.buttonText &&
              <Button
                id='centerButton'
                key='centerButton'
                color={ props.destructive ? 'red' : 'green' }
                text={ props.buttonText }
                action={ (e) => context.modal.hide(true) }
              />
            }
            { !props.form && props.buttonCancelText &&
              <Button
                id='centerButtonCancel'
                key='centerButtonCancel'
                color={'outline' }
                text={ props.buttonCancelText }
                action={ (e) => context.modal.hide(true) }
              />
            }

            { props.html &&
              {...props.html }
            }

            
         
          </Card>
        </div>
      </div>
    </CSSTransition>
  );
}
