const Style = {
    small: 'p-0 text-sm text-normal-tight',
    smaller: 'p-0 text-xs font-semibold',
    smallest: 'p-0 text-xxs font-semibold',
    smallWhite: 'p-0 text-sm text-normal-tight text-white',
    smallerWhite: 'p-0 text-xs font-semibold text-white',
    smallestWhite: 'p-0 text-xxs font-semibold text-white',
    textWhite: 'p-0 text-s text-white',
    textWhiteSmall: 'p-0 text-s text-white',
    textWhiteSmaller: 'p-0 text-xs text-white',
    textWhiteSmallest: 'p-0 text-xxs text-white',

    devButtons: 'px-7 py-3 max-w-220',
    devButton: 'p-1 text-xxs font-semibold mb-1 max-w-sm',

    scheduledSpaceCard: 'text-center min-h-380 shadow-lg',
    scheduledSpaceCardDark: 'text-center min-h-380 shadow-lg bg-slate-700',
    spaceTitle: 'text-center min-h-100 h-8',
    spaceTitleSmall: 'text-center min-h-60 max-h-50 h-14',
    spaceCard: 'text-center min-h-360 max-h-360 shadow-lg overflow-y-auto',
    spaceCardDark: 'text-center min-h-495 max-h-495 shadow-lg bg-slate-700 overflow-y-auto',
    spaceCardTall: 'text-center min-h-565 max-h-565 max-w-8 shadow-lg overflow-y-auto',
    spaceCardTallDark: 'text-center min-h-565 max-h-565 max-w-8 shadow-lg bg-slate-700 overflow-y-auto mb-1',
    spaceCardTallDarkThin: 'text-center max-w-sm min-h-565 max-h-565 shadow-lg bg-slate-700 overflow-y-auto mb-1',
    spaceCardTallReallyDark: 'text-center min-h-565 max-h-565 max-w-8 shadow-lg bg-slate-800 overflow-y-auto',
    spaceCardTallSuperDark: 'text-center min-h-565 max-h-565 max-w-8 shadow-lg bg-slate-900 overflow-y-auto',

    gameCardTall: 'p-1 pl-2 text-center min-w-1066 min-h-600 shadow-lg',
    gameCardTallMobile: '!p-0 text-center aspect-[9/16] shadow-lg',

    user: 'p0 mx-auto text-center shadow-lg',
    userCard: 'relative p-1 x-1 mb-5 min-h-135 bg-white rounded last-of-type:mb-0',
    userCardSmall: 'relative p-1 x-1 mb-2 min-h-90 bg-white rounded last-of-type:mb-0',
    userName: 'p0 text-xxs font-semibold',
    nftButton: 'px-7 !py-1 mx-auto text-center mt-1',
    spaceButton: 'px-7 !py-1 mx-auto text-center mt-1',
    nftImage: 'max-h-32 sm:max-h-38 mx-auto aspect-square',
    spaceUserImage: 'max-h-8 sm:max-h-8 max-w-8 sm:max-w-8',
    fillRaw: 'h-full ease-in-out duration-1000 bg-orange-500!',
    socialIcon: 'absolute z-5 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
  }
  
  export default Style;