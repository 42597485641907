/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import React from 'react';
import { Link, ClassHelper } from 'components/lib';
import LogoWhite from './images/ScribeLogoTrans_Web3.png';
import LogoDieMark from './images/NFTScribeDieLogoGold.png';
//import LogoWhite from './images/NFTScribe.jpg';
//import LogoWhite from './images/logo-white.svg';
//import LogoWhite from './images/logo-white.svg';
import LogoMarkWhite from './images/logo-mark-white.svg';
import LogoMetaverse from './images/ScribeLogoTrans_2000-3-1-300x216.png';
//import LogoColor from './images/logo-color.svg';
//import LogoMarkColor from './images/logo-mark-color.svg';
import Style from './logo.tailwind.js';

export function Logo(props){

  const Logo = {
    color: {
      logo: LogoWhite,
      mark: LogoDieMark,
      meta: LogoMetaverse
    },
    white: {
      logo: LogoWhite,
      mark: LogoMarkWhite
    }
  }

  

  const logoStyle = ClassHelper(Style, props);
  let color = props.color ? 'color' : 'white';
  let mark = props.mark ? 'mark' : 'logo';
  let meta = props.meta ? 'meta' : null;
  let imageSrc = Logo[color][meta ? meta : mark];
  //Logo[props.color ? 'color' : 'white'][props.mark ? 'mark' : 'logo']
  
  
  return(
    <Link url='/' className={ logoStyle }>
      <img src={ imageSrc } alt='Logo' />
    </Link>
  )
}
