/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import React from 'react';
import { Animate, Row, Content, Link } from 'components/lib'
import Style from './footer.tailwind.js';

export function Footer(props){

  return (
    <Row dark>
      <footer className={ Style.footer }>
        
          <Content>

            <nav className={ Style.nav }>
              <Link url='/' text='Home' className={ Style.link } color='cyan'/>
              {/*<Link url='/pricing' text='Pricing' className={ Style.link } color='dark' />*/}
              <Link url='/signin' text='Sign in' className={ Style.link } color='cyan' />
              {/*<Link url='/signup' text='Sign up' className={ Style.link } color='dark' />*/}
              <Link url='/terms' text='Terms' className={ Style.link } color='cyan' />
              <Link url='/privacy' text='Privacy' className={ Style.link } color='cyan' />
              <Link url='/contact' text='Contact' className={ Style.link } color='cyan' />
            </nav>

            <div className={ Style.copyright }>{ `Copyright © ${new Date().getFullYear()} Intergalactic Play, LLC. `}</div>

          </Content>
        
      </footer>
    </Row>
  );
}