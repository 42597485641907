/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback, Fragment} from 'react';
import { useLocation } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
//import truncateEthAddress from 'truncate-eth-address';
import { Card, Stat, ProgressBar, Chart,  Button, Image, Row, Link, Form, Search, CommunityViewNav,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, AuthContext, WalletConnect } from 'components/lib';

import { ethers, utils, BigNumber } from 'ethers';

//https://eth-mainnet.g.alchemy.com/v2/RR7i6KryXLb4CRj-wkmv4Tyr_lr-9jj0
  
//import Axios from 'axios';
//import Style from './project.tailwind.js';

//let lastSearch = '';

export function CommunityViewMetrics(props){

  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const location = useLocation();
  //const [tokenPrice, setTokenPrice] = useState(null);
  //const [contractABI, setContractABI] = useState(null);
  //const [contractAddress, setContractAddress] = useState(null);
  
  //const [walletAddress, setWallet] = useState("");
  //const [status, setStatus] = useState("");
  //const [waitMint, setWaitMint] = useState(false);

  //const [name, setName] = useState("");
  //const [description, setDescription] = useState("");
  //const [url, setURL] = useState("");

  //const { active, account, chainId } = useWeb3React();
  const [chartData, setChartData] = useState(null);
  const [communityError, setCommunityError] = useState(false);
  const [advanced, setAdvanced] = useState(false);

  
  
  let pathSplit = location.pathname.split("/");
  const community_slug = pathSplit[pathSplit.length-1];
  const communityInfo = useAPI('/api/community/' + community_slug);
  const communityChartInfo = useAPI('/api/community/chart/' + community_slug);
  const user = useAPI('/api/user', authContext);

  function truncateToken(tokenString){
    let retString = tokenString;
    if(tokenString.length > 16){
      let strLen = tokenString.length;
      let string1 = tokenString.substring(0,12);
      let string2 = tokenString.slice(-4);
      retString = string1 + "..." + string2;
    }
    return retString;
  }

  useEffect(() => {

    //console.log(communityInfo);
    if(communityInfo.loading){
      //console.log("LOADING NO DATA YET");
    } else {
      if(!communityInfo.success){
        //console.log("NOT LOADING, FAIL");
        setCommunityError(true);
      }
    }
    if(communityInfo && communityInfo.data) {
      console.log(communityInfo);
      viewContext.setTitle(communityInfo.data.community.community_name + " [Metrics]");
    }

    if(communityChartInfo && communityChartInfo.data){
      setChartData(communityChartInfo.data.community.chartData);
    }

  }, [communityInfo, communityChartInfo]);


  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);
  

  async function loadContract() {
    //return new web3.eth.Contract(contractABI, contractAddress);
  }

  
  return (
    <Fragment>
      <CommunityViewNav dark user={user} community_info={communityInfo.data} center={!advanced} loading={communityInfo.loading} loadFail={communityInfo.loading ? false : communityError} />
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      {!communityInfo.loading && communityInfo.data && communityInfo.data.project &&
        <Card>
          <strong>{communityInfo.data.project.project_name}</strong><br/>
        </Card>
      }
      {chartData &&
      <>
        { chartData.map( (chartDataSet, index) =>{
          return (
            
            <Row small key={`row_${index}`} >
              <Chart 
                className='p-2'
                key={`chart_${index}`}
                type='line'
                legend
                data={ chartDataSet }
                color={[chartDataSet.color]}
              />
            </Row>
            
          );
        })
        }
      </>
      }
      </div>
      <Feedback />

    </Fragment>
  );
}
