import { LeaderboardHome } from 'views/leaderboard/index';
import { LeaderboardNFT } from 'views/leaderboard/leaderboard_nft';
import { LeaderboardMetaverse } from 'views/leaderboard/leaderboard_metaverse';
import { Leaderboards} from 'views/leaderboard/leaderboards';


const Routes = [
  {
    path: '/leaderboard',
    view: LeaderboardHome,
    layout: 'appDark',
    //permission: 'user',
    title: 'Leaderboards'
  },
  {
    path: '/leaderboards2',
    view: Leaderboards,
    layout: 'appDark',
    //permission: 'user',
    title: 'Leaderboards'
  },
  {
    path: '/leaderboards',
    view: LeaderboardHome,
    layout: 'appDark',
    //permission: 'user',
    title: 'Leaderboards'
  },
  {
    path: '/leaderboard/nft',
    view: LeaderboardNFT,
    layout: 'appDark',
    //permission: 'user',
    title: 'Leaderboard - NFT'
  },
  {
    path: '/leaderboard/metaverse',
    view: LeaderboardMetaverse,
    layout: 'appDark',
    //permission: 'user',
    title: 'Leaderboard - Metaverse'
  },
  

  
]

export default Routes;
