/***
*
*   DEVELOPER NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React from 'react';
import { SubNav } from 'components/lib';

export function DeveloperNav(props){

  return(
    <SubNav dark items={[

      { link: '/developer/applications/', label: 'Applications', permission: 'developer' },
      { link: '/developer/apikeys/', label: 'API Keys', permission: 'developer' },

    ]}/>
  );
}