const Style = {

  stat: 'relative text-center min-h-max',
  icon: 'absolute top-1/2 left-4 -translate-y-1/2',
  value: 'text-3xl font-bold',
  valueDark: 'text-2xl font-bold text-slate-800',
  valueSmall: 'text-xl font-bold',
  userCardStat: 'mx-auto text-center min-h-65 shadow-lg p-1 bg-slate-900 border-green-400 border',
  communityStat: 'mx-auto text-center shadow-lg bg-slate-900 border-green-400 border !p-0',
  change: 'absolute top-1/2 right-5 text-sm text-center -translate-y-1/2',
  changeIcon: 'absolute top-[0.1em] -right-5',
  label: 'text-sm text-slate-400',
  labelSmall: 'text-xs text-slate-400',

}

export default Style;