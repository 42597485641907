/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
//import useWindowSize from 'react-use/lib/useWindowSize';

import { useLocation } from 'react-router-dom'
import { Table, NFTHolderNav, ViewContext, useNavigate, Message, Feedback, useAPI, DashboardNav, Row, Card } from 'components/lib';

//import Style from '../nft.tailwind.js';
//import { useWeb3React } from "@web3-react/core";
import Axios from 'axios';

//Solana stuff
//import { useConnection, useWallet } from "@solana/wallet-adapter-react";

export function UserActivity(props){

  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAPI("/api/user");

  //const onlyWidth = useWindowWidth()
  //const onlyHeight = useWindowHeight()

  //let pathSplit = location.pathname.split("/");
  //const collection_id = pathSplit[pathSplit.length-1];
  //const user_name = pathSplit[pathSplit.length-1];

  // fetch
  //const nftData = useAPI('/api/nft/' + nft_id);
  const [eventData, setEventData] = useState(null);
  //const [unAssignedXP, setUnAssignedXP] = useState(0);
  //const { active, account, chainId } = useWeb3React();
  //const [existingAccount, setAccount] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [advanced, setAdvanced] = useState(false);
  

  // We get the public key of the connected Solana wallet, if there is one
  //const { connection } = useConnection();
  //console.log("Solana wallet: " + publicKey);

  const getDetails = useCallback(async (user_name) => {

    //setDetailsLoading(true);
    console.log("Getting Details: " + user_name);
    const res = await Axios.post("/api/user/name/events", {
        user_name: user_name,
        truncate_events: false,
      });

    if(!res.data.success){
      console.log("Unauthorized");
      navigate('/home');
    } else {
      console.log(res.data);
      //let unAssignedXP = (res.data.data.nftData.xp_raw - res.data.data.nftData.xp).toLocaleString();
      //setUnAssignedXP(unAssignedXP);
      setEventData(res.data.data);
      setDetailsLoading(false);      

    }

  }, [user, navigate]);

  useEffect(() => {
    //console.log("Initial load");
    const id = user.name;
    if (user.data) getDetails(user.data.name);
  }, [getDetails, user])

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);
    
  return (
    
    <Fragment>
        {user && user.data && !props.hideNav && 
          <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>
        }
        <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
        
        <div className='pb-4 text-xl font-semibold text-cyan-400'>User Events (2-Week History)</div>
      

        {!detailsLoading && eventData && eventData.eventCollection.length === 0 && 
          <Message 
            closable
            messageID="Message_UserEvents"
            color='transparent' 
            title='Events' 
            text={'Events your user profile is collecting will be listed below'}
          />
        }

        
        <Card dark>
          <Table
            search
            dark
            data={ detailsLoading? null : eventData.eventCollection }
            loading={ detailsLoading }
            show={[
              "application",
              "nft",
              "description",
              "value",
              "date",
            ]}
            badge={{ col: 'plan', color: 'blue' }}>
              
          </Table>
        </Card>
        
      </div>
      <Feedback />
    </Fragment>
  );
}
