const Style = {

  base: 'py-0 sm:py-10 md:py-14 px-0 text-left bg-white',
  small: 'py-4 md:py-8 !px-4 text-left bg-white',
  smallDark: 'py-4 md:py-8 !px-4 text-left bg-slate-700 text-white',
  tinyDark: '!py-2 !sm:py-2 !md:py-4 !px-0 text-left bg-slate-700 text-white',
  tinyDarker: '!py-2 !sm:py-2 !md:py-4 !px-0 text-left bg-slate-900 text-white',
  tiny: 'py-2 sm:py-2 md:py-4 !px-0 text-left',
  dark: 'py-10 sm:py-2 md:py-2 px-0 text-left bg-slate-700 text-white',
  darkNormal: 'py-10 sm:py-2 md:py-2 px-0 text-left bg-slate-700',
  transparent: 'bg-transparent',
  tint: 'bg-slate-50',
  brand: 'bg-brand-500 text-white',
  left: 'text-left',
  center: 'text-center',
  interactive: 'cursor-pointer',
  
}

export default Style;