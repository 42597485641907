/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/
 import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
 import React, { useEffect, useState, useContext, useCallback } from "react";
 import { useLocation } from "react-router-dom";
 import { ConsoleView, isMobile } from "react-device-detect";
 import {
   Card,
   ViewContext,
   AuthContext,
   Stat,
   ProgressBar,
   Chart,
   Image,
   Button,
   Row,
   Link,
   SocialShare,
   Message,
   Grid,
   Animate,
   Feedback,
   useAPI,
   useNavigate,
   CollectionNav,
 } from "components/lib";
 
 import { useWeb3React } from "@web3-react/core";
 import { Unity, useUnityContext } from "react-unity-webgl";
 import Axios from "axios";
 import Style from "./game_dashboard.tailwind.js";
 
 import io from "socket.io-client";
 import Messages from "../spaces/messages";
 import MessageInput from "../spaces/messageInput";
 import "../spaces/chat.css";
 
 const delay = 30; //seconds
 const doChat = true;
 let spaceTimer;
 
 export function GamesDashboard(props) {
   const location = useLocation();
   let pathSplit = location.pathname.split("/");
   const space_id = pathSplit[pathSplit.length - 1];
 
   localStorage.setItem("game_id", space_id);
 
   
   const showTestButton = useAPI("/api/var/show_game_test");
   const context = useContext(ViewContext);
   const authContext = useContext(AuthContext);
   const user = useAPI("/api/user", authContext);
   const navigate = useNavigate();
   const [spaceInfo, setSpaceInfo] = useState(null);
   const [userSocialLevelInfo, setUserSocialLevelInfo] = useState(null);
   const [userRole, setUserRole] = useState(null);
   const [socket, setSocket] = useState(null);
   const [doGame, setDoGame] = useState(null);
   const [showNav, setShowNav] = useState(true);
   const [showSpace, setShowSpace] = useState(true);
   const [userFlags, setUserFlags] = useState([]);
   const [numTeams, setNumTeams] = useState(0);
   const [nftCollection, setNFTCollection] = useState(null);
   const [numTeamsChanged, setNumTeamsChanged] = useState(false);
   const [isUnityLoaded, setUnityLoaded] = useState(false);
   const [teams, setTeams] = useState([]);
   const [gameSessions, setGameSessions] = useState(null);
 
   const urlParams = new URLSearchParams(window.location.search);
   let dev = urlParams.get("dev");
 
   const {
     unityProvider,
     sendMessage,
     isLoaded,
     loadingProgression,
     addEventListener,
     removeEventListener,
     requestFullscreen,
     unload,
   } = useUnityContext(
     isMobile
       ? {
           loaderUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.loader.js",
           dataUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.data",
           frameworkUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.framework.js",
           codeUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.wasm",
         }
       : dev
       ? {
           loaderUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.loader.js",
           dataUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.data",
           frameworkUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.framework.js",
           codeUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.wasm",
         }
       : {
           loaderUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.loader.js",
           dataUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.data",
           frameworkUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.framework.js",
           codeUrl:
             "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.wasm",
         }
   );
 
   const loadingPercentage = Math.round(loadingProgression * 100);
 
   function truncateString(theString, truncLength = 50) {
     let retString = theString;
     if (theString.length > truncLength) {
       let strLen = theString.length;
       let string1 = theString.substring(0, truncLength);
       retString = string1 + "...";
     }
     return retString;
   }
 
   const { navigator } = useContext(NavigationContext);
 
   /*useEffect(() => {
       console.log("NAV CHECK");
        const unblock = navigator.block( async ( tx ) => {
         console.log(tx);
         console.log("BLOCKING NAV CHECK");
           if(isUnityLoaded) {
             console.log("Unity is loaded");
               await unload();
               //await setIsLoaded(false)
               setUnityLoaded(false);
               unblock();
               tx.retry()
           } else {
             console.log("Unity is not loaded");
             console.log("UN BLOCKING NAV CHECK");
             unblock();
             tx.retry();
             return true;
           }
         } );
         return unblock;
     }, [navigator, isUnityLoaded])
     */
 
   const getGames = useCallback(
     async (filter, minEventDate) => {
       let gameServer =
         window.location.hostname === "localhost"
           ? ""
           : "https://game.nftscribe.io";
       const getGameStateRes = Axios.post(gameServer + "/api/game/getSessions/").then((res) => {
         //console.log(res);
         if (res.data) {
           let theGameSessions = res.data.data;
           //console.log(theGameSessions);
          theGameSessions.sort(function (a, b) {
            return a.livePlayerCount < b.livePlayerCount ? -1 : 1;
          });
          /*theGameSessions.sort(function (a, b) {
            return a.sessionOptions.sessionName ? -1 : 1;
          });*/
           setGameSessions(theGameSessions);
         }
       });
 
       setNFTCollection({
         partner: 1,
       });
     },
     [space_id]
   );
 
   useEffect(() => {
     getGames("level", "2022-04-01");
   }, [getGames]);
 
   async function getSpaceList() {
     //if (user.data) {
       //console.log(user.data);
       //console.log("Getting Space List");
       //console.log(user.data);
       let userflags = user.data ? user.data.flags ? user.data.flags.split(",") : [] : [];
       //console.log(userflags);
       setUserFlags(userflags);
       let theSpaceInfo = {
         elapsedHours: 1,
         participant_count: 1,
         join_code: "Nothing",
         join_code_base: "Nothing",
         id: "123",
         space_link: "https://app.nftscribe.io/game/" + space_id,
         space_id: space_id,
         title: "Game Test",
         allSpaceUsers: [],
       };
       setSpaceInfo(theSpaceInfo);
     //}
   }
 
   useEffect(() => {
     //console.log("showTestButton: " + showTestButton);
     //console.log(showTestButton);
     getSpaceList();
   }, [user]);
 
   const handleUnityAppReady = useCallback(
     (game_id) => {
       console.log("handleUnityAppReady " + game_id);
 
       let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url;
       if (serverURL.includes("localhost")) {
         serverURL = "http://" + serverURL + ":8080";
       } else {
         //serverURL = "https://" + serverURL;
         serverURL = "https://game.nftscribe.io";
       }
       setUnityLoaded(true);
       let startString = user.data.id + "|" + space_id + "|" + serverURL;
       sendMessage("GameController", "StartApp", startString);
     },
     [sendMessage, user, space_id]
   );
 
   const handleUnityAppReady_Confirm = useCallback(
     (local_user_id) => {
       console.log("handleUnityAppReady_Confirm " + local_user_id);
 
       let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url;
       if (serverURL.includes("localhost")) {
         serverURL = "http://" + serverURL + ":8080";
       } else {
         //serverURL = "https://" + serverURL;
         serverURL = "https://game.nftscribe.io";
       }
 
       let userCredits = 1000000;
       let startString =
         user.data.id +
         "|" +
         space_id +
         "|" +
         serverURL +
         "|" +
         userCredits;
       sendMessage("GameController", "StartAppFinal", startString);
     },
     [sendMessage, user, space_id]
   );
 
   const handleUnityOnBuyItem = useCallback(
     (local_user_id, item_id) => {
       console.log("handleUnityOnBuyItem " + local_user_id + "  " + item_id);
 
       let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url;
       if (serverURL.includes("localhost")) {
         serverURL = "http://" + serverURL + ":8080";
       } else {
         //serverURL = "https://" + serverURL;
         serverURL = "https://game.nftscribe.io";
       }
 
       //setUnityLoaded(true);
       let startString = user.data.id + "|" + space_id + "|" + serverURL;
       //sendMessage("GameController", "StartApp", startString);
     },
     [sendMessage, user, space_id]
   );
 
   useEffect(() => {
     addEventListener("OnAppReady", handleUnityAppReady);
     return () => {
       removeEventListener("OnAppReady", handleUnityAppReady);
     };
   }, [addEventListener, removeEventListener, handleUnityAppReady]);
 
   useEffect(() => {
     addEventListener("OnAppReady_Confirm", handleUnityAppReady_Confirm);
     return () => {
       removeEventListener("OnAppReady_Confirm", handleUnityAppReady_Confirm);
     };
   }, [addEventListener, removeEventListener, handleUnityAppReady_Confirm]);
 
   useEffect(() => {
     addEventListener("OnBuyItem", handleUnityOnBuyItem);
     return () => {
       removeEventListener("OnBuyItem", handleUnityOnBuyItem);
     };
   }, [addEventListener, removeEventListener, handleUnityOnBuyItem]);
 
   useEffect(
     () => {
       let timer1 = setInterval(() => getGames(), 30 * 1000);
 
       // this will clear Timeout
       // when component unmount like in willComponentUnmount
       // and show will not change to true
       return () => {
         clearTimeout(timer1);
       };
     },
     // useEffect will run only one time with empty []
     // if you pass a value to array,
     // like this - [data]
     // than clearTimeout will run every time
     // this value changes (useEffect re-run)
     []
   );
 
   useEffect(() => {
     console.log("Changing num teams");
     if (numTeamsChanged) {
       sendGameState("startGame", true);
     }
   }, [numTeams, teams]);
 
   async function launchGame(space) {
     console.log(space);
     setDoGame(true);
   }
 
   const beTheChange = async (e, stateName, showModal) => {
     //console.log("beTheChange");
     //console.log(e);
     if (e.input === "numTeams") {
       //console.log("Setting num teams");
       let theNumTeams = parseInt(e.value);
       //console.log(theNumTeams);
 
       setNumTeamsChanged(true);
       setNumTeams(theNumTeams);
 
       let existingTeams = teams;
       //console.log(existingTeams);
       let newTeams = [];
       for (let x = 0; x < theNumTeams; x++) {
         newTeams.push({
           id: x + 1,
           name:
             existingTeams[x] !== undefined
               ? existingTeams[x].name
               : "Team " + (x + 1),
           group:
             existingTeams[x] !== undefined ? existingTeams[x].group : "Any",
         });
       }
 
       //console.log(newTeams);
       setTeams(newTeams);
     }
 
     if (e.input.includes("team_name_")) {
       let team_id = parseInt(e.input.replace("team_name_", ""));
       //console.log("Team ID:" + team_id);
       let existingTeams = teams;
       //console.log(existingTeams);
       let theTeam = existingTeams.filter((x) => x.id === team_id);
       theTeam[0].name = e.value;
       setNumTeamsChanged(true);
       setNumTeams(existingTeams.length);
       setTeams(existingTeams);
 
       //console.log(existingTeams);
 
       //console.log("Setting num teams");
       //let theNumTeams = parseInt(e.value);
       //console.log(theNumTeams);
       //setNumTeamsChanged(true);
       //setNumTeams(theNumTeams);
     }
 
     if (e.input.includes("team_group_")) {
       let team_id = parseInt(e.input.replace("team_group_", ""));
       //console.log("Team ID:" + team_id);
       let existingTeams = teams;
       console.log(existingTeams);
       let theTeam = existingTeams.filter((x) => x.id === team_id);
       theTeam[0].group = e.value;
       setNumTeamsChanged(true);
       setNumTeams(existingTeams.length);
       setTeams(existingTeams);
 
       console.log(existingTeams);
 
       //console.log("Setting num teams");
       //let theNumTeams = parseInt(e.value);
       //console.log(theNumTeams);
       //setNumTeamsChanged(true);
       //setNumTeams(theNumTeams);
     }
   };
 
   async function sendGameState(stateName, showModal) {
     //console.log(stateName);
     //console.log(numTeams);
 
     let gameServer =
       window.location.hostname === "localhost" ? "": "https://game.nftscribe.io";
 
     if (stateName === "startGame") {
       let collectionGroups = ["Any", "AI Easy", "AI Normal", "AI Hard"];
       let teamOptions = collectionGroups.map((x) => {
         return {
           value: capitalizeFirstLetter(x),
           label: capitalizeFirstLetter(x),
         };
       });
 
       let startGameForm = {
         space_id: {
           type: "hidden",
           value: space_id,
         },
         stateName: {
           type: "hidden",
           value: stateName,
         },
         /*bonusPost: {
           label: `Bonus Tweet (Interacters get starting bonus)`,
           type: 'text',
         },*/
         numTeams: {
           label: `Number Teams (0 teams = Everyone vs Everyone)`,
           type: "number",
           value: isNaN(numTeams) ? "0" : numTeams + "",
           min: 0,
           max: 8,
           required: true,
           errorMessage: `Please enter a number between 0 and 8`,
         },
       };
 
       for (var x = 0; x < numTeams; x++) {
         let teamCounter = x + 1;
         let teamName = teams[x].name;
         let teamGroup = teams[x].group;
         //console.log(teamName, teamGroup);
         //console.log(teamOptions);
         /*
         team1: {
           label: 'Team 1 Name',
           type: 'text'
         },
         team1_type: {
           label: 'Team 1 Members',
           type: 'select',
           options: teamOptions
         },
         */
         startGameForm[`team_name_${teamCounter}`] = {
           label: `Team ${teamCounter} Name`,
           type: "text",
           value: teamName,
         };
         startGameForm[`team_group_${teamCounter}`] = {
           label: `Team ${teamCounter} Player Type`,
           type: "select",
           value: teamGroup,
           default: teamGroup,
           options: teamOptions,
         };
       }
 
       context.modal.show(
         {
           scroll: true,
           title: "Start Game Session",
           text: "Set your team names and game options here",
           form: startGameForm,
           updateOnChange: true,
           onChange: function (e) {
             beTheChange(e, stateName, showModal);
           },
           buttonText: "Go",
           //text: 'Choose the join code for the space.',
           url: gameServer + "/api/game/create",
           method: "POST",
         },
         (res, theData) => {
           if (res.data) {
           }
         }
       );
 
       /*const launchGameRes = Axios.post(gameServer + '/api/game/create', { 
         space_id : space_id
       }).then((res) => {
         //console.log(res);
         if(res.data){
   
         }
       });
       */
       return;
     }
 
     if (showModal) {
       context.modal.show(
         {
           title: "Game Commands",
           text: "Add the param you need to send!",
           form: {
             space_id: {
               type: "hidden",
               value: space_id,
             },
             stateName: {
               type: "hidden",
               value: stateName,
             },
             param: {
               label: "Param",
               type: "text",
             },
           },
           buttonText: "Go",
           //text: 'Choose the join code for the space.',
           url: "/api/game/state/",
           method: "POST",
         },
         (res, theData) => {
           //context.notification.show(data.name + ' was deleted', 'success', true);
           //callback();
           //getSpaceList();
         }
       );
     } else {
       const sendGameStateRes = Axios.post(gameServer + "/api/game/state/", {
         space_id: space_id,
         stateName: stateName,
       }).then((res) => {
         //console.log(res);
         if (res.data) {
         }
       });
     }
   }
 
   function capitalizeFirstLetter(string) {
     return string.charAt(0).toUpperCase() + string.slice(1);
   }
 
   function handleClick() {
     console.log("Full Screen");
     requestFullscreen(true);
   }
 
   function handleClickEnterFullscreen() {
     requestFullscreen(true);
   }
 
   return (
     <Row small>
       {spaceInfo && (
         <>
           
 
           {nftCollection && nftCollection.partner === 1 && (
             <>
               
               {showNav && (
                 <Grid cols="3">
                 
                   <Card key="instructions">
                     <div>
                       <strong>
                         Need a new weapon?{" "}
                         <Link
                           title="Get one here!"
                           target="_same"
                           url="/shop/lootshop-weapons-1"
                           text="Get Some Here!"
                         />
                       </strong>
                     </div>
                   </Card>

                   <Card key="instructions2">
                     <div>
                       <strong>
                         Want to run your own Battlefield?{" "}
                         <Link
                           title="Get one here!"
                           target="_same"
                           url="/shop/battlefields"
                           text="Get one here!"
                         />
                       </strong>
                     </div>
                   </Card>
                   
                 </Grid>
               )}
 
              
             </>
           )}



            <Grid cols="5">
              {
                gameSessions && gameSessions.map((gameSession, index) => {
                  console.log(gameSession);

                  return (
                    gameSession.id !== null && (
                      <Card
                        shadow
                        rounded
                        //nobadge={nft.badge}
                        //badgeText={nft.badge}
                        //badgeColor={"blue"}
                        //brandIcon={nft.badge}
                        //brandText={nft.badge}
                        //brandColor={"blue"}
                        key={gameSession.id}
                        id={gameSession.id}
                        className={Style.gameContainer}
                        //sideBadges={nft.buckets}
                        //topBadge={nft.todayInscribed > 0 ? "Leveled Up to " + nft.todayInscribed + "!" : ""}
                        //ulBadge={nft.chain}
                        center
                      >
                       
                        <div className={Style.collectionTitle}>
                          {gameSession.sessionOptions.sessionName ? gameSession.sessionOptions.sessionName : gameSession.battlefield.nickname}
                        </div>
                        {gameSession.sessionOptions.sessionName ? 
                          <div className={Style.collection}>
                            Current Map: {gameSession.battlefield.nickname ? gameSession.battlefield.nickname : "Battlefield #" + gameSession.battlefield.token_id}
                          </div>
                          : <div className={Style.collection}>
                            &nbsp;
                          </div>
                        }
                        <div className={Style.collection}>
                          Owner: {gameSession.battlefield.show_username === 1 ? gameSession.battlefield.username : "[Private]"}
                        </div>
                        <div className={Style.gameStats}>
                          Players: {gameSession.livePlayerCount}<br/>
                          Wave: {gameSession.waveNumber}<br/>
                        </div>

                        {/*<div className={Style.nftname}>{nft.name}</div>*/}

                        {/*<div className={Style.nftname}>
                          {nft.nickname ? (
                            "Name: " + nft.nickname + " "
                          ) : (
                            <>&nbsp;</>
                          )}
                          {nft.title ? "'" + nft.title + "'" : <>&nbsp;</>}
                          </div>*/}

                        <Image
                          className={Style.nftImage}
                          nftImage={true}
                          border={true}
                          loading="lazy"
                          source={gameSession.battlefield.image_url}
                          alt={gameSession.battlefield.nickname}
                          interactive
                          action={(e) => {
                            //viewNFT(nft);
                          }}
                        />

                        <Button
                          className={Style.nftButton}
                          color={"green"}
                          variant="contained"
                          url={`/game/${gameSession.spaceID}`}
                          text={"Play"}
                        />
                      </Card>
                    )
                  );
                }) // return
              }
            </Grid>
         </>
       )}
 
       <Feedback />
     </Row>
   );
 }
 