const Style = {

  base: 'relative p-4 x-1 mb-2 bg-white rounded last-of-type:mb-2',
  dark700: 'bg-slate-700 text-slate-400',
  dark: 'bg-slate-800 text-slate-400',
  darker: 'bg-slate-900 text-slate-400',
  slate200: 'bg-slate-200',
  blue200: 'bg-blue-200',
  header: 'relative pb-5 mb-5 border-dotted border-slate-100 border-b',
  lightHeader: 'relative pb-1 mb-1 border-dotted border-slate-100 text-slate-100',
  smallHeader: 'relative border-dotted border-slate-100 border-b !last-of-type:mb-2',
  blueHeader: 'bg-blue-500 text-white text-center p-2 relative border-dotted border-slate-500 border-b ',
  title: 'float:left m-0 text-lg font-semibold',
  noBottomMargin: 'mb-0',
  center: 'mx-auto',
  loading: 'h-60',
  shadow: 'drop-shadow-sm',
  noPadding: '!p-0',
  tightPadding: '!p-2',
  restrictWidth: 'max-w-md',
  restrictWidthMore: 'max-w-sm',
  restrictWidthWide: 'max-w-2xl',
  restrictWidthWider: 'max-w-3xl',
  smallBorder: '-mx-4',
  interactive: 'cursor-pointer',
  last: 'mb-0',
  badgeBlue: 'bg-blue-500',
  badge: 'absolute right-0 top-1',
  badge2: 'absolute left-0 top-1',
  badgeUL: 'absolute left-0 top-1 text-xxs bg-slate-500 text-white',
  badgeTop: 'absolute top-1 left-1/2 transform -translate-x-1/2 text-s bg-yellow-300 text-slate-900 font-bold mx-0',
  badgeTopCenter: 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs bg-blue-500 text-white',

  badgeSide1: 'absolute right-0 top-24 text-xxs',
  badgeSide1Text: 'bg-white border-solid border-slate-500 text-slate-700 text-center absolute right-0 top-28 text-xxs',
  badgeSide2: 'absolute right-0 top-32 text-xxs',
  badgeSide2Text: 'bg-white border-solid border-slate-500 text-slate-700 absolute right-0 top-36 text-xxs',
  badgeSide3: 'absolute right-0 top-40 text-xxs',
  badgeSide3Text: 'bg-white border-solid border-slate-500 text-slate-700 absolute right-0 top-44 text-xxs',
  badgeSide4: 'absolute right-0 top-48 text-xxs',
  badgeSide4Text: 'bg-white border-solid border-slate-500 text-slate-700 absolute right-0 top-52 text-xxs',
  
  platformIcon: 'text-blue-500 absolute right-2 top-1',
}

export default Style