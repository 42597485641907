const Style = {

  wrapper: 'relative min-h-[10rem]',
  blankslate: 'absolute top-1/2 left-1/2 text-center font-semibiold -translate-x-1/2 -translate-y-1/2',
  blankslateIcon: 'block mx-auto mb-2',
  chart: 'h-[13.5em] m-h-[13.5em] cursor-pointer',
  sparkline: 'relative h-4 w-12',
  legend: 'overflow-hidden mb-4',
  legendItem: 'float-left leading-4 mr-4 mb-3 text-sm',
  legendColor: 'relative inline-block w-3 h-3 top-px rounded-sm bg-slate-200 mr-2'
  
}

export default Style;