import { PostsDashboard } from 'views/posts/posts_dashboard';
//import { SpaceDashboard } from 'views/spaces/space_dashboard';
//import { ScheduledSpaces } from 'views/spaces/scheduled_spaces';

const Routes = [

  {
    path: '/posts',
    view: PostsDashboard,
    layout: 'appDark',
    //permission: 'user',
    title: 'Social Posts Dashboard'
  }
]

export default Routes;

//http://localhost:3000/space/1mrGmaaeQavGy