/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext} from 'react';
import { Card, Stat, Image, 
   Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, Row, Link } from 'components/lib';

//import { useWeb3React } from "@web3-react/core";
import Axios from 'axios';
import Style from './leaderboard.tailwind.js';
//import { Link } from 'react-router-dom';

export function LeaderboardNFT(props){

  //const context = useContext(ViewContext);
  const navigate = useNavigate();
  const leaderStats = useAPI('/api/reports/nft/leaders');
  //const progress = useAPI('/api/demo/progress');
  //const table = useAPI('/api/demo/users/list');
  //const userChart = useAPI('/api/demo/users/types');
  //const revenueChart = useAPI('/api/demo/revenue');
  

  //const { active, account, chainId } = useWeb3React();
  //const [nftList, setNFTList] = useState(null);
  //const [nftCollections, setNFTCollections] = useState(null);
  //const [nftCount, setNftCount] = useState(0);
  //const [nftImages, setNFTImages] = useState(null);
  //const [defaultNFTImageIndex, setDefaultNFTIndex] = useState(0);
  //const [existingAccount, setAccount] = useState(null);

  useEffect(() => {

    //console.log(leaderStats);

  }, [leaderStats]);


  async function viewItem(item) {
    //console.log(nft);
    //let navPath = `/nft/holder?id=${nft.nft_id}`;
    let navPath = `/nft/view/${item.nft_id}`;
    //console.log(navPath);
    //return <Navigate to={navPath}/>;
    navigate(navPath);
  };

  return (
    <Animate type='pop'>
      <Link btn small url='../leaderboard' text="<-Leaderboards"/>
      <Row title="NFT Leaderboard">
        { leaderStats.data && leaderStats.data.length > 0 && 
          <>
          <Card name='nfts' center smallHeader>
            <Grid cols='6'>
              { leaderStats.data.map((theUser, index) => {
                
                return (
                    theUser.image_url !== null && 
                  <Card shadow rounded
                    
                    key={ theUser.id }
                    className={ Style.leaderCard }
                    title={`#${index + 1}`}
                    center
                    >
                    <div className={ Style.nftname }>
                      {theUser.username !== '[Private]' &&
                        <><Link  url={`/user/${theUser.username}`} text={theUser.username} target="_new" color='dark'/><br/></> }
                      {theUser.username === '[Private]' &&
                        <>{theUser.username }<br/></>}
                      Level: { theUser.level }<br/>
                      XP: { theUser.xp.toLocaleString() }<br/>
                    </div>

                    <Image
                      className={ Style.nftCollectionImage }
                      nftImage = {true}
                      border = {true}
                      interactive
                      source={ theUser.image_url}
                      alt={ theUser.name }
                      action={ e => {viewItem(theUser)}}
                    />

                    <div className={ Style.nftname }>
                      <Link text={ theUser.collection_name } url={`/community/${theUser.slug}`} /><br/>
                    </div>
                      
        
                  </Card>
                )
                })
              }
            </Grid>
          </Card>
          </>
        }
      </Row>

      <Feedback />

    </Animate>
  );
}
