/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback} from 'react';
import { useLocation } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
//import truncateEthAddress from 'truncate-eth-address';
import { Card, Stat, ProgressBar, Chart,  Button, Image, Row, Link, Form, Search,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, AuthContext, WalletConnect } from 'components/lib';

import { ethers, utils, BigNumber } from 'ethers';

//https://eth-mainnet.g.alchemy.com/v2/RR7i6KryXLb4CRj-wkmv4Tyr_lr-9jj0
  
//import Axios from 'axios';
//import Style from './project.tailwind.js';

//let lastSearch = '';

export function ProjectView(props){

  const viewContext = useContext(ViewContext);
  const context = useContext(AuthContext);
  const location = useLocation();
  const [tokenPrice, setTokenPrice] = useState(null);
  const [contractABI, setContractABI] = useState(null);
  const [contractAddress, setContractAddress] = useState(null);
  
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [waitMint, setWaitMint] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");

  const { active, account, chainId } = useWeb3React();
  
  let pathSplit = location.pathname.split("/");
  const project_id = pathSplit[pathSplit.length-1];
  const projectInfo = useAPI('/api/project/' + project_id);

  function truncateToken(tokenString){
    let retString = tokenString;
    if(tokenString.length > 16){
      let strLen = tokenString.length;
      let string1 = tokenString.substring(0,12);
      let string2 = tokenString.slice(-4);
      retString = string1 + "..." + string2;
    }
    return retString;
  }

  useEffect(() => {

    if(projectInfo && projectInfo.data && projectInfo.data.project.contract_abi){
      console.log(projectInfo);
      let theABI = JSON.parse(projectInfo.data.project.contract_abi);
      let theContractAddress = projectInfo.data.project.contract_address;
      setTokenPrice(projectInfo.data.project.mint_cost);
      setContractABI(theABI);
      setContractAddress(theContractAddress);
    }

  }, [projectInfo]);

  const mintNFTHandler = async () => {
    try {
      const {ethereum} = window;
      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, contractABI, signer);

        //console.log("Initialize Payment");

        //{utils.formatEther(this.props.tokenPrice.mul(this.state.mintAmount))}

        let mintAmount = 1;
        const tokenPrice1 = utils.parseEther(tokenPrice);
        //console.log("tokenPrice1");
        //console.log(tokenPrice1);
        let totalMintCost = tokenPrice1.mul(mintAmount);
        //console.log(totalMintCost);

        const transactionOptions = {
          //gasLimit: 6000000,
          //gasPrice: ethers.utils.parseUnits('5.0', 'gwei'),
          //value: ethers.utils.parseEther('0.02')
          value : totalMintCost
        }

        //let mintPrice = { value: ethers.utils.parseEther("0.01")};
        //console.log("MintPrice:" + mintPrice);
        //console.log(transactionOptions);

        
        //amount, {value: this.state.tokenPrice.mul(mintAmount)}
        try {
          setWaitMint(true);
          let nftTxn = await nftContract.mint(mintAmount, transactionOptions);
          //console.log("Minting... please wait");
          //console.log(nftTxn);
          
          await nftTxn.wait();
          let txHash = nftTxn.hash;

          
          return {
            success: true,
            status:
              "✅ Check out your transaction on Etherscan: https://etherscan.io/tx/" + txHash,
          };
        } catch (error) {
          return {
            success: false,
            status: "😥 Something went wrong: " + error.message,
          };
        }
      } else {
        console.log("Ethereum obj does not exist");
      }

    } catch (err) {
      console.log(err);
    }
  }

  async function loadContract() {
    //return new web3.eth.Contract(contractABI, contractAddress);
  }

  function chainName(){
    let theChain = '';
    let theChainName = 'ETH Wallet';
    switch(chainId){
      case 1: theChain = 'eth'; theChainName = 'Ethereum Mainnet'; break;
      case 3: theChain = 'eth'; theChainName = 'Ropsten Test'; break;
      case 4: theChain = 'eth'; theChainName = 'Rinkeby Test'; break;
      case 5: theChain = 'eth'; theChainName = 'Goerli Test'; break;
      case 42: theChain = 'eth'; theChainName = 'Kovan Test'; break;
      case 56: theChain = 'bnb'; theChainName = 'Binance Smart Chain'; break;
      case 137: theChain = 'matic'; theChainName = 'Polygon Mainnet'; break;
      case 25: theChain = 'cro'; theChainName = 'Cronos Mainnet'; break;
      case 100: theChain = 'xdai'; theChainName = 'Gnosis'; break;
      case 43114: theChain = 'avax'; theChainName = 'Avalanche C'; break;
      case 1666600000: theChain = 'harmony'; theChainName = 'Harmony Mainnet'; break;
      default: theChain = 'eth';
    }
    //console.log(theChain);
    return theChainName;
  }

  const onMintPressed = async () => {
    const { success, status } = await mintNFTHandler();
    setStatus(status);
    setWaitMint(false);
    if (success) {
      console.log("SUCCESS");
    }
  };

  return (
    <Animate type='pop'>
      {!projectInfo.loading && projectInfo.data && projectInfo.data.project &&
        <Card>
          <strong>{projectInfo.data.project.project_name}</strong><br/>
          <strong>Mint Price: </strong>{tokenPrice}ETH<br/>
          <span>{projectInfo.data.project.token_description}</span><br/>
        </Card>
      }
       <div key='ethconnect' className={'flodat-left'}>
        <span>{chainName()}</span><br/>
        <WalletConnect button={true}/><br/>
        <Button loading={waitMint} key="space" color={"green"} text='Mint 1' action={ e =>  {onMintPressed() }}/>
        <span>{status}</span>
      </div>

      <Feedback />

    </Animate>
  );
}
