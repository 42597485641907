/***
*
*   SPACES NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React from 'react';
import { SubNav } from 'components/lib';

export function SpacesNav(props){

  return(
    <SubNav dark center items={[

      { link: '/spaces', label: 'Live'},
      { link: '/spaces_scheduled', label: 'Scheduled'},

    ]}/>
  );
}