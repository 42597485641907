/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback} from 'react';
import { Card, Stat, Image, 
   Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, Row, Link, Loader, Form, Table } from 'components/lib';

//import { useWeb3React } from "@web3-react/core";
import Axios from 'axios';
import Style from './leaderboard.tailwind.js';
//import { Link } from 'react-router-dom';

export function Leaderboards(props){

  //const context = useContext(ViewContext);
  const navigate = useNavigate();
  //const leaderStats = useAPI('/api/reports/nft/leaders_metaverse');
  //const progress = useAPI('/api/demo/progress');
  //const table = useAPI('/api/demo/users/list');
  //const userChart = useAPI('/api/demo/users/types');
  //const revenueChart = useAPI('/api/demo/revenue');
  

  //const { active, account, chainId } = useWeb3React();
  //const [nftList, setNFTList] = useState(null);
  //const [nftCollections, setNFTCollections] = useState(null);
  //const [nftCount, setNftCount] = useState(0);
  //const [nftImages, setNFTImages] = useState(null);
  //const [defaultNFTImageIndex, setDefaultNFTIndex] = useState(0);
  //const [existingAccount, setAccount] = useState(null);
  const [leaderboards, setLeaderboards] = useState(null);

  useEffect(() => {

    //console.log(leaderStats);

  }, [leaderboards]);


  async function viewItem(item) {
    //console.log(nft);
    //let navPath = `/nft/holder?id=${nft.nft_id}`;
    let navPath = `/nft/view/${item.nft_id}`;
    //console.log(navPath);
    //return <Navigate to={navPath}/>;
    navigate(navPath);
  };

  const getLeaderBoards = useCallback(async (filter, minEventDate) => {
            
    //let minDate = "2022-06-05";
    //let nftFilter=[];
    let filterString = filter ? "?filter=" + filter : "?";
    let mindateString = minEventDate ? "&minDate=" + minEventDate : "";
    const res = await Axios.get('/api/reports/leaderboards');
    console.log(res.data);
    setLeaderboards(res.data);
    
    
  }, []);

  useEffect(() => {

    getLeaderBoards("level", "2022-04-01");

  }, [getLeaderBoards]);

  async function doOptionChange(optionValues){
    console.log(optionValues);

    //let inputName = optionValues.input;
    //let optionValue = optionValues.value;
    //let optionValid = optionValues.valid;
    
    
    getLeaderBoards(optionValues.view_filter, optionValues.minDate);
    

    
  }

   return (
    <Animate type='pop'>
      <Link btn small url='../leaderboard' text="<-Leaderboards"/>
      {/*
      <Card center restrictWidth>
        <Form 
          center
          smallButton
          buttonText='Filter'
          data={{
            view_filter: {
              type: 'radio',
              options: ['Level', 'Social', 'Gaming'],
              required: false,
              errorMessage: 'Please enter a message',       
              default: 'Level',
              label: '',
            },
            minDate: {
              required: true,
              label: 'Start Date (GMT) Ex: 2022-04-01 01:20:00',
              default: '2022-04-01 01:00:00',
              min: '2022-05-01 01:20:00',
              type: 'date',
            }
          }}
          updateOnChange = {false}
          callback={ res => {
            //console.log(res);
            doOptionChange(res);
          }}
          //onSubmit={ e => {doOptionChange(e)}}
        />
      </Card>
        */}
      
      <Row title="Leaderboards">
        { leaderboards && leaderboards.data && leaderboards.data.length > 0 && 
          <>
          <Card name='leaders' center smallHeader >
            <Grid cols='2'>
              { leaderboards.data.map((leaderboard, index) => {
                
                return (
                  
                  <Card shadow rounded
                    
                    key={ leaderboard.id }
                    className={ Style.leaderCard }
                    title={leaderboard.lb_display_name}
                    center
                    >
                  <Table
                    headerStyle={'text-left outline-0 capitalize p-0 border-b border-dotted border-slate-900 first:pl-0 last:pr-0'}
                    data={ leaderboard.report_data }
                    loading={ leaderboard.loading }
                    >
                  </Table>
                  </Card>
                )
                })
              }
            </Grid>
          </Card>
          </>
        }
      </Row>

      <Feedback />

    </Animate>
  );
}
