/***
*
*   POSTS NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React from 'react';
import { SubNav } from 'components/lib';

export function PostsNav(props){

  return(
    <SubNav dark items={[

      { link: '/posts', label: 'Twitter'},
    ]}/>
  );
}