/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks  from 'remark-breaks'

import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import CommunityLevel from 'components/boost/community_level';

import { useWeb3React } from "@web3-react/core";
//import truncateEthAddress from 'truncate-eth-address';
import { Card, Stat, ProgressBar, Chart,  Button, Image, Row, Link, Form, Search, CommunityViewNav,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, AuthContext, WalletConnect } from 'components/lib';

import { ethers, utils, BigNumber } from 'ethers';

//https://eth-mainnet.g.alchemy.com/v2/RR7i6KryXLb4CRj-wkmv4Tyr_lr-9jj0
  
//import Axios from 'axios';
import Style from './community.tailwind.js';

//let lastSearch = '';

export function CommunityViewGames(props){

  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const location = useLocation();
  //const [tokenPrice, setTokenPrice] = useState(null);
  //const [contractABI, setContractABI] = useState(null);
  //const [contractAddress, setContractAddress] = useState(null);
  
  //const [walletAddress, setWallet] = useState("");
  //const [status, setStatus] = useState("");
  //const [waitMint, setWaitMint] = useState(false);

  //const [name, setName] = useState("");
  //const [description, setDescription] = useState("");
  //const [url, setURL] = useState("");

  //const { active, account, chainId } = useWeb3React();
  const [applicationsInfo, setApplicationsInfo] = useState(null);
  const [community, setCommunity] = useState(null);
  const [communityError, setCommunityError] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  
  let pathSplit = location.pathname.split("/");
  const community_slug = pathSplit[pathSplit.length-1];
  const user = useAPI('/api/user', authContext);
  const communityInfo = useAPI('/api/community/' + community_slug);
  const applications = useAPI('/api/applications');

  const boostRef = useRef(null);

  function truncateToken(tokenString){
    let retString = tokenString;
    if(tokenString.length > 16){
      let strLen = tokenString.length;
      let string1 = tokenString.substring(0,12);
      let string2 = tokenString.slice(-4);
      retString = string1 + "..." + string2;
    }
    return retString;
  }

  
  useEffect(() => {

    //console.log(communityInfo);
    if(communityInfo.loading){
      //console.log("LOADING NO DATA YET");
    } else {
      if(!communityInfo.success){
        //console.log("NOT LOADING, FAIL");
        setCommunityError(true);
      }
    }
    if(communityInfo && communityInfo.data) {
      console.log(communityInfo);
      viewContext.setTitle(communityInfo.data.community.community_name + " [Games]");
      setCommunity(communityInfo.data.community)
    }

    if(applications && applications.data){
      //console.log(applications);
      let tmpApplications = applications.data;
      tmpApplications.forEach((application, index) => {
        //console.log(application);
        //https://theplayarcade.com/PLAY/coinoids
        if(application.application_url){
          application.application_url = application.application_url.replace('/PLAY/', '/' + community_slug + '/');
        }
        

      });

      setApplicationsInfo(tmpApplications)
    }

  }, [communityInfo, applications]);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);

  async function loadContract() {
    //return new web3.eth.Contract(contractABI, contractAddress);
  }

  async function viewApp(item) {
    console.log(item);
    var win = window.open(item.application_url, '_blank');
    //let navPath = `/nft/holder?id=${nft.nft_id}`;
    //let navPath = `/nft/holder/${nft.nft_id}`;
    //console.log(navPath);
    //return <Navigate to={navPath}/>;
    //navigate(navPath);
    //console.log(account);
    /*
    let contract_address = nft.asset_contract.address;
    let token_id = nft.token_id;
    let nft_pic = nft.image_url;

    const res = await Axios.post('/api/nft/track', { 
      contract_address: contract_address,
      token_id: token_id,
      nft_pic: nft_pic,
      wallet_address: account
    });
    console.log(res.data);
    */
  }

  
  return (
    <Animate type='pop'>
      <CommunityViewNav dark user={user} community_info={communityInfo.data} center={!advanced} loading={communityInfo.loading} loadFail={communityInfo.loading ? false : communityError} />
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      {!communityInfo.loading && communityInfo.data && communityInfo.data.project &&
        <Card>
          <strong>{communityInfo.data.project.project_name}</strong><br/>
        </Card>
      }
      {community && 
        <CommunityLevel community={community} ref={boostRef}/>
      }

      {community && community.level.level <3 &&
        <Card center>
          <strong>Communities of Level 3+ have their own Arcades! Boost this Community to unlock that ability.</strong><br/>
        </Card>
      }

      {community && community.level.level >=3 &&
        <Grid cols={advanced ? "6" : "4"}>
        {
          applicationsInfo?.filter(x=>x.application_type === "Gaming").map((application, index) => {
            //console.log(application);
            //application.application_url = application.application_url.replace("/PLAY/", communityInfo.slug);
            return (
              <Card
                key={application.id}
                shadow rounded smallHeader
                title={application.application_name}
                restrictWidth
                className={ Style.applicationCardCenter }
                data-tooltip-id="my-tooltip" 
                data-tooltip-content={application.description} 
                data-tooltip-place="top"
                interactive
                action={(e) => {
                  viewApp(application);
                }}
              >
                {/*<div className={ Style.nftStatName }>
                {application.application_type} - {application.status }
                </div>*/}
                <Image
                  className={ Style.applicationImage }
                  nftImage = {true}
                  border = {true}
                  source={ application.logo_square_url}
                  alt={ application.application_name }
                  interactive 
                  action={(e) => {
                    viewApp(application);
                  }}
                />
                {/*<br/>Superpass Support<br/>
                <small className={ Style.applicationSuperpass }>
                  {application.superpass}
                </small>
                {application.status === "Live" && application.application_url != null && 
                  <Link url={application.application_url} text={"Visit App"} className={ Style.link } target="_new"/>
                }*/}
              </Card>
            )
          })
        }
        </Grid>
      }
      <Tooltip id="my-tooltip" />
      <Feedback />
      </div>
    </Animate>
  );
}
