const Style = {
    collection: 'p0 text-xs text-normal-tight',
    collectionTitle: 'p0 text-s text-normal-tight font-semibold leading-4',
    userName: 'py-1 p2 text-s font-semibold text-blue-500',
    
    userStatTitleLarge: 'p0 text-s font-semibold',
    userStatLarge: 'p0 text-s',

    userStatTitle: 'p0 text-xs font-semibold',
    userStat: 'p0 text-xs',
    
    nftname: 'p0 text-xs font-semibold',
    subtext: 'p0 text-xs font-semibold bg-orange-500 text-white',
    nft: 'mx-auto text-center min-h-400 shadow-lg',
    nftNoButton: 'mx-auto text-center min-h-360 shadow-lg',
    nftLocked: 'mx-auto text-center min-h-345 shadow-lg',
    nftholder: 'mx-auto text-center min-h-390 shadow-lg',
    nftCollectionCard: 'mx-auto text-center min-h-275 shadow-lg',
    nftStats: 'mx-auto min-h-390 shadow-lg',
    nftButton: 'px-5 py-1!important mx-auto text-center mt-1',
    nftImage: 'cursor-pointer max-h-80 sm:max-h-52 mx-auto',
    nftImageSmall: 'cursor-pointer max-h-80 sm:max-h-40 mx-auto',
    nftCollectionImage: 'max-h-32 mx-auto',
    nftStatCardCenter: 'text-center shadow-lg min-h-358',
    nftStatCenter: 'text-center text-xs',
    nftStatName: 'p0 font-semibold',
    leaderCard: 'mx-auto min-h-400 shadow-lg border py-8',
    leaderCardSmall: 'mx-auto text-center min-h-275 shadow-lg border',
    applicationImage: 'max-h-40 mx-auto',
    
  }
  
  export default Style;