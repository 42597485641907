import { CommunitiesDashboard } from 'views/communities/dashboard';
import { CommunityView } from 'views/communities/view';
import { CommunityViewMembers } from 'views/communities/members';
import { CommunityViewNews } from 'views/communities/news';
import { CommunityViewGames } from 'views/communities/games';
import { CommunityViewMetrics } from 'views/communities/metrics';
import { CommunityViewCollectibles } from 'views/communities/collectibles';
import { CommunityAdmin } from 'views/communities/admin';
import { NFTCollectionView } from 'views/nft/nft_collection_view';
import { CollectionGameDashboard } from 'views/nft/game_dashboard';
import { NewCommunity } from 'views/communities/new';
import { CommunityList } from 'views/communities/community_list';


const Routes = [

  {
    path: '/communities',
    view: CommunityList, //CommunitiesDashboard,
    layout: 'appDark',
    //permission: 'user',
    title: 'Communities Dashboard'
  },
  {
    path: '/collections',
    view: CommunitiesDashboard,
    layout: 'app',
    permission: 'user',
    title: 'Communities Dashboard'
  },
  {
    path: '/communities/new',
    view: NewCommunity,
    layout: 'appDark',
    //permission: 'user',
    title: 'New Community'
  },
  {
    path: '/c/new',
    view: NewCommunity,
    layout: 'appDark',
    //permission: 'user',
    title: 'New Community'
  },
  {
    path: '/community/game/:collectionid',
    view: CollectionGameDashboard,
    layout: 'home',
    //permission: 'user',
    title: 'Gaming Dashboard'
  },
  {
    path: '/c/game/:collectionid',
    view: CollectionGameDashboard,
    layout: 'home',
    //permission: 'user',
    title: 'Gaming Dashboard'
  },
  {
    path: '/community/:community_slug',
    view: NFTCollectionView,
    layout: 'home',
    //permission: 'user',
    title: 'Community View'
  },
  {
    path: '/collection/:community_slug',
    view: NFTCollectionView,
    layout: 'home',
    //permission: 'user',
    title: 'Community View'
  },
  {
    path: '/c',
    view: CommunityList,
    layout: 'appDark',
    //permission: 'user',
    title: 'Communities'
  },{
    path: '/c/members/:community_slug',
    view: CommunityViewMembers,
    layout: 'appDark',
    //permission: 'user',
    title: 'Community Members'
  },{
    path: '/c/metrics/:community_slug',
    view: CommunityViewMetrics,
    layout: 'appDark',
    //permission: 'user',
    title: 'Community Metrics'
  },{
    path: '/c/collectibles/:community_slug',
    view: CommunityViewCollectibles,
    layout: 'appDark',
    //permission: 'user',
    title: 'Community Collectibles'
  }
  ,{
    path: '/c/admin/:community_slug',
    view: CommunityAdmin,
    layout: 'appDark',
    //permission: 'user',
    title: 'Community Admin'
  },
  /*{
    path: '/c/games/:community_slug',
    //view: CollectionGameDashboard,
    layout: 'appDark',
    //permission: 'user',
    title: 'Community Games'
  },*/{
    path: '/c/news/:community_slug',
    view: CommunityViewNews,
    layout: 'appDark',
    //permission: 'user',
    title: 'News & Updates'
  }
  ,{
    path: '/c/games/:community_slug',
    view: CommunityViewGames,
    layout: 'appDark',
    //permission: 'user',
    title: 'Games'
  },
  {
    path: '/c/:community_slug',
    view: CommunityView,
    layout: 'appDark',
    //permission: 'user',
    title: 'Community'
  },
  
]

export default Routes;
