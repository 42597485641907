/***
*
*   CommunityBoost
*   Content for the Community Boost Modal
*
**********/

import React, {useContext, Fragment, useEffect, useState} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useAPI, AuthContext, ViewContext, Link, Button, Image, Grid } from 'components/lib';
import Axios from 'axios';
export function CommunityBoost(props){
  //console.log(props);

  
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const [userNFTs, setUserNFTs] = useState(null);
  const [selectedMultipass, setSelectedMultipass] = useState(null);
  
  const userMultipasses = useAPI('/api/user/multipass', authContext, "POST");

  useEffect(() => {
    
    //console.log(userMultipasses);
    if(userMultipasses.data){
      //console.log(userMultipasses.data);
      //userMultipasses.data.nfts[0].boost = "Crypto Cave Club";
      setUserNFTs(userMultipasses.data.nfts);
      if(userMultipasses.data.nfts.length > 0){
        setChosenMultipass(0);
      }
      
      //setUserNFTs(userMultipasses.data.nfts);
    }
  }, [userMultipasses]);

  async function onChange(index, item) {
    //console.log("onChange");
    //console.log(index, item);
    setChosenMultipass(index);
    if(props.onChange){
      props.onChange(index, item);
    }
  }

  async function onClickItem(index, item) {
    //console.log("onClickItem");
    //console.log(index, item);
    setChosenMultipass(index);
    if(props.onClickItem){
      props.onClickItem(index, item);
    }
  }

  async function onClickThumb(index, item) {
    //console.log("onClickThumb");
    //console.log(index, item);
    setChosenMultipass(index);
    if(props.onClickThumb){
      props.onClickThumb(index, item);
    }
  }

  async function setChosenMultipass(index){
    //console.log("setChosenMultipass");
    //console.log("Selected Index: " + index);
    let chosenNFT = userMultipasses.data.nfts[index];
    setSelectedMultipass(chosenNFT);
    if(props.onSelected){
      props.onSelected(index, chosenNFT);
    }
    
    //console.log(chosenNFT);
  }

  async function cancelBoost(){
    console.log("cancelBoost");
    viewContext.modal.hide();
    
  }

  async function boostCommunity(){
    console.log("boostCommunity");
    //console.log(resData);
    //console.log(selectedMultipass);
    
    const res = await Axios.post('/api/community/boost', { 
      multipass_id: selectedMultipass.id,
      community_id: props.community.id
    });

    //console.log(res.data);
    if(res.data.error){
      viewContext.notification.show(res.data.message, 'error', true);
      
    } else {
      viewContext.notification.show(res.data.message, 'success', true);

      let tmpCommunity = res.data.data.community;
      if(props.onBoosted){
        props.onBoosted(tmpCommunity);
      }

      //const mpres = await Axios.post('/api/user/multipass');
      //console.log(mpres.data);
      
      /*setUserNFTs(mpres.data.data.nfts);
      if(mpres.data.data.nfts > 0){
        setChosenMultipass(0);
      }*/

      //setCommunity(tmpCommunity); ///api/community/' + community_slug
      viewContext.modal.hide();
    }
  }

  

  return(
    <Fragment>
      <div className='center'>
        
        {!userNFTs && 
          <div >
            <p>Boost with a MultiPass! 1 boost per MultiPass.</p>
            <strong>No Multipasses Found... </strong>
            <Image
              className='h-80'
              loading="lazy"
              source='https://nftscribe.s3.amazonaws.com/projects/c63abb0e-36ef-44bd-836a-9d950d4e524c/preview.gif'
              alt='MultiPass Preview'
              interactive
              target='_blank'
              url='/shop/multipass'
            />

            
            <br/>
            <Link url='/shop/multipass' target='_blank' text='Mint a MultiPass' color='cyan' className='center' />
            <Button
              text={'Cancel'}
            
              color={ 'red' }
              action={ () => cancelBoost() }
              //className='mb-2'
            />
          </div>
        }

        {userNFTs && 
        <div className='w-11/12'>
          <p>Select a MultiPass to Boost this Community. If it's being used for another Community, it will be reassigned to this one.</p>
          <div>
            <Carousel
              centerMode={true}
              className='m-auto text-center' 
              width={"90%"}
              onClickItem={onClickItem}
              onClickThumb={onClickThumb}
              onChange={onChange}
              centerSlidePercentage={80}
              thumbWidth={60}
              selectedItem={0}
            >
              {userNFTs && userNFTs.map((nft, index) => {
                  return (
                    <div key={nft.id}>
                    <img src={`${nft.image_url}`} alt={`${nft.image_url}`}/>
                      <p className="legend">{`${nft.name}`}</p>
                    </div>
                  )
                })
              }
            </Carousel>
            <div className='text-center -mt-5'>
              {selectedMultipass && 
                <>
                  <strong className='text-white'>{selectedMultipass.name}</strong>
                  <br/>
                </>
              }
              {selectedMultipass && selectedMultipass.boost && 
                <>
                  <strong className='text-yellow-300'>Boosting: </strong>{selectedMultipass.boost.community_name}
                <br/>
                </>
              }
            </div>
            <Link url='/shop/multipass' target='_blank' text='Mint a MultiPass' color='cyan' className='center' /><br/>
            {selectedMultipass && <>
            
                <strong className='center text-center'>Boost this Community?</strong>
                <Grid cols="2" className='max-w-xs m-auto'>
                  <Button
                    text={'Boost'}
                  
                    color={ 'green' }
                    action={ () => boostCommunity() }
                    //className='mb-2'
                  />
                  <Button
                    text={'Cancel'}
                  
                    color={ 'red' }
                    action={ () => cancelBoost() }
                    //className='mb-2'
                  />
                </Grid>
                
              </>
            }
          </div>
        </div>
        }    
      <br/>
      </div>
    </Fragment>
  );
}