/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useState, useEffect, useCallback, useContext } from "react";
//import useWindowSize from 'react-use/lib/useWindowSize';

import { useLocation } from "react-router-dom";
import {
  Table,
  NFTHolderNav,
  useNavigate,
  Message,
  Feedback,
  useAPI,
  ViewContext,
  AuthContext,
  DashboardNav,
  Card
} from "components/lib";

import Style from './shop.tailwind.js';
//import { useWeb3React } from "@web3-react/core";
import Axios from "axios";

//Solana stuff
//import { useConnection, useWallet } from "@solana/wallet-adapter-react";

export function CreditHistory(props) {
  const navigate = useNavigate();
  const location = useLocation();

  //const onlyWidth = useWindowWidth()
  //const onlyHeight = useWindowHeight()
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const user = useAPI("/api/user", authContext);

  // fetch
  const creditData = useAPI("/api/shop/history/credits");
  //const [nftData, setNFTData] = useState(null);
  //const [unAssignedXP, setUnAssignedXP] = useState(0);
  //const { active, account, chainId } = useWeb3React();
  //const [existingAccount, setAccount] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [creditTransactions, setCreditTransaction] = useState(null);
  const [advanced, setAdvanced] = useState(false);

  // We get the public key of the connected Solana wallet, if there is one
  //const { connection } = useConnection();
  //console.log("Solana wallet: " + publicKey);
  useEffect(() => {
    if (creditData?.loading) {
    } else {
      //console.log(creditData.data);
      if (creditData.data) {
        setCreditTransaction(creditData.data);
      }
    }
  }, [creditData]);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);  

  return (
    <Fragment>
      {user && user.data && !props.hideMenu && 
      <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>
      }
    
    <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      {!creditData.loading &&
        creditTransactions &&
        creditTransactions.length === 0 && (
          <Message
            closable
            messageID="Message_CreditEvents"
            color="transparent"
            title="Credits"
            text={"Your Credit Transaction History is Shown Below"}
          />
        )}

      {!creditData.loading &&
        creditTransactions &&
        creditTransactions.length > 0 && (
          <Card dark center>
            <div className='pb-4 text-xl font-semibold text-cyan-400'>Metaverse Credit Transactions</div>
            <Table
              dark
              data={creditData.data}
              show={[
                "action",
                "result",
                "note",
                "transaction_amount",
                "tx_type",
                "date_created",
              ]}
              loading={false}
              badge={{ col: "plan", color: "blue" }}
            ></Table>
          </Card>
        )}
      
      <Feedback />
      </div>
    </Fragment>
  );
}
