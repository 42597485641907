/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback, Fragment} from 'react';
import { useLocation } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
//import truncateEthAddress from 'truncate-eth-address';
import { Card, Stat, ProgressBar, Chart,  Button, Image, Row, Link, Form, Search, CommunityViewNav, Table, 
  Message, Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, AuthContext, WalletConnect } from 'components/lib';

import { ethers, utils, BigNumber } from 'ethers';

//https://eth-mainnet.g.alchemy.com/v2/RR7i6KryXLb4CRj-wkmv4Tyr_lr-9jj0
  
//import Axios from 'axios';
//import Style from './project.tailwind.js';

//let lastSearch = '';

export function CommunityViewMembers(props){

  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const location = useLocation();
  //const [tokenPrice, setTokenPrice] = useState(null);
  //const [contractABI, setContractABI] = useState(null);
  //const [contractAddress, setContractAddress] = useState(null);
  
  //const [walletAddress, setWallet] = useState("");
  //const [status, setStatus] = useState("");
  //const [waitMint, setWaitMint] = useState(false);

  //const [name, setName] = useState("");
  //const [description, setDescription] = useState("");
  //const [url, setURL] = useState("");

  //const { active, account, chainId } = useWeb3React();
  const user = useAPI('/api/user', authContext);
  const [communityError, setCommunityError] = useState(false);
  const [communityMembers, setCommunityMembers] = useState(null);
  const [advanced, setAdvanced] = useState(false);
  
  
  let pathSplit = location.pathname.split("/");
  const community_slug = pathSplit[pathSplit.length-1];
  const communityInfo = useAPI('/api/community/' + community_slug);
  const communityMembersData = useAPI('/api/community/members/' + community_slug);

  function truncateToken(tokenString){
    let retString = tokenString;
    if(tokenString.length > 16){
      let strLen = tokenString.length;
      let string1 = tokenString.substring(0,12);
      let string2 = tokenString.slice(-4);
      retString = string1 + "..." + string2;
    }
    return retString;
  }

  useEffect(() => {

    //console.log(communityInfo);
    if(communityInfo.loading){
      //console.log("LOADING NO DATA YET");
    } else {
      if(!communityInfo.success){
        //console.log("NOT LOADING, FAIL");
        setCommunityError(true);
      }
    }
    if(communityInfo && communityInfo.data) {
      console.log(communityInfo);
      viewContext.setTitle(communityInfo.data.community.community_name + " [Members]");
    }

    

    if(communityMembersData && communityMembersData.data){
      //console.log("Members");
      //console.log(communityMembersData);
      let tmpMembers = communityMembersData.data.members;
      

      let header = [
        { name: 'id', title: 'ID', sort: true },
        //{ name: 'image_url', title: 'Profile', sort: false },
        { name: 'user', title: 'Name', sort: true },
        { name: 'collectibles', title: 'Collected', sort: true },
        { name: 'metaverse_level', title: 'Level', sort: true },
        { name: 'social_level', title: 'Social Level', sort: true },
        { name: 'gaming_level', title: 'Gaming Level', sort: true },
        { name: 'score_social', title: 'Social Score', sort: true },
        { name: 'score_gaming', title: 'Gaming Score', sort: true },
        { name: 'social_spaces_speaking_hours', title: 'Speaking Hours', sort: true },
      ];
      
      let tableBody = [];
      if(tmpMembers){
        tmpMembers.forEach((aMember, index) => {
        //console.log(aMember);
        tableBody.push({
          id: index + 1,
          //image_url: aMember.image_url,
          user: {text: aMember.username, url: `/u/${aMember.username_slug}`, target:'_new'}, // + aCollectible.user_id,
          //username: aMember.nickname ? aMember.username + ` (${aMember.nickname})` : aMember.username,
          collectibles: aMember.collectible_count,
          metaverse_level: aMember.metaverse_level,
          social_level: aMember.social_level,
          gaming_level: aMember.gaming_level,
          score_social: aMember.score_social,
          score_gaming: aMember.score_gaming,
          social_spaces_speaking_hours: aMember.social_spaces_speaking_hours,
          user_id: aMember.id,
          })
        });
      
      }
      let membersTableData = {
        header: header,
        body: tableBody
      }
      setCommunityMembers(membersTableData);
    }

  }, [viewContext, communityInfo, communityMembersData]);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);
  
  async function viewItem(aItem) {
    console.log(aItem);
    var win = window.open(aItem.user.url, '_blank');
 
  };

  
  return (
    <Fragment>
      <CommunityViewNav dark user={user} community_info={communityInfo.data} center={!advanced} loading={communityInfo.loading} loadFail={communityInfo.loading ? false : communityError} />
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      {!communityInfo.loading && communityInfo.data && communityInfo.data.project &&
        <Card>
          <strong>{communityInfo.data.project.project_name}</strong><br/>
        </Card>
      }
      <Card dark>
        <Table
          search
          data={ communityMembers }
          loading={ communityMembersData.loading }
          show={['id', 'user', 'collectibles', 'metaverse_level', 'social_level', 'gaming_level', 'score_social', 'score_gaming', 'social_spaces_speaking_hours']}
          badge={{ col: 'plan', color: 'blue' }}
          image={['image_url']}
          link={['user']}
          actions={{ 
            //view: viewItem
            custom: [{ icon: 'eye', action: viewItem}]    
          }}
        >
        </Table>
      </Card>
      </div>
      <Feedback />

    </Fragment>
  );
}
