const Style = {

  base: 'underline',
  defaultColor: 'text-blue-500',
  white: 'text-white',
  dark: 'text-slate-500',
  cyan: 'text-cyan-400'

}

export default Style;