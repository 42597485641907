/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import { AuthContext, ViewContext, Form, Card,
  AccountNav, Button, useNavigate, Animate, useAPI, Event, Message } from 'components/lib';

export function MarketingProfile(props){

  const navigate = useNavigate();
  
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch
  const user = useAPI('/api/user');
  const offerTypes = useAPI('/api/offers/types');
  const [enabled, setEnabled] = useState(undefined);
  const [populatedOfferTypes, setPopulatedOfferTypes] = useState([]);
  

  //const interests = ['Digital Collectibles', 'Web3', 'Gaming', 'Web3 Apps', 'Web3 Gaming', 'Defi'];

  useEffect(() => {

    setEnabled(user.data?.['offers_enabled']);

  }, [user.data])

  useEffect(() => {

    if(user.data && offerTypes.data){
      
      let populatedOfferTypesTemp = [];

      offerTypes.data.forEach((offer_type, index) => {
        populatedOfferTypesTemp.push(offer_type.offer_type);
      });

      setPopulatedOfferTypes(populatedOfferTypesTemp);
    }
    

  }, [user.data, offerTypes.data])

return (
  <Fragment>
    <Message
          messageID="Message_Scribe_Offers"
          title="Get offers that interest you!"
          text="As a Scribe user, we can send you offers from partners that match your profile, interests, and activity. When someone pays for you to receive an offer, you will earn some $PLAY! Multipass holders will receive a greater amount of $PLAY per offer."
          //buttonText="My Collectibles"
          //buttonLink="/collectibles"
          type="info"
          noIcon
        />
      <AccountNav />
      <Animate>
      
        <Card
          title='Edit Your Marketing Preferences'
          subtitle='Offers will show up on the site by default, and you can elect to also receive them via email as well (recommended).'
          loading={ user.loading } restrictWidthWide>

          { user?.data && populatedOfferTypes.length > 0 && 
            <Form 
            url='/api/offers/user_offer_types'
            method='put'
            submitOnChange
            data={{
              'offers_enabled': {
                type: 'switch',
                label: 'Enable partner offer emails',
                default: user?.data?.['offers_enabled']
              },
              offer_type: {
                type: 'checkbox',
                label: 'Interests',
                required: true,
                min: 1,
                default: user.data.offer_types_enabled ? user.data.offer_types_enabled.split(",") : [],
                options: populatedOfferTypes,
                errorMessage: 'Please select at least one interest' 
              },
            }}
            callback={ res => {
              
              //setQrCode(res.data.data.qr_code);
    
              if (!res.data.data['offers_enabled'])
                setEnabled(false);
            
            }}
          />
          }

          <Fragment>
            <br/>
            {/*<Button
              textOnly
              action={ closeAccount }
              text='Close Your Account'
            />*/}
          </Fragment>
        </Card>
      </Animate>
    </Fragment>
  );
}
