import { Developer } from 'views/developer';
import { APIKeyList } from 'views/developer/apikey/list';
import { APIKeyEditor } from 'views/developer/apikey/edit';
import { ApplicationList } from 'views/developer/application/list';
import { ApplicationEditor } from 'views/developer/application/edit';

const Routes = [
  {
    path: '/developer',
    view: Developer,
    layout: 'app',
    permission: 'user',
    title: 'Developer Home'
  },
  /*{
    path: '/developer/applications',
    view: ApplicationList,
    layout: 'app',
    permission: 'developer', 
    title: 'Applications'
  },
  {
    path: '/developer/applications/create',
    view: ApplicationEditor,
    layout: 'app',
    permission: 'developer', 
    title: 'Application'
  },
  {
    path: '/developer/applications/edit',
    view: ApplicationEditor,
    layout: 'app',
    permission: 'developer',
    title: 'Application'
  },
  */
  {
    path: '/developer/apikeys',
    view: APIKeyList,
    layout: 'app',
    permission: 'developer', 
    title: 'API Keys'
  },
  {
    path: '/developer/apikeys/create',
    view: APIKeyEditor,
    layout: 'app',
    permission: 'developer', 
    title: 'API Keys'
  },
  {
    path: '/developer/apikeys/edit',
    view: APIKeyEditor,
    layout: 'app',
    permission: 'developer',
    title: 'API Keys'
  },
]

export default Routes;