/***
*
*   MESSAGE
*   Colored feedback message with optional call to action
*
*   PROPS
*   type - info/success/warning/error
*   title - descriptive string
*   text - string
*   closable - boolean to determine if the user can close the message
*   buttonText - text for the cta button (optional)
*   buttonLink - url link for the button (optional)
*
**********/

import React, { useState, useEffect } from 'react';
import { Button, Icon, useNavigate, ClassHelper } from 'components/lib';
import Style from './message.tailwind.js';

export function Message(props){

  const navigate = useNavigate();

  // state
  const [closed, setClosed] = useState(false);
  const type = props.type || 'info';
  const noIcon = props.noIcon || false;

  useEffect(() => {
    if(props.messageID){
      let closedStatus = localStorage.getItem(props.messageID);
      //console.log("Close Status for props.messageID " + closedStatus);
      if(closedStatus){
        setClosed(closedStatus);
      }
    }
  },[props.messageID]);

  async function setClosedStatus(isClosed) {
    setClosed(isClosed);
    if(props.messageID){
      localStorage.setItem(props.messageID, isClosed);
    }
    
  };

  if (closed)
    return false;

  const icon = {

    info: 'info',
    success: 'check',
    warning: 'alert-triangle',
    error: 'alert-octagon',
    award: 'award'

  };

  const color = {

    info: 'blue',
    success: 'green',
    warning: 'orange',
    error: 'red',
    award: 'green'

  }

  // style
  const messageStyle = ClassHelper(Style, { 
    
    message: true,
    [`${color[type]}Bg`]: true,
    className: props.className, 
  
  });

  const titleStyle = ClassHelper(Style, {

    title: true,
    [`${color[type]}Text`]: true 
    
  });

  return (
    <div className= { messageStyle }>
      { !props.noIcon &&
        <Icon
        className={ Style.icon }
        size={ 30 }
        color={ color[type] }
        image={ icon[type] }
      />
      }
      

      { props.closable &&
        <Button
          icon='x'
          position='absolute'
          className={ Style.close }
          action={ e => setClosedStatus(true) }
        />
      }

      <section className={ Style.content }>

        { props.title && 
          <h1 className={ titleStyle }>
            { props.title }
          </h1> }
        
        { props.text && 
          <p>{ props.text }</p> }
        { props.text2 && 
          <p>{ props.text2 }</p> }

        { props.children &&
          props.children }

        { props.buttonLink &&
          <Button
            className={ Style.btn }
            color={ color[type] }
            text={ props.buttonText }
            action={ e => 
              props.newTab ? 
              window.open(props.buttonLink,"_blank","noopener noreferrer")
              :  
              navigate(props.buttonLink) 
            
            }
          />
        }

     </section>
    </div>
  );
}