/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback} from 'react';
import { Card, Stat, Image, 
   Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, Row, Link, Loader, Form } from 'components/lib';

//import { useWeb3React } from "@web3-react/core";
import Axios from 'axios';
import Style from './leaderboard.tailwind.js';
//import { Link } from 'react-router-dom';

export function LeaderboardMetaverse(props){

  //const context = useContext(ViewContext);
  const navigate = useNavigate();
  //const leaderStats = useAPI('/api/reports/nft/leaders_metaverse');
  //const progress = useAPI('/api/demo/progress');
  //const table = useAPI('/api/demo/users/list');
  //const userChart = useAPI('/api/demo/users/types');
  //const revenueChart = useAPI('/api/demo/revenue');
  

  //const { active, account, chainId } = useWeb3React();
  //const [nftList, setNFTList] = useState(null);
  //const [nftCollections, setNFTCollections] = useState(null);
  //const [nftCount, setNftCount] = useState(0);
  //const [nftImages, setNFTImages] = useState(null);
  //const [defaultNFTImageIndex, setDefaultNFTIndex] = useState(0);
  //const [existingAccount, setAccount] = useState(null);
  const [leaderStats, setLeaderStats] = useState(null);

  useEffect(() => {

    //console.log(leaderStats);

  }, [leaderStats]);


  async function viewItem(item) {
    //console.log(nft);
    //let navPath = `/nft/holder?id=${nft.nft_id}`;
    let navPath = `/nft/view/${item.nft_id}`;
    //console.log(navPath);
    //return <Navigate to={navPath}/>;
    navigate(navPath);
  };

  const getLeaders = useCallback(async (filter, minEventDate) => {
            
    //let minDate = "2022-06-05";
    //let nftFilter=[];
    let filterString = filter ? "?filter=" + filter : "?";
    let mindateString = minEventDate ? "&minDate=" + minEventDate : "";
    const res = await Axios.get('/api/reports/nft/leaders_metaverse/' + filterString + mindateString);
    //console.log(res.data);
    setLeaderStats(res.data);
    
    
  }, []);

  useEffect(() => {

    getLeaders("level", "2022-04-01");

  }, [getLeaders]);

  async function doOptionChange(optionValues){
    console.log(optionValues);

    //let inputName = optionValues.input;
    //let optionValue = optionValues.value;
    //let optionValid = optionValues.valid;
    
    
    getLeaders(optionValues.view_filter, optionValues.minDate);
    

    
  }

   return (
    <Animate type='pop'>
      <Link btn small url='../leaderboard' text="<-Leaderboards"/>
      <Card center restrictWidth>
        <Form 
          center
          smallButton
          buttonText='Filter'
          data={{
            view_filter: {
              type: 'radio',
              options: ['Level', 'Social', 'Gaming'],
              required: false,
              errorMessage: 'Please enter a message',       
              default: 'Level',
              label: '',
            },
            minDate: {
              required: true,
              label: 'Start Date (GMT) Ex: 2022-04-01 01:20:00',
              default: '2022-04-01 01:00:00',
              min: '2022-05-01 01:20:00',
              type: 'date',
            }
          }}
          updateOnChange = {false}
          callback={ res => {
            //console.log(res);
            doOptionChange(res);
          }}
          //onSubmit={ e => {doOptionChange(e)}}
        />
      </Card>
      
      <Row title="Metaverse Leaderboard">
        { leaderStats && leaderStats.data && leaderStats.data.length > 0 && 
          <>
          <Card name='leaders' center smallHeader >
            <Grid cols='5'>
              { leaderStats.data.map((theUser, index) => {
                
                return (
                    theUser.user_name !== null && 
                  <Card shadow rounded
                    
                    key={ theUser.user_id }
                    className={ Style.leaderCardSmall }
                    title={`#${index + 1}`}
                    center
                    sideBadges = {theUser.buckets}
                    >
                    <div >
                      <span className={Style.userName}>
                        {theUser.user_name !== '[Private]' &&
                          <><Link  url={`/user/${theUser.user_name}`} text={theUser.user_name} target="_new" /><br/></> }
                        {theUser.user_name === '[Private]' &&
                          <>{theUser.user_name }<br/></>}
                      </span>
                      <span className={Style.userStatTitleLarge}>Level: </span><span className={Style.userStatLarge}>{ theUser.level.level }</span><br/>
                      <span className={Style.userStatTitle}>Total XP: </span><span className={Style.userStat}>{ theUser.level.xp.toLocaleString() }</span><br/>
                      <span className={Style.userStatTitle}>Next Level: </span><span className={Style.userStat}>{ theUser.level.nextLevelXP.toLocaleString() }</span><br/>
                    </div>

                    {/*<Image
                      className={ Style.nftCollectionImage }
                      nftImage = {true}
                      border = {true}
                      interactive
                      source={ theUser.image_url}
                      alt={ theUser.name }
                      action={ e => {viewItem(theUser)}}
                    />*/}

                    {/*<div className={ Style.nftname }>
                      <Link text={ theUser.collection_name } url={`/community/${theUser.slug}`} /><br/>
                    </div>*/}
                      
        
                  </Card>
                )
                })
              }
            </Grid>
          </Card>
          </>
        }
      </Row>

      <Feedback />

    </Animate>
  );
}
