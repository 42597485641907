/***
*
*   NFT VIEW NAV
*   NFT sub nav that renders different options based
*   on user permissions.
*
**********/

import React from 'react';
import { SubNav } from 'components/lib';

export function NFTViewNav(props){

  return(
    <SubNav center={props.center} dark items={[

      { link: `/nft/view/${props.nft_id}`, label: 'NFT' },
      { link: `/nft/view/events/${props.nft_id}`, label: 'Events' },
      //{ link: '/account/2fa', label : '2FA' },
      //{ link: '/account/billing', label: 'Billing', permission: 'owner' },
      //{ link: '/account/apikeys/', label: 'API Keys', permission: 'developer' },
      //{ link: '/account/users', label: 'Users', permission: 'admin' }

    ]}/>
  );
}