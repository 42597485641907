/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext} from 'react';

import { Card, ViewContext, AuthContext, Stat, ProgressBar, Chart,  Image, Button, Row, Link, SocialShare, SignIn,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, PostsNav, Table } from 'components/lib';

import { useWeb3React } from "@web3-react/core";

import Axios from 'axios';
import Style from './posts.tailwind.js';

export function PostsDashboard(props){

  const user = useAPI('/api/user',true);
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [signalBoost, setSignalBoost] = useState(null);
  const [postList, setPostList] = useState(null);
  const [postStats, setPostStats] = useState(null);

  localStorage.setItem('social_posts', true);
  
  function truncateString(theString, truncLength=50){
    let retString = theString;
    if(theString.length > truncLength){
      let strLen = theString.length;
      let string1 = theString.substring(0,truncLength);
      retString = string1 + "...";
    }
    return retString;
  }

  useEffect(() => { 
    async function getPostList() {
      if(user){
        //console.log(user.data);
        //const resSite = await Axios.get('/api/site/posts/twitter');
        const res = await Axios.get('/api/posts/twitter');
        const res2 = await Axios.get('/api/post_stats/twitter');
        //console.log(res.data.data);
        //console.log(res2.data.data);

        let postStatsData = res2.data.data.platformPostStats;
        setPostStats(postStatsData);

        let postListData = res.data.data.platformPosts;
        
        let thePostList = [];
        let totalBoost = 0;
        postListData.forEach((post,index) => {
          
          let hasActed = false;
          let postActions = post.actions;
          
          if(user.data) {
            let myTwitterID = user.data.twitter_id;
            let myPostActions = postActions.filter(x=>x.twitter_id === myTwitterID);
            if(myPostActions && myPostActions.length > 0){
              hasActed = true;
            }
          }

          let postAuthorDisplayName = post.author_name;
          if(post.author_name !== post.platform_author_username){
            //postAuthorDisplayName = post.author_name + " (" + truncateString(post.platform_author_username,20) + ")";
            postAuthorDisplayName = post.author_name;
          }

          //let postSignalLikes = post.total_likes > 0 ? post.tracked_likes / post.total_likes : 0;
          //let postSignalRetweets = post.total_retweeets > 0 ? post.tracked_retweets / post.total_retweeets : 0;
          //let postSignalQuoteRetweets = post.total_quote_retweets > 0 ? post.tracked_quote_retweets / post.total_quote_retweets : 0;
          
          //let postSignalLikesDisplay = (postSignalLikes > 0 ? (postSignalLikes * 100).toFixed(2) : 0) + "%";
          //let postSignalRetweetsDisplay = (postSignalRetweets > 0 ? (postSignalRetweets * 100).toFixed(2) : 0) + "%";
          //let postSignalQuoteRetweetsDisplay = (postSignalQuoteRetweets > 0 ? (postSignalQuoteRetweets * 100).toFixed(2) : 0) + "%";

          //let postSignal = postSignalLikesDisplay + "/" + postSignalRetweetsDisplay + "/" + postSignalQuoteRetweetsDisplay;
          let postSignal = post.tracked_likes + "/" + post.tracked_retweets + "/" + post.tracked_quote_retweets;

          let thePostStats = post.total_likes + "/" + post.total_retweets + "/" + post.total_quote_retweets;

          //let signalRatioLikes = postSignalLikes > 0 ? 1 : 0;
          //let signalRatioRetweets = postSignalRetweets > 0 ? 1 : 0;
          //let signalRatioQuoteRetweets = postSignalQuoteRetweets > 0 ? 1 : 0;
          //let totalSignalContribution = signalRatioLikes + signalRatioRetweets + signalRatioQuoteRetweets;
          //let signalRatio = (postSignalLikes + postSignalRetweets + postSignalQuoteRetweets) / totalSignalContribution;
          let totalPostStats = post.total_likes + post.total_retweets + post.total_quote_retweets;
          let totalSignalStats = post.tracked_likes + post.tracked_retweets + post.tracked_quote_retweets;
          let signalBoost = totalPostStats > 0 ? parseInt(((totalSignalStats) / (totalPostStats)) * 100): 0;
          totalBoost = totalBoost + signalBoost;
          let signalRatio = signalBoost > 0 ? signalBoost + "%" : "--";

          let postData = {
            number: index + 1,
            xp_modifier: post.xp_modifier === 1 ? "Normal" : post.xp_modifier + "X Bonus",
            id: post.id,
            platform_id: post.platform_post_id,
            post_platform_author_id: post.platform_author_id,
            post_platform_author_username: post.platform_author_username,
            author_displayname: truncateString(postAuthorDisplayName,25),
            post_stats: thePostStats,
            signal: postSignal,
            signal_ratio: signalRatio,
            post_author: post.author_name,
            post_text: truncateString(post.post_text, 100),
            acted: user.data ? hasActed : 'n/a',
            post_date: post.post_time
          }
          thePostList.push(postData);
        });

        let postTableData = {
          header: [
            { name: 'number', title: '#', sort: true},
            { name: 'xp_modifier', title: 'XP', sort: true},
            { name: 'author_displayname', title: 'Author', sort: true},
            { name: 'post_stats', title: 'Like/Retweet/Quote', sort: true},
            //{ name: 'signal', title: 'Signal-LRQ', sort: true},
            { name: 'signal_ratio', title: 'Boost', sort: true},
            { name: 'post_text', title: 'Post', sort: true},
            { name: 'acted', title: 'Acted', sort: true},
            //{ name: 'post_date', title: 'Date', sort: true},
            
          ],
          body: thePostList
        }

        //console.log(postTableData);
        //console.log(totalBoost);
        //console.log(postListData.length);
        totalBoost = (totalBoost / postListData.length).toFixed(2) + "%";
        setSignalBoost(totalBoost);
        setPostList(postTableData);
      }
    }

    getPostList();

  },[user]);


  async function viewPost(post) {
    //console.log(post);
    //let navPath = `/post/${post.post_id}`;
    //console.log(navPath);
    //navigate(navPath);
    return window.open(`https://twitter.com/${post.post_platform_author_username}/status/${post.platform_id}`,'_blank');
   
  };


  /*if(!authContext.user){
    return (
      <>
        <SignIn title="Signin Required" text="You must signin to use this feature" />
        
      </>
    );
  }
  */

  return (
    <Animate type='pop'>
      <PostsNav/>

      <Grid cols='5'>
        <Stat
          value={ postList ? postList.body.length: '--'}
          label={ 'Tracked Posts'}
          icon=''
        />
        <Stat
          value={ signalBoost ? signalBoost: '--'}
          label={ 'Signal Impact'}
          icon=''
        />
        <Stat
          label={ 'Likes (Last 7d)' }
          value={ postStats? postStats.likes  : '--'}
          icon=''
        />
        <Stat
          label={ 'Reweets (Last 7d)' }
          value={ postStats? postStats.retweets  : '--'}
          icon=''
        />
        <Stat
          label={ 'Quote Retweets (Last 7d)' }
          value={ postStats? postStats.quote_retweets  : '--'}
          icon=''
        />
      </Grid>
  
  



      { postList && postList.body.length === 0 && 
        <Message
          text="Scribe is not currently tracking any Twitter posts."
          type='info'
        />
      }

      { postList && postList.body.length > 0 && 
        <Card>
          <div>
            <strong>These are all the posts from partners being tracked that are eligible for XP. You can also see if you've acted on the post and if it has an XP Boost.</strong><br/>
            <span>XP Rewards for actions are: Likes = 200XP.&nbsp;&nbsp;&nbsp;Retweets=300XP.&nbsp;&nbsp;&nbsp;Quote Retweets=400XP.</span>
          </div>
          <Table
            search
            className='restrict-width'
            data={ postList }
            loading={ postList.loading }
            show={['number', 'xp_modifier', 'author_displayname', 'post_stats', 'signal_ratio', 'post_text', 'acted']}
            badge={{ col: 'xp_modifier', color: 'blue', condition: [

              { value: 'NORMAL', color: 'orange' },
              { value: '2', color: 'blue' }

            ]}}
            actions={{ 
              
              custom: [
                { text:'View', action: viewPost },
              ]
            }}

          />
        </Card>
      }

      
      <Feedback />

    </Animate>
  );
}
