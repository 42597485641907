/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext} from 'react';
import { Card, Stat, Image, Search,
   Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, Row, Link, Button } from 'components/lib';

//import { useWeb3React } from "@web3-react/core";
import Axios from 'axios';
import Style from './nft.tailwind.js';
//import { Link } from 'react-router-dom';
import { ethers, utils, BigNumber } from 'ethers';

let lastSearch = '';

export function NFTCollectionList(props){

  const viewContext = useContext(ViewContext);
  //const context = useContext(ViewContext);
  const navigate = useNavigate();
  const stats = useAPI('/api/demo/stats');
  //const progress = useAPI('/api/demo/progress');
  //const table = useAPI('/api/demo/users/list');
  //const userChart = useAPI('/api/demo/users/types');
  //const revenueChart = useAPI('/api/demo/revenue');
  

  //const { active, account, chainId } = useWeb3React();
  //const [nftList, setNFTList] = useState(null);
  const [nftBaseCollections, setNFTBaseCollections] = useState(null);
  const [nftCollections, setNFTCollections] = useState(null);
  const [nftCount, setNftCount] = useState(0);
  const [search, setSearch] = useState('');
  //const [nftImages, setNFTImages] = useState(null);
  //const [defaultNFTImageIndex, setDefaultNFTIndex] = useState(0);
  //const [existingAccount, setAccount] = useState(null);

  const {ethereum} = window;
  const provider = ethereum ? new ethers.providers.Web3Provider(ethereum) : null;


  useEffect(() => {
    if(nftBaseCollections){
      //console.log("Show Locked " + showLocked);
      if(search !== '' || (lastSearch.toLowerCase() !== search.toLowerCase())){
        //console.log(search);
        if(lastSearch.toLowerCase() !== search.toLowerCase()){
          let list = nftBaseCollections;
          //console.log(list);
          let thisSearch = search.toLowerCase();
          let shortList = list.filter(function (thing) { 
            //console.log(creator);
            return (
              (thing.name && thing.name.toLowerCase().indexOf(thisSearch) !== -1) ||
              (thing.description && thing.description.toLowerCase().indexOf(thisSearch) !== -1) ||
              (thing.slug && thing.slug.toLowerCase().indexOf(thisSearch) !== -1) ||
              (thing.owner_twitter && thing.owner_twitter.toLowerCase().indexOf(thisSearch) !== -1)
            )
          });
          
          lastSearch = thisSearch;
          setNFTCollections(shortList)
        }
        
      } 
    }
    
  }, [search, nftBaseCollections]);

  async function getNFTCollections(){
          
    //let nftFilter=[];
    //api.get('/api/nft/collections', use(nftController.getCollections));
    const res = await Axios.get('/api/nft/collections');
    console.log(res.data);
    //setNFTList(res.data.data.nfts);
    let collectionResults = res.data.data.collections;
    let collections = [];

    let nft_count = 0;
    await Promise.all(collectionResults.map(async (collection, index) => {
      if(!collections.includes(collection.address)){
        collection.id = index;
        nft_count = nft_count + collection.nft_count;

        if(collection.abi != null && collection.partner===1 && provider){
          let theABI = JSON.parse(collection.abi);
          const nftContract = new ethers.Contract(collection.address, theABI, provider);
          collection.ethersContract = nftContract;

          collection.paused = false;
          try {
            let isPaused = await nftContract.paused();// * 1e18;
            collection.isPaused = isPaused; 
          } catch (error) {
            
          }

          //console.log(collection.name);
          try {
            collection.totalSupply = "?";
            collection.maxSupply = "?"
            
            try {
              let nftCost = await nftContract.cost();// * 1e18;
              let nftCostDecimal = nftCost / 1e18;
              if(nftCostDecimal < 0.00001){
                nftCostDecimal = 0;
              }
              //collection.ethersContract = nftContract;
              
              collection.nftCost = nftCost;
              collection.nftCostDecimal = nftCostDecimal;  
            } catch (error) {
              try {
                let nftCost = await nftContract.MAINSALE_PRICE();// * 1e18;
                let nftCostDecimal = nftCost / 1e18;
                if(nftCostDecimal < 0.00001){
                  nftCostDecimal = 0;
                }
                //collection.ethersContract = nftContract;
                
                collection.nftCost = nftCost;
                collection.nftCostDecimal = nftCostDecimal;
              } catch (error) {
                
                try {
                  let nftCost = await nftContract.price();// * 1e18;
                  let nftCostDecimal = nftCost / 1e18;
                  if(nftCostDecimal < 0.00001){
                    nftCostDecimal = 0;
                  }
                  //collection.ethersContract = nftContract;
                  
                  collection.nftCost = nftCost;
                  collection.nftCostDecimal = nftCostDecimal;  
                } catch (error) {
                  try {
                    let nftCost = await nftContract.PRICE_PER_TOKEN();// * 1e18;
                    let nftCostDecimal = nftCost / 1e18;
                    if(nftCostDecimal < 0.00001){
                      nftCostDecimal = 0;
                    }
                    //collection.ethersContract = nftContract;
                    
                    collection.nftCost = nftCost;
                    collection.nftCostDecimal = nftCostDecimal;  
                  } catch (error) {
                    
                  }
                  
                }
                
              }
            }
            
            //let nftNumMinted = await nftContract.totalSupply();// * 1e18;
            try {
              let maxMint = await nftContract.maxSupply();// * 1e18;
              collection.maxSupply = maxMint ; 
            } catch (error) {
              collection.maxSupply = "?"
              try {
                let maxMint = await nftContract.MAX_SUPPLY();// * 1e18;
                collection.maxSupply = maxMint ; 
              } catch (error) {
                collection.maxSupply = "?"
                try {
                  let maxMint = await nftContract.maxTokens();// * 1e18;
                  collection.maxSupply = maxMint ; 
                } catch (error) {
                  
                }
              }
            }

            try {
              let totalMintSupply = await nftContract.totalSupply();// * 1e18;
              collection.totalSupply = totalMintSupply ; 
            } catch (error) {
              collection.totalSupply = "?"
            }
            //console.log(collection.name);
            //console.log(collection.address);

            if(collection.maxSupply === collection.totalSupply && (collection.totalSupply !== "?")){
              collection.nftCost = null;
              collection.nftCostDecimal = null;
            } else {
              //console.log(collection.name);
              //console.log("Minted: " + collection.totalSupply + " / " + collection.maxSupply);
              collection.mintedOut = false;
              if(collection.maxSupply - collection.totalSupply === 0){
                //console.log("Minted out");
                collection.mintedOut = true;
              }
              
              if(collection.maxSupply === collection.totalSupply){
                //console.log("Minted out");
                //collection.mintedOut = true;
              }

              if(collection.maxSupply === "?"){
                collection.nftCost = null;
              collection.nftCostDecimal = null;
              }
            }
            
            //collection.totalMinted = nftNumMinted.toString();
            //collection.totalSupply = nftTotalSupply.toString();

          } catch (error) {
            //console.log(error);
            //console.log(collection.name);
            
          }
          

        }

        collections.push(collection);
        //const signer = provider.getSigner();
        //const nftContract = new ethers.Contract(theContractAddress, theABI, provider);
        //let nftCost = await nftContract.cost();// * 1e18;
        //let nftCostDecimal = nftCost / 1e18;
      }
    }));
    
    /*collectionResults.forEach((collection, index) => {
      //console.log(collection);
      if(!collections.includes(collection.address)){
        nft_count = nft_count + collection.nft_count;
        
        collections.push(collection);
      }
    });
    */

    collections.sort(
      function(a, b) {          
        //console.log(a.buckets[0]);
        //console.log(b.buckets[0]);
        return a.name < b.name ? -1 : 1;
        
      }
    );

    setNftCount(nft_count);
    setNFTBaseCollections(collections);
    setNFTCollections(collections);
    //console.log(collections);
    
  }

  useEffect(() => {
    
    getNFTCollections();
  
  
    },[]);

    const mintNFTHandler = async (collectionID) => {
      try {
        const {ethereum} = window;
        if(ethereum){
          //console.log(chainName() + "  " + tokenChain);
  
          /*
          if(chainName() !== tokenChain ){
            return viewContext.modal.show({
              title: chainName() === '' ? 'Connect your ETH Wallet' : 'Incorrect Blockchain',
              buttonText: 'OK',
              destructive: false,
              text: "This NFT only mints on the " + tokenChain.toUpperCase() + " blockchain. Please switch your wallet chain(network).",
          
            }, (res) => {
              
            });
  
          }*/
  
          //const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          let nftCollection = nftCollections.filter(x=>x.id === collectionID)[0];
          let nftContract = new ethers.Contract(nftCollection.address, nftCollection.abi, signer);
          //nftContract = nftContract[0];
          //let mintAmount = 1;
          //let nftCost = await nftContract.cost();// * 1e18;
          let nftCost = nftCollection.nftCost;
          let nftCostDecimal = nftCost / 1e18;

          const tokenPrice1 = nftCost; //utils.parseEther(nftContract.nftCostDecimal.toString());
          console.log("tokenPrice1");
          console.log(tokenPrice1);
          let totalMintCost = tokenPrice1.mul(1);
          console.log("totalMintCost");
          console.log(totalMintCost);
  
          const gasPrice = await provider.getGasPrice();
          console.log("gasPrice");
          console.log(gasPrice);
          console.log(gasPrice.toString());
               //Estimate gas limit
          //const gasLimit = await nftContract.estimateGas["mint(uint256)"](mintAmount, { gasPrice });
          //console.log(gasLimit);
          //console.log(gasLimit.toString());
  
          const transactionOptions = {
            //gasLimit: 6000000,
            gasLimit: 100000,
            gasPrice: gasPrice, //ethers.utils.parseUnits('5.0', 'gwei'),
            //value: ethers.utils.parseEther('0.02')
            value : totalMintCost
          }
  
          //let mintPrice = { value: ethers.utils.parseEther("0.01")};
          //console.log("MintPrice:" + mintPrice);
          //console.log(transactionOptions);
  
          
          //amount, {value: this.state.tokenPrice.mul(mintAmount)}
          try {
            //setWaitMint(true);
            //setStatus("Minting... please wait");
            let nftTxn = await nftContract.mint(1, transactionOptions);
            viewContext.notification.show("Mint In Progress, check MetaMask for results", 'success', true);
            //console.log("Minting... please wait");
            //console.log(nftTxn);
            
            await nftTxn.wait();
            console.log(nftTxn);
            let txHash = nftTxn.hash;
            viewContext.notification.show("Mint Complete", 'success', true);
            //let txHash = '0x7e4fb93ee06dc2fd5e31aaa9e9ad120c69da3adac42f9635d480cb5989070e82'; //test
            
  
            /*const resTokens = await Axios.post('/api/shop/getTokensFromTransaction', { 
              txHash: txHash,
              reveal: true,
              project_id: projectInfo.data.project.id
              }
            );*/
            //let theUserLevels = resLevels.data.data;
            /*console.log(resTokens.data.data);
            if(resTokens.data.message){
              viewContext.notification.show(resTokens.data.message, 'success', true);
            }*/
  
            /*const receipt = await provider.getTransactionReceipt(txHash);
            console.log(receipt);
            let topic = null;
            let tokens = [];
            if(receipt.logs){
              receipt.logs.forEach(log => {
                let topic = log.topics[3];
                let tokenID = Web3.utils.hexToNumber(topic);
                tokens.push(tokenID);
              });
            }
            console.log(tokens);
            */
            //setLastTxHash(txHash);
            

          } catch (error) {
            console.log(error);
            return {
              success: false,
              status: "😥 Something went wrong: " + error.message,
              tokens: []
            };
          }
        } else {
          console.log("Ethereum obj does not exist");
        }
  
      } catch (err) {
        console.log(err);
      }
    }


  async function viewItem(collection) {
    //console.log(nft);
    //let navPath = `/nft/holder?id=${nft.nft_id}`;
    let navPath = `/community/${collection.slug}`;
    //console.log(navPath);
    //return <Navigate to={navPath}/>;
    navigate(navPath);
    //console.log(account);
    /*
    let contract_address = nft.asset_contract.address;
    let token_id = nft.token_id;
    let nft_pic = nft.image_url;

    const res = await Axios.post('/api/nft/track', { 
      contract_address: contract_address,
      token_id: token_id,
      nft_pic: nft_pic,
      wallet_address: account
    });
    console.log(res.data);
    */
  };

  return (
    <Animate type='pop'>
      
      <Row small>
        <Search throttle={ 500 } callback={ x => setSearch(x) }/><br/>
        { nftCollections && nftCollections.length > 0 && 
          <>
          <Grid cols='2'>
          <Stat
            loading={ stats?.loading }
            value={ nftCollections !== null ? nftCount : "--" }
            label='Collectibles'
            icon='image'
          />
          <Stat
            loading={ stats?.loading }
            value={ nftCollections !== null ? nftCollections.length: "--"  }
            label='Communities'
            icon='package'
          />
          </Grid>
          
          <Card name='nfts' center title="Projects">
            <Grid cols='5'>
              { nftCollections.filter(x=>x.partner === 1).map((nftCollection, index) => {
                
                return (
                  nftCollection.image_url !== null && nftCollection.nft_count > 0 &&
                  <Card shadow rounded
                    
                    id={ nftCollection.id + "_" + index }
                    key={ nftCollection.id + "_" + index }
                    className={ Style.nftCollectionCard }
                    center
                    //badge = {(nftCollection.arcade_url != null && nftCollection.arcade_url !== "") ? true : false}
                    //badgeText = {"Arcade"}
                    //badgeColor = {"orange"}

                    //badge2 = {nftCollection.partner === 1 ? true : false}
                    //badge2Text = {"Partner"}
                    //badge2Color = {"green"}
                    
                    >
                      
                    <div className={ Style.collectionTitle }>
                      <Link text={ nftCollection.name } url={`/community/${nftCollection.slug}`} />
                    </div>

                    <Image
                      className={ Style.nftCollectionImage }
                      nftImage = {true}
                      border = {true}
                      loading = "lazy"
                      interactive
                      source={ nftCollection.image_url}
                      alt={ nftCollection.name }
                      action={ e => {viewItem(nftCollection)}}
                    />

                    <div className={ Style.collectionTitle }>
                      <small>Activated Collectibles: { nftCollection.nft_count }</small><br/>
                      <small>Highest Level: { nftCollection.max_level }</small><br/>
                      { Boolean(nftCollection.ethersContract && nftCollection.nftCostDecimal && nftCollection.maxSupply && nftCollection.totalSupply && nftCollection.mintedOut) && 
                        <>
                          <strong>Sold Out</strong><br/>
                        </>
                      }
                      { Boolean(nftCollection.ethersContract && nftCollection.nftCostDecimal && nftCollection.maxSupply && nftCollection.totalSupply && nftCollection.isPaused) && 
                        <>
                          <strong>Mint Paused</strong><br/>
                        </>
                      }
                      { Boolean(nftCollection.ethersContract && nftCollection.nftCostDecimal && nftCollection.maxSupply && nftCollection.totalSupply && !nftCollection.mintedOut && !nftCollection.isPaused) && 
                        <>
                          <small>{`${nftCollection.totalSupply} / ${nftCollection.maxSupply}`}</small><br/>
                          <small>Mint Price: {nftCollection.nftCostDecimal=== '0' ? 'FREE' : nftCollection.nftCostDecimal + 'ETH'}</small><br/>
                          <Button className={ Style.centerButton } key="manual" tiny color={"green"} text='Mint' action={ e => mintNFTHandler(nftCollection.id)  }/>
                        </>
                      }
                    </div>

                  </Card>
                )
                })
              }
            </Grid>
          </Card> {/*partners*/}

          <Card name='nfts' center title="Non-Partner Projects">
            <Grid cols='6'>
              { nftCollections.filter(x=>x.partner !== 1).map((nftCollection, index) => {
                
                return (
                  nftCollection.image_url !== null && nftCollection.nft_count > 0 &&
                  <Card shadow rounded
                    
                    id={ nftCollection.id + "_" + index }
                    key={ nftCollection.id + "_" + index }
                    className={ Style.nftCollectionCard }
                    center
                    //badge = {(nftCollection.arcade_url != null && nftCollection.arcade_url !== "") ? true : false}
                    //badgeText = {"Arcade"}
                    //badgeColor = {"orange"}

                    //badge2 = {nftCollection.partner === 1 ? true : false}
                    //badge2Text = {"Partner"}
                    //badge2Color = {"green"}
                    
                    >
                      
                    <div className={ Style.collectionTitle }>
                      <Link text={ nftCollection.name } url={`/community/${nftCollection.slug}`} />
                    </div>

                    <Image
                      className={ Style.nftCollectionImage }
                      nftImage = {true}
                      border = {true}
                      loading = "lazy"
                      interactive
                      source={ nftCollection.image_url}
                      alt={ nftCollection.name }
                      action={ e => {viewItem(nftCollection)}}
                    />

                    <div className={ Style.collectionTitle }>
                      <small>Activated Collectibles: { nftCollection.nft_count }</small><br/>
                      <small>Highest Level: { nftCollection.max_level }</small><br/>
                      { Boolean(nftCollection.ethersContract && nftCollection.nftCostDecimal && nftCollection.maxSupply && nftCollection.totalSupply && nftCollection.mintedOut) && 
                        <>
                          <strong>Sold Out</strong><br/>
                        </>
                      }
                      { Boolean(nftCollection.ethersContract && nftCollection.nftCostDecimal && nftCollection.maxSupply && nftCollection.totalSupply && nftCollection.isPaused) && 
                        <>
                          <strong>Mint Paused</strong><br/>
                        </>
                      }
                      { Boolean(nftCollection.ethersContract && nftCollection.nftCostDecimal && nftCollection.maxSupply && nftCollection.totalSupply && !nftCollection.mintedOut && !nftCollection.isPaused) && 
                        <>
                          <small>Mint Price: {nftCollection.nftCostDecimal=== '0' ? 'FREE' : nftCollection.nftCostDecimal + 'ETH'}</small><br/>
                          <Button className={ Style.centerButton } key="manual" tiny color={"blue"} text='Mint' action={ e => mintNFTHandler(nftCollection.id)  }/>
                        </>
                      }
                    </div>

                  </Card>
                )
                })
              }
            </Grid>
          </Card> {/*partners*/}


          </>
        }
      </Row>

      <Feedback />

    </Animate>
  );
}
