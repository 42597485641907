/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback} from 'react';
import { useLocation } from 'react-router-dom';
//import truncateEthAddress from 'truncate-eth-address';
import { Card, Stat, AuthContext, ProgressBar, Chart,  Button, Image, Row, Link, DashboardNav,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, ViewContext } from 'components/lib';

//import { useWeb3React } from "@web3-react/core";
import Axios from 'axios';
import Style from './nft.tailwind.js';

export function NFTUserView(props){

  //const user = useAPI('/api/user');
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const viewContext = useContext(AuthContext);
  
  const user = useAPI('/api/user',authContext);

  //const context = useContext(ViewContext);
  const navigate = useNavigate();
  const stats = useAPI('/api/demo/stats');
  
  
  let pathSplit = location.pathname.split("/");
  //const collection_id = pathSplit[pathSplit.length-1];
  const user_slug = pathSplit[pathSplit.length-1];
  console.log("user_slug: " + user_slug);
  const userMultipassInfo = useAPI('/api/user/multipasses/' + user_slug);

  //const { active, account, chainId } = useWeb3React();
  const [userInfo, setUserInfo] = useState(null);
  const [nftList, setNFTList] = useState(null);
  //const [nftCollection, setNFTCollection] = useState(null);
  const [nftCollections, setNFTCollections] = useState(null);
  const [userLevelInfo, setUserLevelInfo] = useState(null);
  
  const [userSocialActivityInfo, setUserSocialActivityInfo  ] = useState(null);
  const [userCollectionBucketData, setUserCollectionBucketData  ] = useState(null);
  const [userCollectionSummaryData, setUserCollectionSummaryData  ] = useState(null);
  
  const [userSpacesActivityInfo, setUserSpacesActivityInfo  ] = useState(null);
  const [userGamingActivityInfo, setUserGamingActivityInfo] = useState(null);
  
  const [userXPInfo, setUserXPInfo] = useState(null);
  const [currentUserCreditInfo, setCurrentUserCreditInfo] = useState(null);
  const [userMultipasses, setUserMultipasses] = useState(null);
  const [advanced, setAdvanced] = useState(false);
  
  //const [nftImages, setNFTImages] = useState(null);
  //const [defaultNFTImageIndex, setDefaultNFTIndex] = useState(0);
  //const [existingAccount, setAccount] = useState(null);

  /*useEffect(() => { 

    if(user.data) {
      console.log(user.data);
    }

  },[user]);*/

  function truncateString(theString, truncLength = 50) {
    let retString = theString;
    if (theString.length > truncLength) {
      let strLen = theString.length;
      let string1 = theString.substring(0, truncLength);
      retString = string1 + "...";
    }
    return retString;
  }

  useEffect(() => {
    if(userMultipassInfo && userMultipassInfo.data){
      console.log(userMultipassInfo.data);
      if(userMultipassInfo.data.nfts){
        setUserMultipasses(userMultipassInfo.data.nfts);
      }
    }
      
  }, [userMultipassInfo]);
  

  useEffect(() => {
    

    async function getUserInfo() {
      if (user_slug) {
        const res = await Axios.post("/api/user/info_name", {
          user_name: user_slug
        });

        console.log(res.data.data);
        //console.log(res.data);
        setUserInfo(res.data.data);
        if(res.data.data === undefined){
          return;
        }

        let user_id = res.data.data.id;
        let twitter_id = res.data.data.twitter_id
        getUserProfileStats(user_id);
        getUserCommunityStats(user_id);

      }
    }

    async function getUserCommunityStats( user_id){
      const res = await Axios.post("/api/nft/user_profile_list", {
        //chain: "eth",
        //account: account,
        //publisher_id: publisher.id,
        //nft_template_id: nftFilter,
        //chainId: chainId,
        user_id: user_id,
        filter: "unlocked",
        simple: true,
      });
  

  
      
      //let profileNFTs = profileRes.data.data;
      let userNFTList = res.data.data.nfts;
      let nftCollectionData = res.data.data.collections;
      //console.log(thisNFTList);
      setNFTList(userNFTList);
      let collections = [];
      userNFTList.forEach(nft => {
        if(collections.filter(x=>x.name === nft.collection_name).length === 0){
          collections.push(
            {
              name: nft.collection_name,
              image: nft.collection_image,
              slug: nft.collection_slug,
              chain: nft.chain,
              asset_contract: nft.asset_contract,
              count: 1
            }
          )
        }  
      });
      
      
      //console.log(userNFTList);

      const res2 = await Axios.post("/api/user/collection_stats", {
        user_id: user_id
      });
      //console.log("getUserSocialStats");
      //console.log(res2.data);
      setUserSocialActivityInfo(res2.data.data.statsData);

      //Associate collectible count with collection
      let collectionStats = res2.data.data.collectionStats;
      let collectionSummaryStats = res2.data.data.collectionSummaryStats;
      setUserCollectionSummaryData(collectionSummaryStats);
      setUserCollectionBucketData(collectionStats);

      collections.forEach(collection => {
        let thisCollectionSummaryStats = collectionSummaryStats.filter(x=>x.asset_contract === collection.asset_contract);
        if(thisCollectionSummaryStats.length > 0){
          thisCollectionSummaryStats = thisCollectionSummaryStats[0];
        }
        collection.summaryStats = thisCollectionSummaryStats;

        let collectionNFTList = userNFTList.filter(x=>x.asset_contract === collection.asset_contract);
        let collectionCount = collectionNFTList.length;
        collection.assetCount = collectionCount;
        let thisCollectionStats = collectionStats.filter(x=>x.asset_contract === thisCollectionSummaryStats.asset_contract);
        
        let foundCollectionStats = thisCollectionStats.length > 0;
        let totalCollectionLevel = null;
        let gamingLevel = null;
        let socialLevel = null;
        if(foundCollectionStats){
          totalCollectionLevel = thisCollectionSummaryStats.level.level;
          let socialBucket = thisCollectionStats.filter(x=>x.event_bucket === 'Social');
          let gamingBucket = thisCollectionStats.filter(x=>x.event_bucket === 'Gaming');
          if(socialBucket.length > 0){
            socialLevel = socialBucket[0].level.level;
          }
          if(gamingBucket.length > 0){
            gamingLevel = gamingBucket[0].level.level;
          }
        }
        collection.bucketStats = thisCollectionStats;
        collection.totalCollectionLevel = totalCollectionLevel;
        collection.socialLevel = socialLevel;
        collection.gamingLevel = gamingLevel;

      });

      collections.sort(function (a, b) {
        if (a.totalCollectionLevel === b.totalCollectionLevel) {
    
          return b.assetCount - a.assetCount;
        }
        return a.totalCollectionLevel > b.totalCollectionLevel ? -1 : 1;
      });
      //console.log(collections);
      
      //console.log(nftCollectionData);
      setNFTCollections(collections);

    }

    async function getUserProfileStats(user_id) {
      //if (user_slug) {
        const res = await Axios.post("/api/user/profile_stats", {
          user_id: user_id
        });

        //console.log(res.data.data);
        //console.log(res.data);
        setUserLevelInfo(res.data.data.metaverse_level);
        setUserXPInfo(res.data.data);
        

        //const resCredits = await Axios.get(`/api/shop/credits`);
        //console.log(resCredits.data.data);
        //setCurrentUserCreditInfo(resCredits.data.data);
      //}
    }

    


    getUserInfo();
    
  }, []);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);


  function truncateToken(tokenString){
    let retString = tokenString;
    if(tokenString.length > 16){
      let strLen = tokenString.length;
      let string1 = tokenString.substring(0,12);
      let string2 = tokenString.slice(-4);
      retString = string1 + "..." + string2;
    }
    return retString;
  }

  async function viewNFT(nft) {
    
    let navPath = `/nft/view/${nft.nft_id}`;
    
    navigate(navPath);
    
  };

  return (
    <>
      {user && user.data && 
        <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>
      }
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      <Row tinyDark>
        

        {!userInfo && 
        <Row tinyDark>
          <h1>User Profile Not Found</h1>
        </Row>
        }
        {
          userInfo && userInfo.name && <Grid cols='2' className={Style.noBottomMargin}>
            <Card className={Style.userCardName}>
              {(userInfo.twitter_profile || userInfo.discord_profile) && 
                <Grid cols='2'>
                  <div>
                    <Image
                        className={ Style.nftCollectionImageMedium }
                        nftImage = {true}
                        border = {true}
                        source={ userInfo.discord_profile ? userInfo.discord_profile.profile_image_url : userInfo.twitter_profile.profile_image_url.replace('_normal','')}
                        alt={ userInfo.discord_profile ? userInfo.discord_profile.platform_user_name : userInfo.twitter_profile.platform_user_name }
                        //action={ e => {viewNFT(nft)}}
                        />
                  </div>
                  <div>
                    <span className='text-2xl'>{userInfo.name}</span><br/>
                    {userInfo.twitter_profile && 
                      <p className={ Style.userCardPlatformName }><Link target='_new' url={`https://twitter.com/${userInfo.twitter_profile.platform_user_name}`} text={`@ ${userInfo.twitter_profile.platform_user_name}`}/><br/></p>
                    }
                    <br/>
                    {userXPInfo && <p className={ Style.userCardPlatformName }><strong>Last Updated:</strong> {new Date(userXPInfo.date_created).toLocaleString()}</p>}
                    {userXPInfo && <p className={ Style.userCardPlatformName }><strong>Last Activity: </strong>{new Date(userXPInfo.last_event).toLocaleString()}</p>}
                  </div>
                  
                  
                </Grid>
                
              }
              {!userInfo.twitter_profile && 
                userInfo.name.toUpperCase()
              } 
              
              
            </Card>

            {userInfo && <>
            
              <Card className={Style.userCardName}>
                <Stat
                  labelSmall
                  loading={userXPInfo ? false : true}
                  value={userXPInfo ? userXPInfo.metaverse_level : "--"}
                  label={"Metaverse Level"}
                  userCardStat
                  icon=""
                />
                
              
                <Stat
                  labelSmall
                  loading={userXPInfo ? false : true}
                  //label={"Metaverse XP/XP for Next Level"}
                  label={"Metaverse XP"}
                  value={
                    userXPInfo
                      ? userXPInfo.metaverse_xp.toLocaleString() 
                      : "--"
                  }
                  userCardStat
                  icon=""
                />
              </Card>
            
            </>
            }
          
          </Grid>
        }
        </Row>
        
        {userInfo && userInfo.name && userMultipasses && userMultipasses.length === 0 && 
        <Card dark center>
          <div className='text-center'><strong>Full Metaverse Profile Stats and Collectible Stats are available on the profiles of all MultiPass holders!</strong></div>
          <Link url='/shop/multipass' target='_blank' text='Mint a MultiPass' color='cyan' className='center' /><br/>
        </Card>
        }
        {userInfo && userInfo.name && userMultipasses && userMultipasses.length > 0 && 
        <>
        <Row tinyDark>
          <p className={Style.statName}>User XP Levels</p>
          <Grid cols='4' >

          {/* userXPInfo && userXPInfo.buckets && userXPInfo.buckets.map((userXPBucket, index) => {
              if(userXPBucket.level.level > 0){
                return (
                  <Stat
                    id = {`Bucket_${index}`}
                    key = {`Bucket_${index}`}
                    loading={userXPInfo ? false : true}
                    value={userXPBucket.level.level}
                    label={userXPBucket.event_bucket}
                    userCardStat
                    icon=""
                  />
                )
              } else {
                return null;
              }
            })*/}
            {userXPInfo &&
              <>
                <Stat
                  id = {`Bucket_Gaming`}
                  key = {`Bucket_Gaming`}
                  loading={userXPInfo ? false : true}
                  value={userXPInfo.xp_gaming}
                  label="Gaming"
                  userCardStat
                  icon=""
                />
                <Stat
                  id = {`Bucket_Social`}
                  key = {`Bucket_Social`}
                  loading={userXPInfo ? false : true}
                  value={userXPInfo.xp_social}
                  label="Social"
                  userCardStat
                  icon=""
                />
                <Stat
                  id = {`Bucket_Misc`}
                  key = {`Bucket_Misc`}
                  loading={userXPInfo ? false : true}
                  value={userXPInfo.xp_misc}
                  label="Misc"
                  userCardStat
                  icon=""
                />
              </>
            }
          </Grid>
        </Row>
        <Row tinyDark>
          <p className={Style.statName}>Social Stats (Project Engagement Only)</p>
          <Grid cols='6'>
            {/*<Stat
              loading={userSocialActivityInfo ? false : true}
              value={userSocialActivityInfo && userSocialActivityInfo.filter(x=>x.tweet_action === "tweet").length > 0 ? userSocialActivityInfo.filter(x=>x.tweet_action === "tweet")[0].action_count : "N/A"}
              label={"Tweets"}
              className = "valueDark"
              icon=""
          />*/}
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_retweets  > 0 ? userXPInfo.social_retweets : '--' : '--'}
              label={"Retweets"}
              userCardStat
              labelSmall
              icon=""
              
            />
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_quote_retweets  > 0 ? userXPInfo.social_quote_retweets : '--' : '--'}
              label={"Quote Retweets"}
              userCardStat
              labelSmall
              icon=""
            />
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_likes  > 0 ? userXPInfo.social_likes : '--' : '--'}
              label={"Likes"}
              userCardStat
              labelSmall
              icon=""
            />
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_comments > 0 ? userXPInfo.social_comments : '--' : '--'}
              label={"Comments"}
              userCardStat
              labelSmall
              icon=""
            />
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_videos_watched > 0 ? userXPInfo.social_videos_watched : '--' : '--'}
              label={"Video Plays"}
              userCardStat
              labelSmall
              icon=""
            />
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_events_attended > 0 ? userXPInfo.social_events_attended : '--' : '--'}
              label={"Events Attended"}
              userCardStat
              labelSmall
              icon=""
            />
          </Grid>
        </Row>
        <Row tinyDark>
          <p className={Style.statName}>Twitter Spaces Stats</p>
          <Grid cols='6'>
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_spaces_hosted : '--'}
              label={"Spaces Hosted"}
              userCardStat
              labelSmall
              icon=""
            />
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_spaces_cohosted : '--'}
              label={"Spaces Co-Hosted"}
              userCardStat
              labelSmall
              icon=""
            />
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_spaces_speaking : '--'}
              label={"Spaces Speaking"}
              userCardStat
              labelSmall
              icon=""
            />
            {/*<Stat
              loading={userSpacesActivityInfo ? false : true}
              value={userSpacesActivityInfo && userSpacesActivityInfo.spaces_joined > 0 ? parseInt( userSpacesActivityInfo.spaces_joined) : "--"}
              label={"Spaces Attended"}
              icon=""
            />*/}
          
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_spaces_hosted_hours : '--'}
              label={"Hours Hosting"}
              userCardStat
              labelSmall
              icon=""
            />
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_spaces_cohosted_hours : '--'}
              label={"Hours Co-Hosting"}
              userCardStat
              labelSmall
              icon=""
            />
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.social_spaces_speaking_hours : '--'}
              label={"Hours Speaking"}
              userCardStat
              labelSmall
              icon=""
            />
            {/*<Stat
              loading={userSpacesActivityInfo ? false : true}
              value={userSpacesActivityInfo && userSpacesActivityInfo.spaces_joined_hours > 0 ? parseInt( userSpacesActivityInfo.spaces_joined_hours) : "--"}
              label={"Hours Attending"}
              icon=""
        />*/}
          </Grid>
          
        </Row>
        <Row tinyDark>
          <p className={Style.statName}>Gaming Stats</p>
          <Grid cols='5'>
            {/*<Stat
              loading={userLevelInfo ? false : true}
              value={userLevelInfo ? userLevelInfo.level : "--"}
              label={"Gaming Level"}
              className = "valueDark"
              icon=""
            />
            <Stat
              loading={userLevelInfo ? false : true}
              value={userLevelInfo ? userLevelInfo.level : "--"}
              label={"Gaming XP"}
              className = "valueDark"
              icon=""
            />*/}
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.gaming_games_titles_played : '--'}
              label={"Game Titles Played"}
              userCardStat
              labelSmall
              icon=""
            />
            <Stat
              loading={userXPInfo ? false : true}
              value={userXPInfo ? userXPInfo.gaming_games_played : '--'}
              label={"Game Sessions Played"}
              userCardStat
              labelSmall
              icon=""
            />
            
          </Grid>
        </Row>
        <Row tinyDark>
          <p className={Style.statName}>Digital Collectibles</p>
        { (nftList && nftList.length ===0) && 
          <Message
            title='No Collectibles Found.'
            text='No activated Collectibles in this collection.'
            type='info'
          />
        }
        
          
        { nftList && nftList.length > 0 && 
          <>
          
          <Grid cols='3'>
            <Stat
              loading={ stats?.loading }
              value={ nftCollections !== null ? nftCollections.length : "--"  }
              label='Communities'
              icon='package'
              userCardStat
            />
            <Stat
              loading={ stats?.loading }
              value={ nftList !== null ? nftList.length: "--" }
              label='Collectibles'
              icon='image'
              userCardStat
            />
          </Grid>
          <p className={Style.statName}>Communities</p>
          <Card name='collections' center className={ Style.darkBack }>
            
            <Grid cols='6'>
              { nftCollections && nftCollections.map((nftCollection, index) => {
                
                
                return (
                  <Card shadow rounded
                    badge={ nftCollection.chain } 
                    badgeText={ nftCollection.chain }
                    //badgeText={nft.badge}
                    badgeColor={"blue"}
                    key={ "CollectionCard" + index }
                    className={ Style.nftCollectionStatsCard }
                    interactive
                    center
                    >
                      <div className={ Style.collectionTitleDark }>
                      <Link
                        target='_same'
                        className={ Style.link }
                        url={ '/c/' + nftCollection.slug }
                        text={truncateString(nftCollection.name ? nftCollection.name : "Unnamed",28)}
                      />
                        
                      </div>
                      <Image
                        className={ Style.nftCollectionImageSmall }
                        nftImage = {true}
                        border = {true}
                        source={ nftCollection.image}
                        alt={ nftCollection.name }
                        //action={ e => {viewNFT(nft)}}
                      />
                      <div className={ Style.collectionTitleDark }>
                        Collectible Count: {nftCollection.count}{/*nftList.filter(x=>x.asset_contract === nftCollection.address).length*/}
                      </div>
                      {(nftCollection.totalCollectionLevel ||nftCollection.socialLevel || nftCollection.gamingLevel ) && 
                        <div className={ Style.collectionStatsBoxDark }>
                          {nftCollection.totalCollectionLevel && <>Level: {nftCollection.totalCollectionLevel}<br/></>}
                          {nftCollection.socialLevel && <>Social Level: {nftCollection.socialLevel}<br/></>}
                          {nftCollection.gamingLevel && <>Gaming Level: {nftCollection.gamingLevel}</>}
                        </div>
                      }
                      
                      


                    </Card>

                );
              })}
            </Grid>
          </Card>
          {/*<Card name='nfts' center>
            <Grid cols='5'>
              { 1===2 && nftList.map((nft, index) => {
                //console.log(nft);
                let unAssignedXP = (nft.xp_raw - nft.xp).toLocaleString();
                return (
                  nft.image_url !== null &&
                  <Card shadow rounded
                    badge={ nft.status_name } badgeText={ nft.token_name }
                    key={ nft.id }
                    className={ Style.nftNoButton }
                    interactive
                    center
                    >

                    <div className={ Style.nftname }>
                      {nft.owner_name !== 'Private' ? '@' + nft.owner_name : '[Private]'}
                    </div>

                    <div className={ Style.nftname }>
                      #{ truncateToken(nft.name) }
                    </div>

                    <div className={ Style.nftname }>
                      { nft.nickname ? 
                        nft.nickname !== "" ? "Name: " + nft.nickname + " " : <>&nbsp;</>
                        : <>&nbsp;</>
                      }
                      { nft.title !== undefined ? 
                        nft.title !== "" ? "'" + nft.title + "'" : <>&nbsp;</>
                        : <>&nbsp;</>
                      }
                    </div>

                    <Image
                      className={ Style.nftImageSmall }
                      nftImage = {true}
                      border = {true}
                      source={ nft.image_url}
                      alt={ nft.token_name }
                      action={ e => {viewNFT(nft)}}
                    />

                    <ProgressBar 
                      small
                      progress={ nft.unlocked === 1 ? nft.progress + '%': 0 } 
                      label={nft.unlocked === 1 ? `Level: ${nft.xpStats.level}  (${nft.xp.toLocaleString()} of ${nft.xpStats.nextLevelXP.toLocaleString()})` : 'Activate to Level Up'}
                    />


                    <ProgressBar 
                      raw
                      small
                      progress={ 
                        nft.unlocked === 1 ? 
                          unAssignedXP !== '0' ?
                          nft.progress_raw + '%'
                          :
                          0
                        :
                        0
                      } 
                      label={
                        nft.unlocked === 1 ? 
                          unAssignedXP === '0' ?
                          '(No Uninscribed XP)'
                          :
                          //`Uninscribed XP: ${unAssignedXP} Level: ${nft.xpStatsRaw.level} (${nft.xp_raw.toLocaleString()} of ${nft.xpStatsRaw.nextLevelXP.toLocaleString()})`
                          `Uninscribed XP: ${unAssignedXP}   Level: ${nft.xpStatsRaw.level}`
                        : 
                        'Activate to Level Up'
                        }
                      //label={nft.unlocked === 1 ? `Uninscribed XP: ${unAssignedXP} ${unAssignedXP > 0 ? `Level ${nft.xpRawStats.level}` : ''}` : 'Activate to Level Up'}
                    />
        
                  </Card>
                )
                })
              }
            </Grid>
          </Card>
            */}
          </>
        }

      </Row>
      
      </>
      
    }

      <Feedback />
      </div>
      </>

    
  );
}
