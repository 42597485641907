const Style = {

  label: 'mb-1 text-xs',
  labelSmall: 'mb-1 text-xs',
  labelCenter: 'text-center',
  labelStrong: 'font-bold text-yellow-300',
  bar: 'w-full h-2 mb-2 bg-slate-100 rounded-sm overflow-hidden outline-slate-500 outline outline-1',
  fill: 'h-full ease-in-out duration-500 bg-emerald-500',
  fillRaw: 'h-full mb-2 ease-in-out duration-1000 bg-orange-500',
  metaFill: 'h-full bg-gradient-to-r from-violet-500 to-fuchsia-500'

}

export default Style;