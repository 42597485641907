/***
*
*   SPACES NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React from 'react';
import { SubNav } from 'components/lib';

export function CommunityNav(props){

  return(
    <SubNav items={[

      { link: '/c/' + props.collection_id, label: 'Main'},
      { link: '/c/game/' + props.collection_id, label: 'Games'},

    ]}/>
  );
}