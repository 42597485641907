/***
*
*   ACCOUNT
*   Index page for account functions
*
**********/

import React, { useContext } from 'react';
import { AuthContext, Animate, Grid, Card, Icon, Link, useAPI, Loader } from 'components/lib';

export function Developer(props){

  const context = useContext(AuthContext);
  const user = useAPI('/api/user');
  const iconSize = 20;

  if (user.loading)
    return <Loader />

  return (
    <Animate>
      <Grid cols='4'>

        

        { context.permission?.developer &&
          <Card>

            <Icon image='box' size={ iconSize }/>
            <h2 className={ Style.subtitle }>Applications</h2>
            <div>Manage your Applications</div>
            <Link url='/developer/applications' className={ Style.link }>
              Manage
            </Link>

          </Card>
        }

        { context.permission?.developer &&
          <Card>

            <Icon image='settings' size={ iconSize }/>
            <h2 className={ Style.subtitle }>API Keys</h2>
            <div>Manage your API keys</div>
            <Link url='/developer/apikeys' className={ Style.link }>
              Manage
            </Link>

          </Card>
        }

       
      </Grid>
    </Animate>
  )
}

const Style = {

  subtitle: 'font-bold mt-4',
  link: 'text-blue-500 font-semibold mt-3 inline-block'

}