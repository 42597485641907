import { ShopDashboard } from "views/shop/shop_dashboard";
import { ShopItemView } from "views/shop/shop";
import { CreditHistory } from "views/shop/credits";
import { TokenStore } from "views/shop/token_store";

const Routes = [
  {
    path: "/shop",
    view: ShopDashboard,
    layout: "appDark",
    //permission: 'user',
    title: "Scribe Shop",
  },
  {
    path: "/shop/token/:token_id",
    view: TokenStore,
    layout: "appDark",
    //permission: 'user',
    title: "Scribe Store",
  },
  {
    path: "/shop/history",
    view: CreditHistory,
    layout: "appDark",
    //permission: "user",
    title: "User Credits History",
  },
  {
    path: "/shop/:shop_id",
    view: ShopItemView,
    layout: "appDark",
    //permission: 'user',
    title: "Project Mint Page",
  },
];

export default Routes;
