/***
*
*   PROGRESS BAR
*   Percentage based progress bar with animated fill
*
*   PROPS
*   label: text label (optional)
*   progress: percentage value: eg. 75%
*
**********/

import React from 'react';
import { ClassHelper } from 'components/lib';
import Style from './bar.tailwind.js';

export function ProgressBar(props){

  const barStyle = ClassHelper(Style, { 

    bar: true,
    className: props.className,
    metaFill: props.meta,

  });

  const labelStyle = ClassHelper(Style, { 

    label: true,
    labelSmall: props.small,
    labelCenter: props.labelCenter,
    labelStrong: props.labelStrong,

  });

  return (
    <section>

      { props.label &&
        <div className={ labelStyle }>{ props.label }
        </div>
      }

      <div className={ barStyle }>
        <div className={ props.raw ? Style.fillRaw : Style.fill } style={{ width: props.progress }}></div>
      </div>

    </section>
  );
}