/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import React, { useEffect, useState, useContext, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ConsoleView, isMobile } from "react-device-detect";
import {
  Card,  ViewContext,  AuthContext,  Stat,  ProgressBar,  Chart,  Image,  Button,  Row,  Link,  SocialShare,  Message,  Grid,  Animate,
  Feedback,  useAPI,  useNavigate,  CollectionNav,  SignIn} from "components/lib";

//import { useWeb3React } from "@web3-react/core";
import { Unity, useUnityContext } from "react-unity-webgl";
import Axios from "axios";
import Style from "./game_dashboard.tailwind.js";

//import io from "socket.io-client";
//import Messages from "../spaces/messages";
//import MessageInput from "../spaces/messageInput";
import "../spaces/chat.css";

//const delay = 30; //seconds
//const doChat = true;
//let spaceTimer;

export function GameDashboard(props) {
  const location = useLocation();
  let pathSplit = location.pathname.split("/");
  const space_id = pathSplit[pathSplit.length - 1];

  localStorage.setItem("space_id", space_id);

  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const user = useAPI("/api/user", authContext);
  //const showTestButton = useAPI("/api/var/show_game_test");
  
  //const navigate = useNavigate();
  const [spaceInfo, setSpaceInfo] = useState(null);
//  const [userSocialLevelInfo, setUserSocialLevelInfo] = useState(null);
//  const [userRole, setUserRole] = useState(null);
//  const [socket, setSocket] = useState(null);
  const [doGame, setDoGame] = useState(null);
  const [showNav, setShowNav] = useState(true);
  //const [showSpace, setShowSpace] = useState(true);
  const [userFlags, setUserFlags] = useState([]);
  const [numTeams, setNumTeams] = useState(0);
  const [nftCollection, setNFTCollection] = useState(null);
  const [numTeamsChanged, setNumTeamsChanged] = useState(false);
  //const [isUnityLoaded, setUnityLoaded] = useState(false);
  const [teams, setTeams] = useState([]);
  const [gameSession, setGameSession] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  let dev = urlParams.get("dev");

  const {
    unityProvider,
    sendMessage,
    isLoaded,
    loadingProgression,
    addEventListener,
    removeEventListener,
    requestFullscreen,
  } = useUnityContext(
    isMobile
      ? {
          loaderUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.loader.js",
          dataUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.data",
          frameworkUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.framework.js",
          codeUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/mobile/BuildMobile.wasm",
        }
      : dev
      ? {
          loaderUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.loader.js",
          dataUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.data",
          frameworkUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.framework.js",
          codeUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.wasm",
        }
      : {
          loaderUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.loader.js",
          dataUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.data",
          frameworkUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.framework.js",
          codeUrl:            "https://nftscribe.s3.amazonaws.com/games/spaces-br/Build.wasm",
        }
  );

  const loadingPercentage = Math.round(loadingProgression * 100);
 

  //const { navigator } = useContext(NavigationContext);

 
  const getGames = useCallback(
    async (filter, minEventDate) => {
      let gameServer = window.location.hostname === "localhost" ? "" : "https://game.nftscribe.io";

      const getGameStateRes = Axios.post(gameServer + "/api/game/get/", {
        space_id: space_id,
      }).then((res) => {
        console.log("GAME LIST");
        //console.log(res);
        if (res.data) {
          let theGameSession = res.data.data;
          console.log(theGameSession);
          setGameSession(theGameSession);
        }
      });

      setNFTCollection({
        partner: 1,
      });
    },
    [space_id]
  );

  useEffect(() => {
    //console.log("showTestButton: " + showTestButton);
    //console.log(user);
    //console.log(showTestButton);
    getGameList();
  }, [user]);

  useEffect(() => {
    getGames("level", "2022-04-01");
  }, [getGames]);

  async function getGameList() {
    console.log(user.data);
    if (user.data) {
      //console.log(user.data);
      //console.log("Getting Space List");
      //console.log(user.data);
      let userflags = user.data.flags ? user.data.flags.split(",") : [];
      //console.log(userflags);
      setUserFlags(userflags);
      let theSpaceInfo = {
        elapsedHours: 1,
        participant_count: 1,
        join_code: "Nothing",
        join_code_base: "Nothing",
        id: "123",
        space_link: "https://app.nftscribe.io/game/" + space_id,
        space_id: space_id,
        title: "Game Test",
        allSpaceUsers: [],
      };
      setSpaceInfo(theSpaceInfo);
    }
  }

  useEffect(() => { 
    if(!authContext.user){
      console.log("Signed out");
      console.log("Setting Game ID")
      localStorage.setItem("space_id", space_id);
    } else {
      console.log("Signed in");
      console.log("Clearing Game ID")
      localStorage.removeItem("space_id");
    }

  },[authContext]);

 

  const handleUnityAppReady = useCallback((game_id) => {
      console.log("handleUnityAppReady " + game_id);

      let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url;
      if (serverURL.includes("localhost")) {
        serverURL = "http://" + serverURL + ":8080";
      } else {
        //serverURL = "https://" + serverURL;
        serverURL = "https://game.nftscribe.io";
      }
      //setUnityLoaded(true);
      let startString = user.data.id + "|" + space_id + "|" + serverURL;
      console.log("startString");
      console.log(startString);
      sendMessage("GameController", "StartApp", startString);
    },
    [sendMessage, user, space_id]
  );

  const handleUnityAppReady_Confirm = useCallback(
    (local_user_id) => {
      console.log("handleUnityAppReady_Confirm " + local_user_id);

      let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url;
      if (serverURL.includes("localhost")) {
        serverURL = "http://" + serverURL + ":8080";
      } else {
        //serverURL = "https://" + serverURL;
        serverURL = "https://game.nftscribe.io";
      }

      let userCredits = 1000000;
      let startString =
        user.data.id +
        "|" +
        space_id +
        "|" +
        serverURL +
        "|" +
        userCredits;
      sendMessage("GameController", "StartAppFinal", startString);
    },
    [sendMessage, user, space_id]
  );

  const handleUnityOnBuyItem = useCallback(
    (local_user_id, item_id) => {
      console.log("handleUnityOnBuyItem " + local_user_id + "  " + item_id);

      let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url;
      if (serverURL.includes("localhost")) {
        serverURL = "http://" + serverURL + ":8080";
      } else {
        //serverURL = "https://" + serverURL;
        serverURL = "https://game.nftscribe.io";
      }

      //setUnityLoaded(true);
      let startString = user.data.id + "|" + space_id + "|" + serverURL;
      //sendMessage("GameController", "StartApp", startString);
    },
    [sendMessage, user, space_id]
  );

  useEffect(() => {
    addEventListener("OnAppReady", handleUnityAppReady);
    return () => {
      removeEventListener("OnAppReady", handleUnityAppReady);
    };
  }, [addEventListener, removeEventListener, handleUnityAppReady]);

  useEffect(() => {
    addEventListener("OnAppReady_Confirm", handleUnityAppReady_Confirm);
    return () => {
      removeEventListener("OnAppReady_Confirm", handleUnityAppReady_Confirm);
    };
  }, [addEventListener, removeEventListener, handleUnityAppReady_Confirm]);

  useEffect(() => {
    addEventListener("OnBuyItem", handleUnityOnBuyItem);
    return () => {
      removeEventListener("OnBuyItem", handleUnityOnBuyItem);
    };
  }, [addEventListener, removeEventListener, handleUnityOnBuyItem]);

  useEffect(
    () => {
      let timer1 = setInterval(() => getGames(), 30 * 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer1);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  useEffect(() => {
    console.log("Changing num teams");
    if (numTeamsChanged) {
      sendGameState("startGame", true);
    }
  }, [numTeams, teams]);

  async function launchGame(space) {
    console.log(space);
    setDoGame(true);
  }

  const beTheChange = async (e, stateName, showModal) => {
    //console.log("beTheChange");
    //console.log(e);
    if (e.input === "numTeams") {
      //console.log("Setting num teams");
      let theNumTeams = parseInt(e.value);
      //console.log(theNumTeams);

      setNumTeamsChanged(true);
      setNumTeams(theNumTeams);

      let existingTeams = teams;
      //console.log(existingTeams);
      let newTeams = [];
      for (let x = 0; x < theNumTeams; x++) {
        newTeams.push({
          id: x + 1,
          name:
            existingTeams[x] !== undefined
              ? existingTeams[x].name
              : "Team " + (x + 1),
          group:
            existingTeams[x] !== undefined ? existingTeams[x].group : "Any",
        });
      }

      //console.log(newTeams);
      setTeams(newTeams);
    }

    if (e.input.includes("team_name_")) {
      let team_id = parseInt(e.input.replace("team_name_", ""));
      //console.log("Team ID:" + team_id);
      let existingTeams = teams;
      //console.log(existingTeams);
      let theTeam = existingTeams.filter((x) => x.id === team_id);
      theTeam[0].name = e.value;
      setNumTeamsChanged(true);
      setNumTeams(existingTeams.length);
      setTeams(existingTeams);

      //console.log(existingTeams);

      //console.log("Setting num teams");
      //let theNumTeams = parseInt(e.value);
      //console.log(theNumTeams);
      //setNumTeamsChanged(true);
      //setNumTeams(theNumTeams);
    }

    if (e.input.includes("team_group_")) {
      let team_id = parseInt(e.input.replace("team_group_", ""));
      //console.log("Team ID:" + team_id);
      let existingTeams = teams;
      console.log(existingTeams);
      let theTeam = existingTeams.filter((x) => x.id === team_id);
      theTeam[0].group = e.value;
      setNumTeamsChanged(true);
      setNumTeams(existingTeams.length);
      setTeams(existingTeams);

      console.log(existingTeams);

      //console.log("Setting num teams");
      //let theNumTeams = parseInt(e.value);
      //console.log(theNumTeams);
      //setNumTeamsChanged(true);
      //setNumTeams(theNumTeams);
    }
  };

  async function sendGameState(stateName, showModal) {
    //console.log(stateName);
    //console.log(numTeams);

    let gameServer =
      window.location.hostname === "localhost" ? "": "https://game.nftscribe.io";

    if (stateName === "startGame") {
      let collectionGroups = ["Any", "AI Easy", "AI Normal", "AI Hard"];
      let teamOptions = collectionGroups.map((x) => {
        return {
          value: capitalizeFirstLetter(x),
          label: capitalizeFirstLetter(x),
        };
      });

      let startGameForm = {
        space_id: {
          type: "hidden",
          value: space_id,
        },
        stateName: {
          type: "hidden",
          value: stateName,
        },
        /*bonusPost: {
          label: `Bonus Tweet (Interacters get starting bonus)`,
          type: 'text',
        },*/
        numTeams: {
          label: `Number Teams (0 teams = Everyone vs Everyone)`,
          type: "number",
          value: isNaN(numTeams) ? "0" : numTeams + "",
          min: 0,
          max: 8,
          required: true,
          errorMessage: `Please enter a number between 0 and 8`,
        },
      };

      for (var x = 0; x < numTeams; x++) {
        let teamCounter = x + 1;
        let teamName = teams[x].name;
        let teamGroup = teams[x].group;
        //console.log(teamName, teamGroup);
        //console.log(teamOptions);
        /*
        team1: {
          label: 'Team 1 Name',
          type: 'text'
        },
        team1_type: {
          label: 'Team 1 Members',
          type: 'select',
          options: teamOptions
        },
        */
        startGameForm[`team_name_${teamCounter}`] = {
          label: `Team ${teamCounter} Name`,
          type: "text",
          value: teamName,
        };
        startGameForm[`team_group_${teamCounter}`] = {
          label: `Team ${teamCounter} Player Type`,
          type: "select",
          value: teamGroup,
          default: teamGroup,
          options: teamOptions,
        };
      }

      context.modal.show(
        {
          scroll: true,
          title: "Start Game Session",
          text: "Set your team names and game options here",
          form: startGameForm,
          updateOnChange: true,
          onChange: function (e) {
            beTheChange(e, stateName, showModal);
          },
          buttonText: "Go",
          //text: 'Choose the join code for the space.',
          url: gameServer + "/api/game/create",
          method: "POST",
        },
        (res, theData) => {
          if (res.data) {
          }
        }
      );

      /*const launchGameRes = Axios.post(gameServer + '/api/game/create', { 
        space_id : space_id
      }).then((res) => {
        //console.log(res);
        if(res.data){
  
        }
      });
      */
      return;
    }

    if (showModal) {
      context.modal.show(
        {
          title: "Game Commands",
          text: "Add the param you need to send!",
          form: {
            space_id: {
              type: "hidden",
              value: space_id,
            },
            stateName: {
              type: "hidden",
              value: stateName,
            },
            param: {
              label: "Param",
              type: "text",
            },
          },
          buttonText: "Go",
          //text: 'Choose the join code for the space.',
          url: "/api/game/state/",
          method: "POST",
        },
        (res, theData) => {
          //context.notification.show(data.name + ' was deleted', 'success', true);
          //callback();
          getGameList();
        }
      );
    } else {
      const sendGameStateRes = Axios.post(gameServer + "/api/game/state/", {
        space_id: space_id,
        stateName: stateName,
      }).then((res) => {
        console.log(res.data);
        if (res.data) {
        }
      });
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function handleClick() {
    console.log("Full Screen");
    requestFullscreen(true);
  }

  function handleClickEnterFullscreen() {
    requestFullscreen(true);
  }

  if(!authContext.user){
    return (
      <Card dark700 center restrictWidthWide>
        <SignIn title="Signin Required" text="You must signin to use this feature" />
      </Card>
    );
  }

  
  return (
    <Row dark small>

      {spaceInfo && (
        <>
          {nftCollection && nftCollection.partner !== 1 && 1 === 3 && (
            <Message
              text="Games are available to Scribe partners only! Good news though.. it's free to become a partner project!"
              type="error"
              newTab
              buttonLink="https://nftscribe.io/partner"
              buttonText="Sign up now!"
            />
          )}
          {nftCollection && nftCollection.partner === 1 && (
            <>
              {/*!doGame && (
                <Message
                  text="Ready to have some fun? Share out the link to this page to have others join you. Then click the 'Play Conquest' button!"
                  type="info"
                />
              )*/}
              {showNav && (
                <Grid cols="4">
                  {!doGame && (
                    <Card dark key="Options">
                      <Grid cols="1">
                        {/*{(userRole === "Host" || userRole === "Co-Host") && <Button key="space2" tiny color={"blue"} text='Picker' action={ e =>  {viewSpace(spaceInfo) }}/>}
                          {(userRole === "Host" || userRole === "Co-Host" || userRole === "Speaker") && <Button key="space3" tiny color={"blue"} text='Games' action={ e =>  {viewSpace(spaceInfo) }}/>}
                          {(userRole === "Host" || userRole === "Co-Host") && <Button key="space4" tiny color={"blue"} text='Admin' action={ e =>  {viewSpace(spaceInfo) }}/>}*/}
                        {/*{(user.data && (user.data.twitter_id === '22164071' || user.data.twitter_id === '32460385')) && <Button key="spacegame1" tiny color={"blue"} text='Test Game' action={ e =>  {launchGame(spaceInfo) }}/>}*/}

                        <Button
                          key="spacegame1"
                          tiny
                          color={"blue"}
                          text="Play Conquest"
                          action={(e) => {
                            launchGame(spaceInfo);
                          }}
                        />
                      </Grid>
                    </Card>
                  )}
                  <Card dark key="instructions">
                    <div>
                      <strong>
                        Need some weapons?{" "}
                        <Link
                          title="Get some here!"
                          target="_same"
                          url="/shop/d53313d5-a532-41ad-8c4d-a5637cb2d128"
                          text="Get Some Here!"
                        />
                      </strong>
                    </div>
                  </Card>
                  <Card dark key="share">
                    <SocialShare
                      url={spaceInfo.space_link}
                      description={"Come play Conquest with me!"}
                    />
                  </Card>
                </Grid>
              )}

              <Grid cols="2" class="-mx-6" mobileNoMargin noBottomMargin>
                {user.data && doGame && (
                  <Card
                    dark
                    shadow
                    key={"spaceContext"}
                    className={
                      isMobile ? Style.gameCardTallMobile : Style.gameCardTall
                    }
                    //title = {space.title}
                    center
                  >
                    <>
                      {isLoaded === false && (
                        // We'll conditionally render the loading overlay if the Unity
                        // Application is not loaded.
                        <div className="loading-overlay">
                          <p>Loading... ({loadingPercentage}%)</p>
                        </div>
                      )}
                      {isMobile ? (
                        <>
                          MOBILE
                          <Unity
                            style={{ width: "100%", height: "100%" }}
                            unityProvider={unityProvider}
                            tabIndex={1}
                          />
                        </>
                      ) : (
                        <Unity
                          style={{
                            width: "100%",
                            maxWidth: "1066px",
                            height: 600,
                          }}
                          unityProvider={unityProvider}
                          tabIndex={1}
                        />
                      )}

                      <Button
                        key="full_screen_10"
                        tiny
                        className={Style.devButton}
                        color={"red"}
                        text="Enter Fullscreen"
                        action={(e) => {
                          handleClick();
                        }}
                      />
                    </>
                  </Card>
                )}

                {user.data &&
                  (doGame || userFlags.includes("17")) &&
                  (user.data.superpass_xp > 0 ||
                    user.data.partner === 1 ||
                    userFlags.includes("17")) && (
                    <>
                      {(!gameSession || (gameSession && !gameSession.stayAlive)) && (
                        <>
                          <Card className={Style.devButtons}>
                            <Button
                              key="spacegametest_0"
                              tiny
                              className={Style.devButton}
                              color={"orange"}
                              text="Start Game Session"
                              action={(e) => {
                                sendGameState("startGame", true);
                              }}
                            />
                            {/*<Button key="spacegametest_1" tiny className={Style.devButton} color={"blue"} text='waitForJoin' action={ e =>  {sendGameState("waitForJoin") }}/>*/}
                            {/*<Button key="spacegametest_2" tiny className={Style.devButton} color={"blue"} text='sessionCreated' action={ e =>  {sendGameState("sessionCreated") }}/>*/}
                            {/*<Button key="spacegametest_3" tiny className={Style.devButton} color={"blue"} text='allowJoin' action={ e =>  {sendGameState("allowJoin") }}/>*/}
                            {/*<Button key="spacegametest_4" tiny className={Style.devButton} color={"blue"} text='joined' action={ e =>  {sendGameState("joined") }}/>*/}
                            <Button
                              key="spacegametest_5"
                              tiny
                              className={Style.devButton}
                              color={"blue"}
                              text="Begin Match"
                              action={(e) => {
                                sendGameState("matchBeginCountdown");
                              }}
                            />
                            {/*<Button key="spacegametest_6" tiny className={Style.devButton} color={"blue"} text='matchRunning' action={ e =>  {sendGameState("matchRunning") }}/>*/}
                            <Button
                              key="spacegametest_8"
                              tiny
                              className={Style.devButton}
                              color={"blue"}
                              text="End Match"
                              action={(e) => {
                                sendGameState("matchEndCountdown");
                              }}
                            />
                            {/*<Button key="spacegametest_9" tiny className={Style.devButton} color={"blue"} text='matchEnd' action={ e =>  {sendGameState("matchEnd") }}/>*/}
                            {/*<Button key="spacegametest_10" tiny className={Style.devButton} color={"blue"} text='sessionEnd' action={ e =>  {sendGameState("sessionEnd") }}/>*/}
                          </Card>

                          <Card className={Style.devButtons}>
                            <Button
                              key="spacegametest_20"
                              tiny
                              className={Style.devButton}
                              color={"red"}
                              text="Spawn Enemy"
                              action={(e) => {
                                sendGameState("spawnMob");
                              }}
                            />
                            <Button
                              key="spacegametest_30"
                              tiny
                              className={Style.devButton}
                              color={"red"}
                              text="Spawn 10 Enemies"
                              action={(e) => {
                                sendGameState("spawnMob10");
                              }}
                            />
                            {/*<Button key="spacegametest_20input" tiny className={Style.devButton} color={"red"} text='spawn mob p' action={ e =>  {sendGameState("spawnMob", true) }}/>*/}
                            <Button
                              key="spacegametest_21"
                              tiny
                              className={Style.devButton}
                              color={"red"}
                              text="Spawn Boss"
                              action={(e) => {
                                sendGameState("spawnBoss");
                              }}
                            />
                            {/*<Button key="spacegametest_21input" tiny className={Style.devButton} color={"red"} text='spawn boss p' action={ e =>  {sendGameState("spawnBoss", true) }}/>*/}
                            {/*<Button key="spacegametest_22" tiny className={Style.devButton} color={"red"} text='healz' action={ e =>  {sendGameState("healAll") }}/>*/}
                            {/*<Button key="spacegametest_23" tiny className={Style.devButton} color={"red"} text='hurtz' action={ e =>  {sendGameState("hurtAll") }}/>*/}
                            {/*<Button key="spacegametest_13" tiny className={Style.devButton} color={"red"} text='allowJoin' action={ e =>  {sendGameState("allowJoin") }}/>
                  <Button key="spacegametest_14" tiny className={Style.devButton} color={"red"} text='joined' action={ e =>  {sendGameState("joined") }}/>
                  <Button key="spacegametest_15" tiny className={Style.devButton} color={"red"} text='matchBeginCountdown' action={ e =>  {sendGameState("matchBeginCountdown") }}/>
                  <Button key="spacegametest_16" tiny className={Style.devButton} color={"red"} text='matchRunning' action={ e =>  {sendGameState("matchRunning") }}/>
                  <Button key="spacegametest_17" tiny className={Style.devButton} color={"red"} text='matchEndCountdown' action={ e =>  {sendGameState("matchEndCountdown") }}/>
                  <Button key="spacegametest_18" tiny className={Style.devButton} color={"red"} text='matchEnd' action={ e =>  {sendGameState("matchEnd") }}/>
                */}
                          </Card>
                        </>
                      )}
                    </>
                  )}
              </Grid>
            </>
          )}
        </>
      )}

      <Feedback />
    </Row>
  );
}
