/***
*
*   USER
*   shows the current user 
*   if user belongs to more than one account they can switch accounts here
*
**********/

import React, { useContext } from 'react';
import { AuthContext, ViewContext, HoverNav, Button, Link } from 'components/lib';
import Style from './user.tailwind.js';

export function User(props){

  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const accountName = authContext.user?.accounts?.find(x => x.id === authContext.user?.account_id)?.name;
  //console.log(authContext);
  
  return (
    <div className={ Style.user }>
      
      {!authContext.user && 
        <div className={ Style.name }>
          <Link url='/' text={`Log In`} color='cyan' />
          </div>
      }

      {authContext.user?.name && 
        <div className={ Style.name }>
          {/*<Link url='/account/profile' text={`Welcome, ${authContext.user.name}!`} color='cyan' />*/}
          <Link url='/account/profile' text={`${authContext.user.name}`} color='cyan' />
          
        <Button 
          tiny
          iconButton
          insideButton
          key={ 'signoutbutton' }
          text={ 'Sign Out' } 
          icon= 'log-out'
          action={() => {
            authContext.signout();
          
          }}
        />
        </div>
      }
      
      
      

      { authContext.user?.accounts?.length > 1 &&
        <HoverNav 
          dark
          icon='user' 
          label={ accountName } 
          className={ Style.hoverNav }
          align='right' >
          { authContext.user.accounts.map(account => {
              
              return (
                <Button 
                  key={ account.id }
                  text={ account.name } 
                  action={() => {
                    
                    viewContext.setLoading(true);
                    authContext.switchAccount(account.id);
                  
                  }}
                />
              );
            }
          )}
        </HoverNav>
      }
   </div>
  )
}