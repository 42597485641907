/***
*
*   SOCIAL SIGN IN BUTTONS
*   To sign up/in with Facebook, Google or Microsoft
*
**********/

import React, { useState } from 'react';
import { Button } from 'components/lib';
import { ClassHelper } from 'components/lib';
//import settings from 'settings';
import Style from './social.tailwind.js';

export function SocialSignin(props){

  const [loading, setLoading] = useState(props.network?.map(x => { return { [x]: false }}));
  
  //console.log("process.env.SERVER_URL: " + process.env.SERVER_URL);
  //console.log("window.location.hostname : " + window.location.hostname);
  //console.log("settings[process.env.NODE_ENV].SERVER_URL: " + settings[process.env.NODE_ENV].server_url);
  //console.log(settings[process.env.NODE_ENV]);
  let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url; 
  if(serverURL.includes("localhost")){
    serverURL = "http://" + serverURL + ":8080";
  } else {
    serverURL = "https://" + serverURL;
  }

  //console.log("serverURL: " + serverURL);

  // construct query string
  let qs = '';
  if (props.invite) qs = `?invite=${props.invite}`;
  if (props.signup) qs = '?signup=1'
  if (props.accountlink) qs = `?aclink=${props.network}&acu=${props.uid}`
  let referrer = localStorage.getItem('referrer');
  if (referrer) qs = `?referrer=${referrer}`;
  //console.log(qs);

  let localUser = localStorage.getItem('user');
  let walletLogin = localStorage.getItem('walletLogin');
  if(localUser && !walletLogin){
    console.log("removing local user");
    localStorage.removeItem("user");
  }

  return (
    <div className={ props.normal ? Style.signinButtonsNormal : Style.signinButtons }>
      { props.network?.map(n => {

        const css = ClassHelper(Style, { [n]: true, signinButton: true });
        
        return (
          <Button  
            key={ n }
            loading={ loading[n] }
            icon={['fab', n]}
            iconPack='fontawesome'
            iconSize='1x'
            iconButton
            fullWidth
            className={ css }
            action={ () => {
              if(props.localStorage){
                //console.log("Setting local storage: " + props.localStorage);
                localStorage.setItem(props.localStorage, true);
              }
              setLoading({ [n]: true }) }
            }
            url={ `${serverURL}/auth/${n}${qs}` }
            text={ props.text ? props.text : `Continue with ${n.charAt(0).toUpperCase() + n.slice(1)}` }
          />
        )
      })}

      { props.showOr && 
        <span className={ Style.or }>or</span> }

    </div>
  );
}