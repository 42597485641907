/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useState, useEffect, useCallback } from 'react';
//import useWindowSize from 'react-use/lib/useWindowSize';

import { useLocation } from 'react-router-dom'
import { Table, NFTViewNav, useNavigate, Message, Feedback , Row} from 'components/lib';

//import Style from '../nft.tailwind.js';
//import { useWeb3React } from "@web3-react/core";
import Axios from 'axios';

//Solana stuff
//import { useConnection, useWallet } from "@solana/wallet-adapter-react";

export function NFTViewerEvents(props){

  const navigate = useNavigate();
  const location = useLocation();

  //const onlyWidth = useWindowWidth()
  //const onlyHeight = useWindowHeight()

  let pathSplit = location.pathname.split("/");
  const nft_id = pathSplit[pathSplit.length-1];

  // fetch
  //const nftData = useAPI('/api/nft/' + nft_id);
  const [nftData, setNFTData] = useState(null);
  //const [unAssignedXP, setUnAssignedXP] = useState(0);
  //const { active, account, chainId } = useWeb3React();
  //const [existingAccount, setAccount] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  

  // We get the public key of the connected Solana wallet, if there is one
  //const { connection } = useConnection();
  //console.log("Solana wallet: " + publicKey);

  const getDetails = useCallback(async () => {

    setDetailsLoading(true);
    //console.log("Getting Details");
    /*const res = await Axios.post("/api/nft/events", {
      nft_id: nft_id,
      truncate_events: false,
    });*/

    const res = await Axios.get(`/api/nft/events/${nft_id}?limit=200`);
   
    if(!res.data.success){
      console.log("Unauthorized");
      navigate('/home');
    } else {
      
      //let unAssignedXP = (res.data.data.nftData.xp_raw - res.data.data.nftData.xp).toLocaleString();
      //setUnAssignedXP(unAssignedXP);
      setNFTData(res.data.data);
      setDetailsLoading(false);      

    }

  }, [nft_id, navigate]);

  useEffect(() => {
    //console.log("Initial load");
    const id = nft_id;
    if (id) getDetails(id);
  }, [getDetails, nft_id])
    
  return (
    
    <Fragment>
      <NFTViewNav dark nft_id={nft_id} />
      <Row dark>
      

        {!detailsLoading && nftData && nftData.eventCollection.length === 0 && 
          <Message 
            color='transparent' 
            title='Events' 
            text={'Events this NFT is collecting will be listed below'}
          />
        }

        {!detailsLoading && nftData && nftData.eventCollection.length >  0 && 
          
        <Table
          search
          data={ nftData.eventCollection }
          loading={ false }
          badge={{ col: 'plan', color: 'blue' }}>
        </Table>
        }

    </Row>
      <Feedback />

    </Fragment>
  );
}
