/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import { AuthContext, AppNav, Header, User, WalletConnect, QuickLinks, Footer, Switch, ViewContext, Logo } from 'components/lib';

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { clusterApiUrl } from '@solana/web3.js'
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets'


import Style from './app.module.scss';
require('@solana/wallet-adapter-react-ui/styles.css');

export function AppDarkLayout(props){
  //console.log(props);
  
  
  // context & style
  const context = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  
  //viewContext.setAdvanced(savedBasic);
  const [advanced, setAdvanced] = useState(viewContext.isAdvanced);
  //console.log(context);

  //const network = WalletAdapterNetwork.Devnet;
  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = clusterApiUrl(network);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter({ network }),
  ];

  let menuItems = [
    { label: 'Dashboard', icon: 'home', link: '/home' },
    //{ label: 'Collectibles', icon: 'image', link: '/collectibles' },
    { label: 'Conquest', icon: 'map', link: '/games' },
    { label: 'Spaces', icon: 'twitter', link: '/spaces' },
    { label: 'Social Posts', icon: 'book', link: '/posts' },
    { label: 'Communities', icon: 'box', link: '/c' },
    //{ label: 'Leaderboard', icon: 'award', link: '/leaderboard' },
    
  ];

  
 
  menuItems.push({ label: 'Shop', icon: 'shopping-cart', link: '/shop' });
  menuItems.push({ label: 'Apps', icon: 'zap', link: '/supported_apps' });
  menuItems.push({ label: 'Help', icon: 'help-circle', link: '/help' });
  if(context && context.permission && context.permission.developer){
    menuItems.push({ label: 'Developer', icon: 'code', link: '/developer' });
  } 

  if(context && context.permission ){
    menuItems.push({ label: 'Account', icon: 'user', link: '/account/profile' });
    menuItems.push({ label: 'Sign Out', icon: 'log-out', action: context.signout });
  } else {
    menuItems.push({ label: 'Sign In', icon: 'log-in', link: '/signin' });
  }

  
  function change(name, state){
    //console.log(name, state);
    
    //setAdvanced(state);
    viewContext.setAdvanced(state);
  }

  
  useEffect(() => {
    //console.log("viewContext");
    //console.log(viewContext);
    if(!viewContext.isAdvanced){
      //console.log("viewContext BASIC");
    } else {
      //console.log("viewContext !BASIC");
    }
    setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);
  
  


  return (
    <Fragment>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            {advanced && 
              <AppNav
                items={menuItems}
              />
            }
            
            

            <main className={ advanced ? Style.appDark : Style.appDarkBasic }>
              
              
              <Header dark center title={ props.title } className='pt-1.5'>
              {!advanced && 
                <Logo color meta className='absolute top-1 left-1 md:left-20 !max-h-16 md:max-h-20 w-20 md:w-36 ' />
              }
              
                {/*<MetaMaskAuth onAddressChanged={address => {}}/>*/}
                <div id='switchContainer' className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 !-mt-2.5 !-ml-6'>
                  <Switch 
                    small
                    label= {'Advanced'}
                    name= 'BasicToggle'
                    default= {advanced}
                    onChange={ (name, state, state2) => change(name, state, state2)}
                  />  
                </div>
                
                <User/>
              </Header>
              {/*<QuickLinks />  */}
              

              { <props.children {...props.data }/> }

            </main>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
    <Footer />
    </Fragment>
  );
}