import React, { useEffect, useState, useRef } from 'react';

import './messages.css';

function Messages({ socket, userInfo, roomName}) {
  const [messages, setMessages] = useState({});
//  const messagesEndRef = React.createRef();
//  const chatContainer = React.createRef();
  const messagesEndRef = useRef(null)
  
  const scrollToBottom = () => {
    //messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages]);

  useEffect(() => {
    
    /*const scrollToMyRef = () => {
      const scroll =
        chatContainer.current.scrollHeight -
        chatContainer.current.clientHeight;
      chatContainer.current.scrollTo(0, scroll);
    };

    const scrollToBottom = () => {
      this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }*/
    

    

    const messageListener = (message) => {
      //console.log(message);
      setMessages((prevMessages) => {
        const newMessages = {...prevMessages};
        newMessages[message.id] = message;
        return newMessages;
      });
      scrollToBottom();
    };
  
    const deleteMessageListener = (messageID) => {
      setMessages((prevMessages) => {
        const newMessages = {...prevMessages};
        delete newMessages[messageID];
        return newMessages;
      });
    };

    const helloListener = (message) => {
      console.log("Hello from server");
      socket.emit('hello', userInfo, roomName);
    };

    const joinRoomListener = (room, id) => {
      console.log(id + "has joined the room: " + room);
      
    };
  
    socket.on('join-room', joinRoomListener)
    socket.on('hello', helloListener);
    socket.on('message', messageListener);
    socket.on('deleteMessage', deleteMessageListener);
    //socket.emit('getMessages');

    return () => {
      socket.off('join-room', joinRoomListener);
      socket.off('hello', helloListener);
      socket.off('message', messageListener);
      socket.off('deleteMessage', deleteMessageListener);
    };
  }, [socket, roomName, userInfo]);

  return (
    <>
      <div className="message-list">
        {[...Object.values(messages)]
          .sort((a, b) => a.time - b.time)
          .map((message) => (
            <div
              key={message.id}
              className="message-container"
              title={`Sent at ${new Date(message.time).toLocaleTimeString()}`}
            >
              <span className="user"><a href={`https://twitter.com/${message.user.twitter_id}`} target='_new'>{message.user.name}</a></span>
              <span className="message">{message.value}</span>
              <span className="date">{new Date(message.time).toLocaleTimeString()}</span>
            </div>
          ))
        }
        <div ref={messagesEndRef} />
      </div>
      
    </>
  );
}

export default Messages;