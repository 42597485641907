const Style = {

    signin: ``,
  
    blurb: 'mb-10 md:max-w-80 mx-auto',
    title: 'block text-3xl md:text-4xl font-bold mb-4',
    tagline: 'block text-lg m0 mb-4',
    image: '-mb-12 shadow-lg',
    button: 'md:mx-auto',
  
  }
  
  export default Style;