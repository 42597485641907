/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback, Fragment} from 'react';
import { useLocation } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
//import truncateEthAddress from 'truncate-eth-address';
import { Card, Stat, ProgressBar, Chart,  Button, Image, Row, Link, Form, Search, CommunityViewNav, Table,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, AuthContext, WalletConnect } from 'components/lib';

import { ethers, utils, BigNumber } from 'ethers';

//https://eth-mainnet.g.alchemy.com/v2/RR7i6KryXLb4CRj-wkmv4Tyr_lr-9jj0
  
//import Axios from 'axios';
//import Style from './project.tailwind.js';

//let lastSearch = '';

export function CommunityViewCollectibles(props){

  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const location = useLocation();
  //const [tokenPrice, setTokenPrice] = useState(null);
  //const [contractABI, setContractABI] = useState(null);
  //const [contractAddress, setContractAddress] = useState(null);
  
  //const [walletAddress, setWallet] = useState("");
  //const [status, setStatus] = useState("");
  //const [waitMint, setWaitMint] = useState(false);

  //const [name, setName] = useState("");
  //const [description, setDescription] = useState("");
  //const [url, setURL] = useState("");

  //const { active, account, chainId } = useWeb3React();
  const [community, setCommunity] = useState(null);
  const [communityError, setCommunityError] = useState(false);
  const [communityCollectibles, setCommunityCollectibles] = useState(null);
  const [communityContracts, setCommunityContracts] = useState(null);
  const [advanced, setAdvanced] = useState(false);
  
  let pathSplit = location.pathname.split("/");
  const community_slug = pathSplit[pathSplit.length-1];
  const user = useAPI('/api/user', authContext);
  const communityInfo = useAPI('/api/community/' + community_slug);
  const communityCollectiblesData = useAPI('/api/community/collectibles/' + community_slug);


  function truncateToken(tokenString){
    tokenString = tokenString + ""
    let retString = tokenString;
    if(tokenString.length > 16){
      let strLen = tokenString.length;
      let string1 = tokenString.substring(0,12);
      let string2 = tokenString.slice(-4);
      retString = string1 + "..." + string2;
    }
    return retString;
  }

  function truncateString(theString, truncLength = 50) {
    let retString = theString;
    if (theString.length > truncLength) {
      let strLen = theString.length;
      let string1 = theString.substring(0, truncLength);
      retString = string1 + "...";
    }
    return retString;
  }

  useEffect(() => {

    //console.log(communityInfo);
    if(communityInfo.loading){
      //console.log("LOADING NO DATA YET");
    } else {
      if(!communityInfo.success){
        //console.log("NOT LOADING, FAIL");
        setCommunityError(true);
      }
    }
    if(communityInfo && communityInfo.data) {
      //console.log(communityInfo);
      viewContext.setTitle(communityInfo.data.community.community_name + " [Collectibles]");
      //console.log(communityInfo.data.community);
      setCommunity(communityInfo.data.community);
    

    let tmpContracts = communityInfo.data.community.contracts;
      if(tmpContracts){

        let header = [
          //{ name: 'id', title: 'ID', sort: true },
          { name: 'image_url', title: '', sort: false },
          { name: 'name', title: 'Name', sort: true },
          { name: 'chain', title: 'Chain', sort: true},
          { name: 'contract_link', title: 'Contract', sort: true},
          { name: 'contract_address', title: 'Address', sort: true},
        ];
        
        let tableBody = [];
        tmpContracts.forEach((anItem, index) => {
          //console.log(anItem);

          let contractAction = 'https://etherscan.io/address/' + anItem.address;
          switch(anItem.chain){
            case 'ETH': contractAction = 'https://etherscan.io/address/' + anItem.address; break;
            case 'SOL': contractAction = 'https://solana.nftscan.com/' + anItem.address; break;
            case 'AVAX': contractAction = 'https://snowtrace.io/address/' + anItem.address; break;
            case 'MATIC': contractAction = 'https://polygonscan.com/address/' + anItem.address; break;
            case 'CRO': contractAction = 'https://cronoscan.com/address/' + anItem.address; break;
            default:
              contractAction = 'https://etherscan.io/address/' + anItem.address; break;
          }

          tableBody.push({
            id: index + 1,
            image_url: anItem.image_url,
            contract_link: {text: anItem.name, url: `/user/${anItem.slug}`, target:'_new'}, // + aCollectible.user_id,
            link: {text: anItem.name, url: anItem.external_link, target:'_new'}, // + aCollectible.user_id,
            name: anItem.description ? `<strong>${anItem.name}</strong><br/><small>(${truncateString(anItem.description, 100)}</small>)` : `<strong>${anItem.name}</strong>`,
            chain: anItem.chain,
            slug: anItem.slug,
            contract_address: {text: truncateToken(anItem.address), url: contractAction, target:'_new'},
          })
        });

        

        let contractsTableData = {
          header: header,
          body: tableBody
        }
        setCommunityContracts(contractsTableData);
      }

    if(communityCollectiblesData && communityCollectiblesData.data){
      //console.log("Collectibles");
      //console.log(communityCollectiblesData);
      let tmpCollectibles = communityCollectiblesData.data.collectibles;

      let header = [
        //{ name: 'id', title: 'ID', sort: true },
        { name: 'image_url', title: 'Pic', sort: false },
        { name: 'token_id', title: 'Token', sort: true },
        { name: 'owner', title: 'Owner', sort: true},
        { name: 'name', title: 'Name', sort: true },
        { name: 'level', title: 'Level', sort: true },
      ];
      
      let tableBody = [];
      if(tmpCollectibles){
      tmpCollectibles.forEach((aCollectible, index) => {
        //console.log(aCollectible);
        tableBody.push({
          id: index + 1,
          image_url: aCollectible.image_url,
          token_id: truncateToken(aCollectible.token_id),
          owner: {text: aCollectible.username, url: `/u/${aCollectible.username_slug}`, target:'_new'}, // + aCollectible.user_id,
          name: aCollectible.nickname ? aCollectible.name + ` (${aCollectible.nickname})` : aCollectible.name,
          level: aCollectible.level,
          
          nft_id: aCollectible.id,
          asset_contract: aCollectible.asset_contract,
          
          })
        });
      }
      let collectiblesTableData = {
        header: header,
        body: tableBody
      }
      setCommunityCollectibles(collectiblesTableData);
    }
  }

  }, [viewContext, communityInfo, communityCollectiblesData]);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);
  

  async function loadContract() {
    //return new web3.eth.Contract(contractABI, contractAddress);
  }

  async function viewItem(aCollectible) {
    console.log(aCollectible);
    var win = window.open('/nft/view/' + aCollectible.nft_id, '_blank');
    /*
    
    let contractAction = 'https://etherscan.io/address/' + aContract.contract_address;
      switch(aContract.chain){
        case 'ETH': contractAction = 'https://etherscan.io/address/' + aContract.contract_address; break;
        case 'SOL': contractAction = 'https://solana.nftscan.com/' + aContract.contract_address; break;
        case 'AVAX': contractAction = 'https://snowtrace.io/address/' + aContract.contract_address; break;
        case 'MATIC': contractAction = 'https://polygonscan.com/address/' + aContract.contract_address; break;
        case 'CRO': contractAction = 'https://cronoscan.com/address/' + aContract.contract_address; break;
        default:
          contractAction = 'https://etherscan.io/address/' + aContract.contract_address; break;
      }

      var win = window.open(contractAction, '_blank');
      */
    
  };

  async function viewContractItem(anItem) {
    console.log(anItem);
    if(anItem.link.url !== null){
      var win = window.open(anItem.link.url, '_blank');
    }
    
  };
  async function viewItemOpenSea(anItem) {
    //console.log(anItem);
    var win = window.open("https://opensea.io/collection/" + anItem.slug, '_blank');
  };

  
  return (
    <Fragment>
      <CommunityViewNav dark user={user} community_info={communityInfo.data} center={!advanced} loading={communityInfo.loading} loadFail={communityInfo.loading ? false : communityError} />
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      {community && community.community_type.includes('NFT') &&
          <Card 
            dark
            title="Community Collections"
          >
            {!communityContracts && 
            <p>No Digital Collectible contracts associated with this Community yet</p>
            }
            {communityContracts && 
            <Table
              dark
              
              data={ communityContracts }
              loading={ community.loading }
              show={['image_url', 'name', 'chain', 'contract_address']}
              badge={{ col: 'chain', color: 'grey', condition: [

                { value: 'ETH', color: 'blue' },
                { value: 'AVAX', color: 'red' },
                { value: 'MATIC', color: 'violet' },
                { value: 'SOL', color: 'blue' },
                { value: 'CRO', color: 'black' }
              
              ]}}
              image={['image_url']}
              link={['contract_link', 'contract_address']}
              parse={['name']}
              actions={{ 
                //view: viewItem
                custom: [{ icon: 'eye', action: viewContractItem},{ icon: 'zap', /*iconPack: 'fontawesome',*/ action: viewItemOpenSea, condition: { col: 'chain', value: "ETH" }}]
              }}
            />}
          </Card>
        }
      {!communityInfo.loading && communityInfo.data && communityInfo.data.project &&
        <Card>
          <strong>{communityInfo.data.project.project_name}</strong><br/>
        </Card>
      }

      <Card dark>
        <Table
          search
          data={ communityCollectibles }
          loading={ communityCollectiblesData.loading }
          show={['image_url', 'token_id', 'name', 'owner', 'level']}
          badge={{ col: 'plan', color: 'blue' }}
          image={['image_url']}
          link={['owner']}
          actions={{ 
            //view: viewItem
            custom: [{ icon: 'eye', action: viewItem}]    
          }}
        >
        </Table>
      </Card>
      </div>
      <Feedback />

    </Fragment>
  );
}
