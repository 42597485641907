/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback, Fragment} from 'react';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import { useWeb3React } from "@web3-react/core";
//import truncateEthAddress from 'truncate-eth-address';
import { Card, Stat, ProgressBar, Chart,  Button, Image, Row, Link, useNavigate, Form, Search, CommunityViewNav, TabView, Table, Icon,
  Message, Grid, Animate, Feedback, useAPI, ViewContext, AuthContext, WalletConnect } from 'components/lib';


import { ethers, utils, BigNumber } from 'ethers';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CreditHistory } from "views/shop/credits.js";
import { PlayHistory } from "views/shop/play.js";

//https://eth-mainnet.g.alchemy.com/v2/RR7i6KryXLb4CRj-wkmv4Tyr_lr-9jj0
  
//import Axios from 'axios';
import Style from './community.tailwind.js';

//let lastSearch = '';

export function CommunityAdmin(props){

  
  const viewContext = useContext(ViewContext);
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAPI('/api/user');
  //const [tokenPrice, setTokenPrice] = useState(null);
  //const [contractABI, setContractABI] = useState(null);
  //const [contractAddress, setContractAddress] = useState(null);
  
  //const [walletAddress, setWallet] = useState("");
  //const [status, setStatus] = useState("");
  //const [waitMint, setWaitMint] = useState(false);

  //const [name, setName] = useState("");
  //const [description, setDescription] = useState("");
  //const [url, setURL] = useState("");

  //const { active, account, chainId } = useWeb3React();
  const [communityContracts, setCommunityContracts] = useState(null);
  const [copied_ID, set_copied_ID] = useState(false);
  const [communityError, setCommunityError] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const [userFlags, setUserFlags] = useState([]);
  
  
  let pathSplit = location.pathname.split("/");
  const community_slug = pathSplit[pathSplit.length-1];
  const communityInfo = useAPI('/api/community/' + community_slug + '?a=' + community_slug);
  

  function truncateToken(tokenString){
    let retString = tokenString;
    if(tokenString.length > 16){
      let strLen = tokenString.length;
      let string1 = tokenString.substring(0,12);
      let string2 = tokenString.slice(-4);
      retString = string1 + "..." + string2;
    }
    return retString;
  }

  useEffect(() => {
    if (user.data) {
      //console.log(user);
      let userflags = user.data.flags ? user.data.flags.split(",") : [];
      //console.log(userflags);
      setUserFlags(userflags);
    }
  }, [user]);

  useEffect(() => {

    //console.log(communityInfo);
    if(communityInfo.loading){
      //console.log("LOADING NO DATA YET");
    } else {
      if(!communityInfo.success){
        //console.log("NOT LOADING, FAIL");
        setCommunityError(true);
      }
    }
    
    if(communityInfo && communityInfo.data) {
      console.log(communityInfo);
      viewContext.setTitle(communityInfo.data.community.community_name + " [Admin]");
    }

    if(communityInfo && communityInfo.data?.community.contracts) {
      let tmpContracts = communityInfo.data.community.contracts;
      
      let header = [
        //{ name: 'id', title: 'ID', sort: true },
        
        { name: 'chain', title: 'Chain', sort: true },
        { name: 'name', title: 'Name', sort: true },
        { name: 'address', title: 'Contract Address', sort: true },
        
      ];
      
      let tableBody = [];
      tmpContracts.forEach((aContract, index) => {
        
        tableBody.push({
          id: index + 1,
          chain: aContract.chain,
          name: aContract.name,
          address: aContract.address,
        })
      });
      let contractTableData = {
        header: header,
        body: tableBody
      }
      
      setCommunityContracts(contractTableData);
      
    }


  }, [communityInfo]);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);  

  async function loadContract() {
    //return new web3.eth.Contract(contractABI, contractAddress);
  }

  async function launchPartner() {
    
    const res = await Axios.post('/api/nft/launchPartner', { 
      slug: community_slug
    });
    console.log(res);
    viewContext.notification.show("Partner Launched", 'success', true);
  };

  async function viewItem(aContract) {
    console.log(aContract);
    
    
    let contractAction = 'https://etherscan.io/address/' + aContract.contract_address;
      switch(aContract.chain){
        case 'ETH': contractAction = 'https://etherscan.io/address/' + aContract.contract_address; break;
        case 'SOL': contractAction = 'https://solana.nftscan.com/' + aContract.contract_address; break;
        case 'AVAX': contractAction = 'https://snowtrace.io/address/' + aContract.contract_address; break;
        case 'MATIC': contractAction = 'https://polygonscan.com/address/' + aContract.contract_address; break;
        case 'CRO': contractAction = 'https://cronoscan.com/address/' + aContract.contract_address; break;
        default:
          contractAction = 'https://etherscan.io/address/' + aContract.contract_address; break;
      }

      var win = window.open(contractAction, '_blank');
      
    
  };
  
  return (
    <Fragment>
      {/*communityInfo.data && <CommunityViewNav dark user={user} community_info={communityInfo.data.community} /> */}
      <CommunityViewNav dark user={user} community_info={communityInfo.data} center={!advanced} loading={communityInfo.loading} loadFail={communityInfo.loading ? false : communityError} />
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      {!communityInfo.loading && communityInfo.data && communityInfo.data.project &&
        <Card>
          <strong>{communityInfo.data.project.project_name}</strong><br/>
        </Card>
      }
      {communityInfo.data &&  communityInfo.data?.community && 
        <>
          <Card dark>
          <CopyToClipboard
            options={{ debug: true, message: "Yeah" }}
            text={communityInfo.data.community.id}
            
            onCopy={() => {
              console.log("Copied");
              set_copied_ID(true);
            }}
          >
          <span className={ copied_ID ? 'text-cyan-400' : 'text-white' }>
            <strong >Community ID: </strong>{communityInfo.data.community.id}&nbsp;
            {copied_ID && <small className={Style.greenFadeText}> Copied!</small>}
            {!copied_ID && 
              <Icon 
                image={ 'copy' } 
                color={ copied_ID ? 'cyan' : 'dark' }
                className={ 'display: inline cursor-pointer' }
              />}
          </span>
        
        </CopyToClipboard>
            
            
          </Card>
          
          <TabView dark name='AdminOptions' initialView={0} labels={ 
            communityInfo.data?.community.community_type.includes('NFT') ? 
            ['Community Info','Accounts','Discord','Blockchain'] :
            ['Community Info','Accounts','Discord']
            }>
              <Card dark restrictWidthWide>
                {userFlags.includes("20") && 
                <Button tiny color={"blue"} text='Launch Arcade' action={ e => launchPartner() }/>
                }
                <Form 
                  data={{
                    community_id: {
                      type: 'hidden',
                      value: communityInfo.data.community.id,
                    },
                    community_email: {
                      label: "Community Contact Email",
                      type: "text",
                      value: communityInfo.data.community.owner_email,
                      required: true,
                    },
                    community_twitter: {
                      label: "Community Twitter Handle (ie @username)",
                      type: "text",
                      value: communityInfo.data.community.community_twitter,
                    },
                    description: {
                      label: "Description",
                      type: "textarea",
                      value: communityInfo.data.community.description,
                      required: true,
                    },
                    discord_url: {
                      label: "Discord URL",
                      type: "text",
                      value: communityInfo.data.community.discord_url,
                      required: false,
                    },
                    discord_server_id: {
                      label: "Discord Server ID (from the Widget area of your Discord Server Settings)",
                      type: "text",
                      value: communityInfo.data.community.discord_server_id,
                      required: false,
                    },
                    website: {
                      label: "Main Website (if available)",
                      type: "text",
                      value: communityInfo.data.community.external_link,
                      required: false,
                    },
                  }
                    
                  }
                  method='POST'
                  url='/api/community/update'
                  //updateOnChange
                  className={ Style.form }
                  buttonText='Save'
                  /*onChange={ e => {
                    beTheChange(e)
                  }}*/
                  callback={ (res, data) => {
                    console.log(res.data);
                    //setShowForm(false);
                    //context.notification.show('Thank you, your feedback is most appreciated.', 'success', true, 'toast', 'heart');
                    if(res.data?.message){

                      viewContext.notification.show(res.data.message, res.data.success !== undefined ? res.data.success ? 'success' : 'error' : 'success', true);
                      
                      if(res.data.success){
                        //let slug = res.data.slug;
                        setTimeout(() => { navigate('/c/admin/' + community_slug)}, 2000);
                        
                      }
                    }

                  }}
                />
            
            </Card>
            <Card dark ><br /></Card>
            <Card dark><br /></Card>
            {communityInfo.data?.community.community_type.includes('NFT') && 
              <Card dark>
                <Card dark>
                  <strong className='text-slate-100'>Existing Contracts</strong>
                  <Table
                    data={ communityContracts }
                    loading={ false }
                    show={['chain', 'name', 'address']}
                    badge={{ col: 'chain', color: 'grey', condition: [

                      { value: 'ETH', color: 'blue' },
                      { value: 'AVAX', color: 'red' },
                      { value: 'MATIC', color: 'yellow' },
                      { value: 'SOL', color: 'blue' },
                      { value: 'CRO', color: 'black' }
                    
                    ]}}

                    actions={{ 
                      //view: viewItem
                      custom: [{ icon: 'eye', action: viewItem}]    
                    }}
                    >
                  </Table>
                  <br/>
                </Card>
                <Card title = 'Add Contract' dark restrictWidthWide>
                  <Form 
                    data={{
                      community_id: {
                        type: 'hidden',
                        value: communityInfo.data.community.id,
                      },
                      contract_chain: {
                        label: 'Chain',
                        type: 'select',
                        required: true,
                        default: 'ETH',
                        options: [
                          {value:'ETH',label:'Ethereum'},
                          {value:'SOL',label:'Solana'},
                          {value:'AVAX',label:'Avalanche'},
                          {value:'CRO',label:'Cronos'},
                          {value:'MATIC',label:'Polygon'}
                        ]
                      },
                      contract_address: {
                        label: `Contract Address`,
                        type: 'text',
                        required: true,
                      },
                    }}
                    method='POST'
                    url='/api/community/updateContracts'
                    updateOnChange
                    //className={ Style.form }
                    buttonText='Add Contract'
                    onChange={ e => { return false }}
                    callback={ (res, data) => {
                      console.log(res.data);
                      //setShowForm(false);
                      //context.notification.show('Thank you, your feedback is most appreciated.', 'success', true, 'toast', 'heart');
                      if(res.data?.message){
  
                        viewContext.notification.show(res.data.message, res.data.success !== undefined ? res.data.success ? 'success' : 'error' : 'success', true);
                        
                        if(res.data.success){
                          //let slug = res.data.slug;
                          console.log("Refreshing page");
                          setTimeout(() => { window.location.reload()}, 2000);
                          
                        }
                      }
  
                    }}
                  />
                </Card>
              </Card>
            }
            
            
          </TabView>
          
        </>
      }

      {communityInfo.data?.community && 
        <></>
      }
      {communityInfo.data?.community && communityInfo.data?.community.community_type.includes('NFT') &&
        <Card dark restrictWidth>
        
        </Card>
      }
      
      </div>
      <Feedback />

    </Fragment>
  );
}
