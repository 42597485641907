/***
*
*   SUB NAV
*   Sub navigation element (located underneath the header).
*
**********/

import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext, } from 'components/lib';
import './sub.scss';

export function SubNav(props){

  const context = useContext(AuthContext);
  let classNames = 'subnav';
  if(props.dark){
    classNames = 'subnavdark';
  }

  if(props.center){
    classNames += ' center';
  }

  return(
    <nav className={classNames}>
      { props.items?.map(item => {
        
        if (item.permission && !context.permission[item.permission])
          return false

        return (
          <NavLink
            key={ item.label }
            to={ item.link }
            activeclassname='active'
            className={props.dark? 'itemdark' : 'item'}>

            { item.label }

          </NavLink>
        );
      })}
    </nav>
  );
}
