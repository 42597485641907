/***
*
*   GRID
*   Responsive one-to-six column grid layout
*
*   PROPS
*   cols: number of columns (default: 2, max: 6)
*
**********/

import React from 'react';
import { ClassHelper } from 'components/lib';
import Style from './grid.module.scss';

export function Grid(props){

  const cols = {}
  const colNames = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  let noMargin = '';
  let tightGrid = '';
  let noBottomMargin = '';

  if (props.cols){

    for (let i = 0; i < parseInt(props.cols); i++)
      cols[colNames[i]] = true;

  }

  if(props.mobileNoMargin){
    noMargin = true;
  }
  /*if(props.tightGrid){
    tightGrid = true;
  }*/
  if(props.noBottomMargin){
    noBottomMargin = true;
  }

  const css = ClassHelper(Style, {...cols, ...{ grid: true }, ...{noMargin: props.mobileNoMargin}, ...{noBottomMargin: props.noBottomMargin}, ...{tightGrid: tightGrid}});
  //console.log(css);
  
  return(
    <section className={ css }>
      { props.children?.length > 1 ?
        props.children.map((child, index) => {

          return child

        }) : props.children
      }
    </section>
  );
}