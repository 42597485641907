const Style = {
  collection: 'p0 text-xs text-normal-tight',
  collectionTitle: 'p0 text-s text-normal-tight font-semibold leading-4',
  inscribeAllBtn: 'inline text-center font-semibold rounded px-12 py-3 mx-4 ease-in-out duration-500 text-white',
  nftname: 'p0 text-xs font-semibold',
  subtext: 'p0 text-xs font-semibold bg-orange-500 text-white',
  nft: 'mx-auto text-center min-h-400 shadow-lg',

  nftNoButton: 'mx-auto text-center min-h-360 shadow-lg',
  nftLocked: 'mx-auto text-center min-h-345 shadow-lg',
  nftholder: 'mx-auto text-center min-h-390 shadow-lg',
  nftCollectionCard: 'mx-auto text-center min-h-275 shadow-lg',
  nftStats: 'mx-auto min-h-390 shadow-lg',
  nftButton: 'px-5 py-1!important mx-auto text-center mt-1',
  nftUpgradeButton: 'px-5 py-1!important mx-auto text-center mt-1 min-w-230',

  nftImage: 'cursor-pointer max-h-80 sm:max-h-52 mx-auto',
  nftImageSmall: 'cursor-pointer max-h-80 sm:max-h-40 mx-auto',
  nftCollectionImage: 'max-h-32 mx-auto',
  nftStatCardCenter: 'text-center shadow-lg min-h-358',
  nftStatCardCenterOverflow: 'text-center shadow-lg min-h-358 overflow-y-auto',

  nftStatCenter: 'text-center text-xs',
  nftStatName: 'p0 font-semibold',

  statCard: 'shadow-lg',
  overflowCard: 'overflow-y-auto',
  applicationImage: 'max-h-40 mx-auto',
  signatureImage: 'max-h-40 mx-auto border-solid border border-slate-200 p-3',
  
  smallRow: 'p0 text-s font-semibold text-center bg-white shadow-lg px-3 py-2 mb-2',

  walletButton1: 'absolute text-center left-30 z-10',
  walletButton2: 'absolute text-center left-200 z-10',
  
}

export default Style;