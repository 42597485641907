/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback} from 'react';
import { Card, Stat, Image, 
   Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, Row, Link, Loader, Form, Table, List } from 'components/lib';

//import { useWeb3React } from "@web3-react/core";
//import Axios from 'axios';
//import Style from './leaderboard.tailwind.js';
//import { Link } from 'react-router-dom';

export function ApplicationXP(props){

  //const context = useContext(ViewContext);
  //const navigate = useNavigate();
  const XPList  = useAPI('/api/application_xp');
  const navigate = useNavigate();

  useEffect(() => {
    if(XPList?.loading){
      //console.log(XPList);
    } else {
      //console.log(XPList);
    }
  }, [XPList]);


  async function viewApplication(item) {
    console.log(item);
    //let navPath = `/nft/holder?id=${nft.nft_id}`;
    let navPath = `/supported_apps#${item.id}`;
    //console.log(navPath);
    //return <Navigate to={navPath}/>;
    navigate(navPath);
  };

  

   return (
    <Animate type='pop'>

        
      <Row title="Experience Points(XP) Activities" center id="xp_list" key="xp_list">
        <Card id="bonuses" key="bonuses" title="Bonuses" >
          <List ordered items={[
          {title: "SuperPass XP Bonus: ", text: "Percent bonus equal to 1X the 'XP Boost' stat on the SuperPass. Global Bonus."},
          {title: "$PLAY Bonus: ", text: "Percent bonus equal to .002X the total $PLAY held. Global Bonus."},
          {title: "Referral Bonus: ", text: "5000XP when someone you referred levels up an NFT to level 2."},
          {title: "SuperPass Leadership Bonus: ", text: "Multiplier. When you're in a Space or multiplayer activity, the highest Diamond stat on their SuperPass."},
          {title: "Twitter Spaces Ratio Bonus: ", text: "Multiplier. Listener to Speaker Ratio multiplier."},
          {title: "Twitter Space Listener: ", text: "5% Bonus XP for every time you update the listener code as a listener."},
          ]}/>
        </Card>
        <Card id="rewards" key="rewards" title="Activity XP Rewards" >
          <Table
            search
            className='restrict-width'
            data={ XPList.data }
            loading={ XPList.loading }
            show={['application_name', 'xp_reward', 'description', 'xp_type']}
            badge={{ col: 'xp_type', color: 'blue', condition: [

              { value: 'Gaming', color: 'green' },
              { value: 'Social', color: 'blue' }

            ]}}
            actions={{ 
              custom: [
                { icon: 'eye', action: viewApplication },
              ]
          }}
            
          />
        </Card>
      </Row>

      <Feedback />

    </Animate>
  );
}
