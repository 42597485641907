/***
*
*   SIGN UP (user)
*   Signup form for child users
*
**********/

import React, { useContext } from 'react';
import { Animate, Row, AuthContext, Card, Form, Link, SocialSignin } from 'components/lib';

export function SignupUser(props){

  const context = useContext(AuthContext);
  const url = window.location.href;
  const id = url.substring(url.indexOf('?id=')+4, url.indexOf('&email'));
  const email = url.substring(url.indexOf('&email')+7);

  return(
    <Animate type='pop'>
      <Row title='Create Your Account'>
        <Card restrictWidth center>

          <SocialSignin network={['discord']} invite={ id } />

        </Card>
      </Row>
    </Animate>
  );
}
