/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback, Fragment} from 'react';
import { useLocation } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
//import truncateEthAddress from 'truncate-eth-address';
import { Card, Stat, ProgressBar, Chart,  Button, Image, Row, Link, Form, Search, CommunityViewNav,Table,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, AuthContext, WalletConnect } from 'components/lib';

import { ethers, utils, BigNumber } from 'ethers';

//https://eth-mainnet.g.alchemy.com/v2/RR7i6KryXLb4CRj-wkmv4Tyr_lr-9jj0
  
//import Axios from 'axios';
//import Style from './project.tailwind.js';

//let lastSearch = '';

export function CommunityViewNews(props){

  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const location = useLocation();
  //const [tokenPrice, setTokenPrice] = useState(null);
  //const [contractABI, setContractABI] = useState(null);
  //const [contractAddress, setContractAddress] = useState(null);
  
  //const [walletAddress, setWallet] = useState("");
  //const [status, setStatus] = useState("");
  //const [waitMint, setWaitMint] = useState(false);

  //const [name, setName] = useState("");
  //const [description, setDescription] = useState("");
  //const [url, setURL] = useState("");

  //const { active, account, chainId } = useWeb3React();
  const [communityError, setCommunityError] = useState(false);
  const [communityNews, setCommunityNews] = useState(null);
  const [advanced, setAdvanced] = useState(false);
  const [signalBoost, setSignalBoost] = useState(null);
  const [postList, setPostList] = useState(null);
  const [postStats, setPostStats] = useState(null);
  
  let pathSplit = location.pathname.split("/");
  const community_slug = pathSplit[pathSplit.length-1];
  const communityInfo = useAPI('/api/community/' + community_slug);
  const communityNewsData = useAPI('/api/community/news/' + community_slug);
  const user = useAPI('/api/user', authContext);

  function truncateToken(tokenString){
    let retString = tokenString;
    if(tokenString.length > 16){
      let strLen = tokenString.length;
      let string1 = tokenString.substring(0,12);
      let string2 = tokenString.slice(-4);
      retString = string1 + "..." + string2;
    }
    return retString;
  }

  function truncateString(theString, truncLength=50){
    let retString = theString;
    if(theString.length > truncLength){
      let strLen = theString.length;
      let string1 = theString.substring(0,truncLength);
      retString = string1 + "...";
    }
    return retString;
  }

  useEffect(() => {

    //console.log(communityInfo);
    if(communityInfo.loading){
      //console.log("LOADING NO DATA YET");
    } else {
      if(!communityInfo.success){
        //console.log("NOT LOADING, FAIL");
        setCommunityError(true);
      }
    }
    if(communityInfo && communityInfo.data) {
      //console.log(communityInfo);
      viewContext.setTitle(communityInfo.data.community.community_name + " [News, Updates & Promos]");
      
    }

    if(communityNewsData && communityNewsData.data){
      //console.log("communityNewsData");
      //console.log(communityNewsData);
      setCommunityNews(communityNewsData.data.community.news);

      let postListData = communityNewsData.data.community.news;
        
        let thePostList = [];
        let totalBoost = 0;
        postListData.forEach((post,index) => {
          
          let hasActed = false;
          let postActions = post.actions;
          
          /*if(user.data) {
            let myTwitterID = user.data.twitter_id;
            let myPostActions = postActions.filter(x=>x.twitter_id === myTwitterID);
            if(myPostActions && myPostActions.length > 0){
              hasActed = true;
            }
          }*/

          let postAuthorDisplayName = post.author_name;
          if(post.author_name !== post.platform_author_username){
            //postAuthorDisplayName = post.author_name + " (" + truncateString(post.platform_author_username,20) + ")";
            postAuthorDisplayName = post.author_name;
          }

          let postSignal = post.tracked_likes + "/" + post.tracked_retweets + "/" + post.tracked_quote_retweets;

          let thePostStats = post.total_likes + "/" + post.total_retweets + "/" + post.total_quote_retweets;

          let totalPostStats = post.total_likes + post.total_retweets + post.total_quote_retweets;
          let totalSignalStats = post.tracked_likes + post.tracked_retweets + post.tracked_quote_retweets;
          let signalBoost = totalPostStats > 0 ? parseInt(((totalSignalStats) / (totalPostStats)) * 100): 0;
          totalBoost = totalBoost + signalBoost;
          let signalRatio = signalBoost > 0 ? signalBoost + "%" : "--";

          let postData = {
            number: index + 1,
            xp_modifier: post.xp_modifier === 1 ? "Normal" : post.xp_modifier + "X Bonus",
            id: post.id,
            platform_id: post.platform_post_id,
            post_platform_author_id: post.platform_author_id,
            post_platform_author_username: post.platform_author_username,
            author_displayname: truncateString(postAuthorDisplayName,25),
            post_stats: thePostStats,
            signal: postSignal,
            signal_ratio: signalRatio,
            post_author: post.author_name,
            post_text: truncateString(post.post_text, 100),
            acted: user.data ? hasActed : 'n/a',
            post_date: post.post_time
          }
          thePostList.push(postData);
        });

        let postTableData = {
          header: [
            { name: 'number', title: '#', sort: true},
            { name: 'xp_modifier', title: 'XP', sort: true},
            { name: 'author_displayname', title: 'Author', sort: true},
            { name: 'post_stats', title: 'Like/Retweet/Quote', sort: true},
            //{ name: 'signal', title: 'Signal-LRQ', sort: true},
            { name: 'signal_ratio', title: 'Boost', sort: true},
            { name: 'post_text', title: 'Post', sort: true},
            { name: 'acted', title: 'Acted', sort: true},
            //{ name: 'post_date', title: 'Date', sort: true},
            
          ],
          body: thePostList
        }

        setPostList(postTableData);
        //console.log(postTableData);
    }

  }, [viewContext, communityInfo, communityNewsData]);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);

  async function loadContract() {
    //return new web3.eth.Contract(contractABI, contractAddress);
  }

  async function viewPost(post) {
    //console.log(post);
    //let navPath = `/post/${post.post_id}`;
    //console.log(navPath);
    //navigate(navPath);
    return window.open(`https://twitter.com/${post.post_platform_author_username}/status/${post.platform_id}`,'_blank');
   
  };

  
  return (
    <Fragment>
      <CommunityViewNav dark user={user} community_info={communityInfo.data} center={!advanced} loading={communityInfo.loading} loadFail={communityInfo.loading ? false : communityError} />
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
        {!communityInfo.loading && communityInfo.data && communityInfo.data.project &&
          <Card>
            <strong>{communityInfo.data.project.project_name}</strong><br/>
          </Card>
        }

        { postList && postList.body.length > 0 && 
          <Card dark>
            <div>
              <strong>Tracked Social Posts in last 7 days.</strong><br/>
              {/*<span>XP Rewards for actions are: Likes = 200XP.&nbsp;&nbsp;&nbsp;Retweets=300XP.&nbsp;&nbsp;&nbsp;Quote Retweets=400XP.</span>*/}
            </div>
            <Table
              search
              className='restrict-width'
              data={ postList }
              loading={ postList.loading }
              //show={['number', 'xp_modifier', 'author_displayname', 'post_stats', 'signal_ratio', 'post_text', 'acted']}
              show={['number', 'xp_modifier', 'author_displayname', 'post_text']}
              badge={{ col: 'xp_modifier', color: 'blue', condition: [

                { value: 'NORMAL', color: 'orange' },
                { value: '2', color: 'blue' }

              ]}}
              actions={{ 
                
                custom: [
                  { text:'View', action: viewPost },
                ]
              }}

            />
            <br/>
            
          </Card>
        }

        { postList && postList.body.length === 0 && 
          <Card dark>
            <div>
              <strong>No Tracked Social Posts in last 7 days.</strong><br/>
              {/*<span>XP Rewards for actions are: Likes = 200XP.&nbsp;&nbsp;&nbsp;Retweets=300XP.&nbsp;&nbsp;&nbsp;Quote Retweets=400XP.</span>*/}
            </div>
            <br/>
            
          </Card>
        }

      
      </div>
      <Feedback />

    </Fragment>
  );
}
