import Style from 'components/stat/stat.tailwind';
import React, { useState } from 'react';
import './messageInput.css';

const NewMessage = ({socket}) => {
  const [value, setValue] = useState('');
  const submitForm = (e) => {
    e.preventDefault();
    if(value.length >= 1 && value !== ' '){
      socket.emit('message', value);
    }
    
    setValue('');
  };

  return (
    <form onSubmit={submitForm} >
      <input
      className="chatInput"
        autoFocus
        value={value}
        placeholder="Type your message"
        onChange={(e) => {
          setValue(e.currentTarget.value);
        }}
      />
    </form>
  );
};

export default NewMessage;