const Style = {
    small: 'p0 text-sm text-normal-tight',
    smaller: 'p0 text-xs font-semibold',
    mintCardSmall: 'text-left min-h-310 shadow-lg',
    mintCard: 'text-center min-h-540 shadow-lg',
    mintCardTall: 'text-center min-h-565 shadow-lg',
    mintCardMedium: 'text-center min-h-495 shadow-lg',
    mintCardMediumPoly: 'text-center min-h-495 shadow-lg bg-violet-200',

    shopCardSmall: 'text-center min-h-250 shadow-lg',
    shopSummaryText: 'text-blue-500',
    tableTitle: 'p0 text-xs font-semibold',


    user: 'p0 mx-auto text-center shadow-lg',
    userCard: 'relative p-1 x-1 mb-6 bg-white rounded last-of-type:mb-0',
    userName: 'p0 text-xxs font-semibold',
    nftButton: 'px-7 !py-1 mx-auto text-center mt-1',
    spaceButton: 'px-7 !py-1 mx-auto text-center mt-1',
    nftImage: 'max-h-32 sm:max-h-38 mx-auto aspect-square',
    nftImagePreview: 'max-h-310 sm:max-h-310 mx-auto aspect-square',
    spaceUserImage: 'max-h-8 sm:max-h-8 max-w-8 sm:max-w-8',
    fillRaw: 'h-full ease-in-out duration-1000 bg-orange-500!',
    socialIcon: 'absolute z-5 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
  }
  
  export default Style;