/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext, useCallback} from 'react';
import { useLocation } from 'react-router-dom';
//import truncateEthAddress from 'truncate-eth-address';
import { Card, Stat, ProgressBar, Chart,  Button, Image, Row, Link, Form, Search, CollectionNav,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, ViewContext, AuthContext, CommunityNav } from 'components/lib';

//import { useWeb3React } from "@web3-react/core";
import Axios from 'axios';
import Style from './nft.tailwind.js';

let lastSearch = '';

export function NFTCollectionView(props){

  const viewContext = useContext(ViewContext);
  const context = useContext(AuthContext);

  //const user = useAPI('/api/user');
  const location = useLocation();

  //const context = useContext(ViewContext);
  const navigate = useNavigate();
  const stats = useAPI('/api/demo/stats');
  
  let pathSplit = location.pathname.split("/");
  //const collection_id = pathSplit[pathSplit.length-1];
  const collection_slug = pathSplit[pathSplit.length-1];

  //const { active, account, chainId } = useWeb3React();
  const [nftList, setNFTList] = useState(null);
  const [nftBaseList, setNFTBaseList] = useState(null);
  const [nftCollection, setNFTCollection] = useState(null);
  const [community, setCommunity] = useState(null);
  const [search, setSearch] = useState('');
  //const [minEventDate, setMinEventDate] = useState('2022-01-01');
  //const [nftImages, setNFTImages] = useState(null);
  //const [defaultNFTImageIndex, setDefaultNFTIndex] = useState(0);
  //const [existingAccount, setAccount] = useState(null);

  /*useEffect(() => { 

    if(user.data) {
      console.log(user.data);
    }

  },[user]);
  */

  /*
  useEffect(() => { 
    console.log(context);
    if(context.user) {
      console.log(context.user);
    }

  },[context]);
  */

  const getNFTs = useCallback(async (filter, minEventDate) => {
            
    //let minDate = "2022-06-05";
    //let nftFilter=[];
    let filterString = filter ? "?filter=" + filter : "?";
    let mindateString = minEventDate ? "&minDate=" + minEventDate : "";
    const res = await Axios.get('/api/nft/collection_list/' + collection_slug + filterString + mindateString);
    console.log(res.data);
    setNFTList(res.data.data.nfts);
    setNFTBaseList(res.data.data.nfts);
    let collection = res.data.data.collection;
    //console.log(collection);
    setNFTCollection(collection);

    const resCommunity = await Axios.get('/api/community/' + collection_slug);
    console.log(resCommunity.data);
    setCommunity(resCommunity.data.community);
    
    
  }, [collection_slug]);

  useEffect(() => {
    if(nftBaseList){
      //console.log("Show Locked " + showLocked);
      if(search !== '' || (lastSearch.toLowerCase() !== search.toLowerCase())){
        //console.log(search);
        if(lastSearch.toLowerCase() !== search.toLowerCase()){
          let list = nftBaseList;
          //console.log(list);
          let thisSearch = search.toLowerCase();
          let shortList = list.filter(function (nft) { 
            //console.log(creator);
            return (
              nft.name.toLowerCase().indexOf(thisSearch) !== -1 ||
              nft.collection.name.toLowerCase().indexOf(thisSearch) !== -1 ||
              (nft.nickname && nft.nickname.toLowerCase().indexOf(thisSearch) !== -1) ||
              (nft.owner_name && nft.owner_name.toLowerCase().indexOf(thisSearch) !== -1)
            )
          });
          
          lastSearch = thisSearch;
          setNFTList(shortList)
        }
        
      } 
    }
    
  }, [search, nftBaseList]);

  useEffect(() => {

    getNFTs("level", "2022-04-01");

  }, [getNFTs]);

  function truncateToken(tokenString){
    let retString = tokenString;
    if(tokenString.length > 16){
      let strLen = tokenString.length;
      let string1 = tokenString.substring(0,16);
      let string2 = tokenString.slice(-4);
      retString = string1 + "..." + string2;
    }
    return retString;
  }

  async function viewNFT(nft) {
    
    let navPath = `/nft/view/${nft.nft_id}`;
    
    navigate(navPath);
    
  };

  async function updateCollection() {
    
    const res = await Axios.post('/api/nft/updateCollection', { 
      slug: collection_slug
    });
    console.log(res);
  };

  async function launchPartner() {
    
    const res = await Axios.post('/api/nft/launchPartner', { 
      slug: collection_slug
    });
    console.log(res);
    viewContext.notification.show("Partner Launched", 'success', true);
  };

  async function doOptionChange(optionValues){
    console.log(optionValues);

    //let inputName = optionValues.input;
    //let optionValue = optionValues.value;
    //let optionValid = optionValues.valid;
    
    
      getNFTs(optionValues.view_filter, optionValues.minDate);
    

    
  }

  return (
    <Animate type='pop'>
      <Row small>
        <CommunityNav collection_id={collection_slug} />
        <Search throttle={ 500 } callback={ x => setSearch(x) }/><br/>
        { (nftList && nftList.length ===0) && 
          <Message
            title='No Collectibles Found.'
            text='No Activated Collectibles in this collection.'
            type='info'
          />
        }
        
          
        { nftList && nftList.length > 0 && 
          <>
          <Grid cols='2'>
            <Stat
              loading={ stats?.loading }
              value={ nftCollection !== null ? nftCollection.name: "--"  }
              label='Collection'
              icon='package'
            />
            <Stat
              loading={ stats?.loading }
              value={ nftList !== null ? "Top: " + nftList.length: "--" }
              label='Collectibles'
              icon='image'
            />
            
          </Grid>
          { context?.user && (context.user.name === "DaveY" || context.user.name === "KiltsRock") && 
            <Card center restrictWidth>
              <Grid cols='2'>
                <Button tiny color={"blue"} text='UpdateCollection' action={ e => updateCollection() }/>
                <Button tiny color={"blue"} text='Launch Partner' action={ e => launchPartner() }/>
              </Grid>
            </Card>    
          }

          <Card center restrictWidth>
            <Form 
              center
              smallButton
              buttonText='Filter'
              data={{
                view_filter: {
                  type: 'radio',
                  options: ['Level', 'Social', 'Gaming'],
                  required: false,
                  errorMessage: 'Please enter a message',       
                  default: 'Level',
                  label: '',
                },
                minDate: {
                  required: true,
                  label: 'Start Date (GMT) Ex: 2022-04-01 01:20:00',
                  default: '2022-04-01 01:00:00',
                  min: '2022-05-01 01:20:00',
                  type: 'date',
                }
              }}
              updateOnChange = {false}
              callback={ res => {
                //console.log(res);
                doOptionChange(res);
              }}
              //onSubmit={ e => {doOptionChange(e)}}
            />
          </Card> 

          <Card name='nfts' center>
            
          { community && community.id != null && 
            <span><strong>Community ID: </strong>{`${community.id}`}<br/></span>
          }
          { nftCollection && nftCollection.arcade_url != null && nftCollection.arcade_url !== '' && 
            <span><strong>Arcade: </strong><Link btn url={`${nftCollection.arcade_url}`} target='_blank' text={`${nftCollection.arcade_url}`} /><br/></span>
          }
          

            
            
            <Grid cols='5'>
              { nftList.map((nft, index) => {
                //console.log(nft);
                //let unAssignedXP = (nft.xp_raw - nft.xp).toLocaleString();
                return (
                  nft.image_url !== null &&
                  <Card shadow rounded
                    badge={ nft.status_name } badgeText={ nft.token_name }
                    key={ nft.id }
                    className={ Style.nftNoButton }
                    interactive
                    center
                    sideBadges = {nft.buckets}
                    >

                    <div className={ Style.nftname }>
                      {nft.owner_name !== 'Private' ? <Link  url={`/user/${nft.owner_name}`} text={nft.owner_name} target="_new" color='dark'/>  : '[Private]'}
                    </div>

                    <div className={ Style.nftname }>
                      { truncateToken(nft.name) }
                    </div>

                    <div className={ Style.nftname }>
                      { nft.nickname ? 
                        nft.nickname !== "" ? "Name: " + nft.nickname + " " : <>&nbsp;</>
                        : <>&nbsp;</>
                      }
                      { nft.title !== undefined ? 
                        nft.title !== "" ? "'" + nft.title + "'" : <>&nbsp;</>
                        : <>&nbsp;</>
                      }
                    </div>

                    <Image
                      className={ Style.nftImageSmall }
                      nftImage = {true}
                      loading = "lazy"
                      border = {true}
                      source={ nft.image_url}
                      alt={ nft.token_name }
                      action={ e => {viewNFT(nft)}}
                    />

                    <ProgressBar 
                      small
                      progress={ nft.unlocked === 1 ? (nft.xpStats.progress * 100) + '%': 0 } 
                      label={nft.unlocked === 1 ? `Level: ${nft.xpStats.level}  (${nft.xp.toLocaleString()} of ${nft.xpStats.nextLevelXP.toLocaleString()})` : 'Activate to Level Up'}
                    />

                    {/*
                    <ProgressBar 
                      raw
                      small
                      progress={ 
                        nft.unlocked === 1 ? 
                          unAssignedXP !== '0' ?
                          nft.progress_raw + '%'
                          :
                          0
                        :
                        0
                      } 
                      label={
                        nft.unlocked === 1 ? 
                          unAssignedXP === '0' ?
                          '(No Uninscribed XP)'
                          :
                          //`Uninscribed XP: ${unAssignedXP} Level: ${nft.xpStatsRaw.level} (${nft.xp_raw.toLocaleString()} of ${nft.xpStatsRaw.nextLevelXP.toLocaleString()})`
                          `Uninscribed XP: ${unAssignedXP}   Level: ${nft.xpStatsRaw.level}`
                        : 
                        'Activate to Level Up'
                        }
                      //label={nft.unlocked === 1 ? `Uninscribed XP: ${unAssignedXP} ${unAssignedXP > 0 ? `Level ${nft.xpRawStats.level}` : ''}` : 'Activate to Level Up'}
                    />
                    */}
        
                  </Card>
                )
                })
              }
            </Grid>
          </Card>
          </>
        }
      </Row>

      <Feedback />

    </Animate>
  );
}
