import { Dashboard } from 'views/dashboard/dashboard';
import { UserActivity } from 'views/dashboard/activity';
import { UserActivities } from 'views/dashboard/activities';
import { RewardsDashboard } from 'views/dashboard/rewards';
import { Help } from 'views/dashboard/help';
import { SupportedApps } from 'views/dashboard/supported_apps';
import { OnboardingView } from 'views/onboarding/onboarding';
import { ApplicationXP } from 'views/dashboard/xp';

const Routes = [
  {
    path: '/home',
    view: Dashboard,
    layout: 'appDark',
    //permission: 'user',
    title: 'Scribe Home'
  },
  {
    path: '/activity',
    view: UserActivity,
    layout: 'appDark',
    permission: 'user',
    title: 'User Activity'
  },
  {
    path: '/activities',
    view: UserActivities,
    layout: 'appDark',
    //permission: 'user',
    title: 'Activities'
  },
  {
    path: '/rewards',
    view: RewardsDashboard,
    layout: 'appDark',
    permission: 'user',
    title: 'Rewards Dashboard'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/help',
    view: Help,
    layout: 'appDark',
    permission: 'user',
    title: 'Get Help'
  },
  {
    path: '/supported_apps',
    view: SupportedApps,
    layout: 'appDark',
    //permission: 'user',
    title: 'Supported Apps'
  },
  {
    path: '/xp',
    view: ApplicationXP,
    layout: 'appDark',
    //permission: 'user',
    title: 'How to Get XP'
  },
]

export default Routes;
