/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, {useEffect, useState, useContext} from 'react';

import { Card, ViewContext, Stat, ProgressBar, Chart,  AuthContext, Image, Button, Row, Link, SocialShare,
  Message, Grid, Animate, Feedback, useAPI, useNavigate, SpacesNav } from 'components/lib';

import { useWeb3React } from "@web3-react/core";

import Axios from 'axios';
import Style from './spaces_dashboard.tailwind.js';

export function SpacesDashboard(props){

  //const user = useAPI('/api/user');
  //const context = useContext(ViewContext);
  
  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();
  const [spacesList, setSpacesList] = useState(null);
  const [advanced, setAdvanced] = useState(false);

    
  const authContext = useContext(AuthContext);
  
  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]);
  
  useEffect(() => { 
    if(localStorage.getItem('spaces')){
      localStorage.removeItem('spaces');
    } else {
      if(!authContext.user){
        localStorage.setItem('spaces', true);
      }
    }
  },[]);
  
  function truncateString(theString, truncLength=50){
    let retString = theString;
    if(theString.length > truncLength){
      let strLen = theString.length;
      let string1 = theString.substring(0,truncLength);
      retString = string1 + "...";
    }
    return retString;
  }

  useEffect(() => { 
    async function getSpaceList() {
      //if(user.data) {
        //console.log(user.data);
        const res = await Axios.get('/api/spaces/list');
        //console.log(res.data);
        setSpacesList(res.data.data.spaces);
      //}
    }

    getSpaceList();
  },[]);

  

  

  /*useEffect(() => { 
    
    if(user.data) {
      Axios.get('/api/site/spaces_dashboard/');
    }

  },[user]);*/


  async function viewSpace(space) {
    //console.log(space);
    let navPath = `/space/${space.space_id}`;
    //console.log(navPath);
    navigate(navPath);
   
  };


  return (

    <div className={Style.spaceMargin}>
      <SpacesNav/>
      <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>

      {/*<Grid cols='4'>
      <Stat
          labelSmall        
          //loading={ stats?.loading }
          //value={ recentEvent ? recentEvent.event_description: '--'}
          //label={ 'Last Event: ' + (recentEvent ? new Date(recentEvent.date_created).toUTCString() : '--')}
          icon=''
        />
        <Stat
          labelSmall
          loading={ false }
          label={ 'Global Spaces XP Events (Last Hour)' }
          //value={ lastHourInfo? lastHourInfo.event_count.toLocaleString()  : '--'}
          icon=''
        />
        <Stat
          labelSmall
          loading={ false }
          label={ 'Global Spaces XP (Last Hour)' }
          //value={ lastHourInfo? isNaN(parseInt(lastHourInfo.event_xp)) ? 0 : parseInt(lastHourInfo.event_xp).toLocaleString()  : '--'}
          icon=''
        />
        <Stat
          labelSmall
          loading={ false }
          label={ 'Users Online (Last Hour)' }
         // value={ lastHourInfo? lastHourInfo.users_online.toLocaleString()  : '--'}          
          icon=''
        />
      </Grid>
  */}



      { spacesList && spacesList.length === 0 && 
      <Message
        text="Scribe is not currently tracking any Twitter spaces."
        type='info'
      />
      }

      { spacesList && spacesList.length > 0 && 

        <Grid cols='3'>

          
            { spacesList.map((space, index) => {
              
              return (
                space.title !== null &&
                  <Card shadow rounded
                    key={ space.id }
                    id={ space.id }
                    className={ Style.spaceCardDark }
                    center
                    >
                    <div key='space_button'>
                      <Button key="space" className={Style.spaceButton} tiny color={"green"} text='Space Dashboard' action={ e =>  {viewSpace(space) }}/>
                    </div>
                    <div key='space_link' className={ Style.spaceTitleSmall }>
                      <strong><Link url={`https://twitter.com/i/spaces/${space.space_id}/peek`} text={truncateString(space.title, 50)} target="_new"/></strong>
                    </div>
                    <div key='space_info' className={ Style.textWhiteSmaller }>
                      <strong>Host:</strong> {space.creator_info.username}<br/>
                      <strong>Participants:</strong> {space.participant_count}
                    </div>
                    <Grid cols='4'>
                      { space.allSpeakers.map((spaceUser, index) => {
                        return (
                          spaceUser.profile_image_url && 
                          <Card
                            className={ Style.userCardSmall }
                            key={`UserCard${index}`}
                          >
                            <div key='space_link2' className={ Style.userName }>
                              <strong><Link  url={`https://twitter.com/${spaceUser.username}`} text={truncateString(spaceUser.username, 13)} target="_new" color='dark'/></strong>
                            </div>
                            <div key='space_image'>
                              <Image
                                key= {`user_${spaceUser.twitter_id}`}
                                className={ Style.nftImage }
                                nftImage = {true}
                                border = {true}
                                source={ spaceUser.profile_image_url.replace("_normal","")}
                                interactive
                                action={ e => {viewSpace(space)}}
                              />
                            </div>
                        </Card>
                        )
                      })}
                      </Grid>
                      
                      
                  </Card>
              )
              })
            }
            
          
        
      </Grid>
      }

      
      <Feedback />
      </div>
    </div>
  );
}
