/***
*
*   Dashboard NAV
*   Dashboard sub nav that renders different options based
*
**********/

import React from 'react';
import { SubNav } from 'components/lib';

export function DashboardNav(props){
  //console.log(props);

  let items = [
    { link: '/home', label: 'Home' },
    //{ link: '/rewards', label: 'Rewards' },
    //{ link: '/activities', label: 'Activity' },
    //{ link: `/activity/${props.user.name}`, label: 'Activity' },
    //{ link: `/user/${props.user.name}`, label: 'Metaverse Profile' },
    
    //{ link: '/account/password', label: 'Password' },
    //{ link: '/account/2fa', label : '2FA' },
    //{ link: '/account/billing', label: 'Billing', permission: 'owner' },
    //{ link: '/account/apikeys/', label: 'API Keys', permission: 'developer' },
    //{ link: '/account/users', label: 'Users', permission: 'admin' }
  ];
  if(props.advanced){
    items.push(
      { link: '/rewards', label: 'Rewards' },
    );
  }
  items.push(
    { link: `/activities`, label: 'XP' },
    { link: '/collectibles', label: 'MyStuff' },
    //{ link: '/activity', label: 'Activity' },
    //{ link: '/activities', label: 'Activities' },
    { link: '/c', label: 'Communities' },
    { link: `/u/${props.user.name_slug}`, label: 'Profile' },
    
    { link: `/shop`, label: 'Shop' }
    );
  

  return(
    <SubNav 
      dark 
      center={props.center}
      items={items}
    />
  );
}