/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useContext, useEffect } from 'react';
import { AuthContext, ViewContext, Form, Card,
  AccountNav, Button, useNavigate, Animate, useAPI, Event, Message, SocialSignin, Grid } from 'components/lib';

export function Profile(props){

  const navigate = useNavigate();
  
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch
  const user = useAPI('/api/user');
  useEffect(() => { 
    if(localStorage.getItem('account_link')){
      localStorage.removeItem('account_link');
      viewContext.notification.show('Account linked', 'success', true);
    } else {
      /*if(!authContext.user){
        localStorage.setItem('account_linking', true);
      }*/
    }
  },[]);

  function closeAccount(){
    viewContext.modal.show({

      title: 'Close Your Account',
      form: {},
      buttonText: 'Close Account',
      url: authContext.permission.owner ? '/api/account' : '/api/user',
      method: 'DELETE',
      destructive: true,
      text: 'Are you sure you want to delete your account? ' +
      'You will lose all of your data and this can not be undone.',

    }, () => {

      // destory user
      Event('closed_account');
      localStorage.clear();
      navigate('/signup');

    });
  }

  useEffect(() => {
   
    if(user){
      if(user.data){
        //console.log(user);
        //console.log(authContext.user);
      }
      
    }
    
  }, [user]);

  return (
    <Fragment>
      {user.data && !user.data.discord_id && 
      <Message
        messageID="Message_Scribe_AccountLinking"
        title="Earn XP everywhere you are!"
        text="Connect your Social Accounts to Scribe to BOOST your communities and earn even more XP."
        //buttonText="My Collectibles"
        //buttonLink="/collectibles"
        type="award"
      />
      }
      <Message
        messageID="Message_Scribe_Offers"
        title="Get offers that interest you!"
        text="As a Scribe user, we can send you offers from partners that match your profile, interests, and activity. When a brand or community sends you an offer, you will earn some $PLAY! Multipass holders will receive a greater amount of $PLAY per offer."
        //buttonText="My Collectibles"
        //buttonLink="/collectibles"
        type="info"
        noIcon
      />
      <AccountNav />
      <Animate>
        <Card
          title='Edit Your Profile'
          loading={ user.loading } restrictWidth>

          { user?.data &&
            <Form
              buttonText='Save'
              url='/api/user'
              method='PATCH'
              data={{
                /*name: {
                  label: 'Your Name',
                  type: 'text',
                  required: true,
                  value: user.data.name,
                  errorMessage: 'Please enter your name',
                },*/
                email: {
                  label: 'Email address',
                  type: 'email',
                  required: true,
                  value: user.data.email.includes('hasnoemail') ? '' : user.data.email,
                  errorMessage: 'Please enter your email address'
                },
                /*backup_email: {
                  label: 'Backup Email address',
                  type: 'email',
                  value: user.data.backup_email,
                  errorMessage: 'Please enter your backup email address'
                },*/
                ...user.data.permission === 'owner' && 1===2 && {
                  account_name: {
                    type: 'text',
                    label: 'Account Name',
                    value: user.data.account_name
                  }
                },
                ...user.data.accounts?.length > 1 && {
                  default_account: {
                    label: 'Default Account',
                    type: 'select',
                    default: user.data.default_account,
                    options: user.data.accounts.map(x => { return {

                      value: x.id, label: x.name

                    }})
                  }
                }
              }}
              callback={ res => {

                // update the account name
                if (authContext.user?.accounts?.length > 0){

                  const accounts = [...authContext.user.accounts]
                  accounts[accounts.findIndex(x => x.id === authContext.user.account_id)].name = res.data.data.account_name;
                  authContext.update({ accounts: accounts })

                }

                // update the user name
                //authContext.update({ name: res.data.data.name })

              }}
            />
          }

          <Fragment>
            <br/>
            {/*<Button
              textOnly
              action={ closeAccount }
              text='Close Your Account'
            />*/}
          </Fragment>
        </Card>

        
        <Card restrictWidth title='Account Linking'>
          {user.data && !user.data.discord_id && 
            <SocialSignin network={['discord']} accountlink localStorage={'account_link'} uid={user.data.id} normal text={'Link Discord Account'} />
          }
          {user.data && user.data.discord_id && 
            <div>Discord Account Linked.</div>
          }
          {user.data && !user.data.instagram_id && 
            <div>Instagram Account Not Linked.</div>
          }
          {user.data && !user.data.facebook_id && 
            <div>Facebook Account Not Linked.</div>
          }
          {user.data && !user.data.google_id && 
            <div>Google Account Not Linked.</div>
          }
          {user.data && !user.data.tiktok_id && 
            <div>TikTok Account Not Linked.</div>
          }
          
        
        
        </Card>
        
        
      </Animate>
    </Fragment>
  );
}
