import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';

import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

//https://www.linumlabs.com/articles/how-to-use-web3react-in-your-next-project

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 15000;
  return library;

  //return new ethers(provider)
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <App></App>
  </Web3ReactProvider>,
  
  document.getElementById('root')
);

const welcome = () => console.log('Welcome to NFTScribe Client 🚀')
welcome('de529c70-eb80-4dfb-9540-5075db7545bf')