/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import { AuthContext, AppNav, Header, User, WalletConnect, QuickLinks, Footer, Switch, ViewContext, Logo } from 'components/lib';

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { clusterApiUrl } from '@solana/web3.js'
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets'


import Style from './app.module.scss';

export function AppDarkBareLayout(props){
  //console.log(props);

  

  return (
    <Fragment>
      <main className={ Style.appDarkBasic }>
        { <props.children {...props.data }/> }
      </main>
    </Fragment>
  );
}